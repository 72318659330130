var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row margin-top-4x" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _vm.locations && _vm.locations.data
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "form",
                      {
                        attrs: {
                          autocomplete: "off",
                          onSubmit: "return false;"
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchKey,
                              expression: "searchKey"
                            }
                          ],
                          ref: "search",
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Search for..." },
                          domProps: { value: _vm.searchKey },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 13
                              ) {
                                return null
                              }
                              return _vm.loadPage(1)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchKey = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loadPage(1)
                          }
                        }
                      },
                      [_vm._v("Apply")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("new-location", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ]
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.locations.data, function(item) {
                      return _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "locations.information.details",
                                    params: { id: item.location.id }
                                  }
                                }
                              },
                              [
                                _c("strong", { staticClass: "f16" }, [
                                  _vm._v(_vm._s(item.location.name))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              item.location.country
                                ? item.location.country.name
                                : ""
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              item.location.state
                                ? item.location.state.name
                                : ""
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.location.city))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.location.address))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.location.phone))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              item.location.parking_due_at_location
                                ? "Yes"
                                : "No"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              item.location.room_due_at_location ? "Yes" : "No"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.parking_commission))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.room_commission))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.bundle_commission))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              item.location.currency
                                ? item.location.currency.code
                                : "-"
                            )
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("pagination", {
                attrs: { "pages-number": _vm.locations.last_page },
                on: {
                  pageChanged: function(page) {
                    return _vm.loadPage(page)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Location Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Country")]),
        _vm._v(" "),
        _c("th", [_vm._v("State")]),
        _vm._v(" "),
        _c("th", [_vm._v("City")]),
        _vm._v(" "),
        _c("th", [_vm._v("address")]),
        _vm._v(" "),
        _c("th", [_vm._v("Phone")]),
        _vm._v(" "),
        _c("th", [_vm._v("Parking due at Location")]),
        _vm._v(" "),
        _c("th", [_vm._v("Room due at Location")]),
        _vm._v(" "),
        _c("th", [_vm._v("Parking Commission")]),
        _vm._v(" "),
        _c("th", [_vm._v("Room Commission")]),
        _vm._v(" "),
        _c("th", [_vm._v("Bundle Commission")]),
        _vm._v(" "),
        _c("th", [_vm._v("Currency Code")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8f889c54", { render: render, staticRenderFns: staticRenderFns })
  }
}