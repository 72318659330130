import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import tabs from '../tabs'
import { GChart } from 'vue-google-charts'
import Vue from 'vue';

const components = {
  layout,
  datepicker,
  tabs,
  GChart
}

const computed = {
    location () {
      return _.cloneDeep(this.$store.state.locations.active)
    },

    roomOccupancy () {
      return _.cloneDeep(this.$store.state.analytics.occupancy.room);
    },

    parkingOccupancy () {
      return _.cloneDeep(this.$store.state.analytics.occupancy.parking);
    },

    showTable () {
      return this.types.room.data.length || this.types.parking.data.length;
    },

    showChart() {
      return !this.chartLoading || !this.chartData;
    }
}

const watch = {
  ['form.from_date'] () {
    let from_date_unformated = moment(this.form.from_date, ['MM/DD/YYYY']);
    let to_date_unformated = moment(from_date_unformated).add(this.number_of_days, 'days');
    this.form.to_date = to_date_unformated.format('MM/DD/YYYY');
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.getTableData();
    }, 200);
  },


}

const methods = {

  parseDates (data) {
    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }
    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    return data;
  },
  setTab(tab) {
    this.selectedTab = tab;
  },
  getTableData () {
    if (!this.form.from_date)
      return;

    this.chartLoading = true;
    this.loading = true;
    let data = _.clone(this.form);
    data = this.parseDates(data);

     Promise.all([
        this.$store.dispatch('analytics.occupancy.room', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        }),

        this.$store.dispatch('analytics.occupancy.parking', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        }),

    ]).then(() => {
      
      this.$nextTick(() =>{
        this.setTypes();
        this.setChartData();
        this.loading = false;
        this.chartLoading = false;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 200);
      })
    });
  },

  averagePerType (type) {
    let total_units = 0;
    let reserved_units = 0;
    let average = 0;
    for (let i = 0 ; i < this.number_of_days ; ++i) {
        total_units += this.availabilityAtDate(type, i);
        reserved_units += this.reservationsAtDate(type, i);
    }
    average = total_units > 0 ? (reserved_units / total_units) * 100 : 0;
    return average;
  },
  totalAverage (type) {
    let type_name = type + "Occupancy";
    let avg = 0;
    let total_units = 0;
    let total_reserved = 0;
    for (let i = 0 ; i < this.number_of_days ; ++i) {
      for (let type of this[type_name]) {
        total_units += this.availabilityAtDate(type, i);
        total_reserved += this.reservationsAtDate(type, i);;
      }
    }
    avg = total_units > 0 ? (total_reserved / total_units) * 100 : 0;
    return avg;
  },

  getAvailableAndReservedByType (type, subType = false) {
    let totalAvialble = 0;
    let type_name = type + "Occupancy";
    let dates = [];
    dates.push(['Day', 'Reserved Units', 'Total Available Units']);
    let searchData = [];
    if (!subType) {
      searchData = _.cloneDeep(this[type_name]);
      return this.getOccupancyForParentTypes(searchData, dates);
    } else {
      searchData = _.cloneDeep(_.filter(this[type_name], (item) => {
        return item.id == subType;
      }));
      return this.getOccupancyForType(searchData, dates);
    }
  },
  getOccupancyForType(data, dates) {
    for (let type of data) {
      for (let i = 0 ; i < this.number_of_days ; ++i) {
        let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(i, 'days').format("MM/DD");
        let reserved_at_date = this.reservationsAtDate(type, i);
        let availability = this.availabilityAtDate(type, i);
        dates.push([date, reserved_at_date, availability]);
      }
    }
    return dates;
  },
  getOccupancyForParentTypes(data, dates) {
    
    for (let i = 0 ; i < this.number_of_days ; ++i) {
      let total_units = 0;
      let total_reserved = 0;
      let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(i, 'days').format("MM/DD");
      for (let subType of data) {
        let reserved_at_date = this.reservationsAtDate(subType, i);
        let availability = this.availabilityAtDate(subType, i);
        total_units += availability;
        total_reserved += reserved_at_date;
      }
      dates.push([date, total_reserved, total_units]);
    }
    return dates;
  },

  setChartData () {
    let selected_unit = this.form.type;
    let dates = [];

    if (selected_unit == 'room' || selected_unit == 'parking') {
      dates = this.getAvailableAndReservedByType(selected_unit, false);
    } else {
      selected_unit = selected_unit.split('.');
      dates = this.getAvailableAndReservedByType(selected_unit[0], selected_unit[1]);
    }

    this.chartData = _.cloneDeep(dates);
    
    
  },
  

  goBackToToday () {
    this.form.from_date = moment().format('MM/DD/YYYY');
  },

  nextMonth (e) {
    e.preventDefault();
    this.form.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).add(this.number_of_days, 'days').format('MM/DD/YYYY');
  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.form.from_date, ['MM/DD/YYYY']).subtract(this.number_of_days, 'days');
    this.form.from_date = prev.format('MM/DD/YYYY');
  },

  reservationsAtDate (type, addDays) {
    let reserved_units = 0;
    // let total = type.number_of_rooms || type.number_of_parkings;
    let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(addDays, 'days');

    let reservation_history_dates = [...type.reservation_history_dates];
    if (type.number_of_parkings && type.child_types && type.child_types.length) {
      for (let i in type.child_types) {
        reservation_history_dates = [...reservation_history_dates, ...type.child_types[i].reservation_history_dates]
      }
    }

    for (let i in reservation_history_dates) {
      let start_date = moment(reservation_history_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      let end_date = moment(reservation_history_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);

      if (date.isSameOrBefore(end_date, 'day') && date.isSameOrAfter(start_date, 'day')) {
        reserved_units += reservation_history_dates[i].number_of_units * reservation_history_dates[i].spot_percentage;
      }
    }

    return reserved_units;
  },

  availabilityAtDate (type, addDays) {
    // let total = type.number_of_rooms || type.number_of_parkings;
    let date = moment(this.form.from_date, ['MM/DD/YYYY']).add(addDays, 'days');
    let total = type.number_of_rooms || type.number_of_parkings;
    let availability = total;

    for (let i in type.blackout_dates) {
      let start_date = moment(type.blackout_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      let end_date = moment(type.blackout_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      let day = date.format('dddd');
      if (date.isSameOrBefore(end_date, 'day')
        && date.isSameOrAfter(start_date, 'day')
        && type.blackout_dates[i][day.toLowerCase()]
      ) {
        availability = type.blackout_dates[i].available_num;
      }
    }

    return availability;
  },

  setTypes () {
    let totalRoomUnits = 0;
    let totalParkingUnits = 0;
    let totalRoomAverage = 0;
    let totalParkingAverage = 0;
    this.types.room.data = _.map(this.roomOccupancy, (room) => {
      let avg = this.averagePerType(room);
      totalRoomAverage += avg;
      totalRoomUnits += room.number_of_rooms;

      let obj = {name: room.name, number_of_units: room.number_of_rooms, avg : avg, id: `room.${room.id}` };
      return obj;
    });
    
    this.types.parking.data = _.map(this.parkingOccupancy, (parking) => {
      let avg = this.averagePerType(parking);
      totalParkingUnits += parking.number_of_parkings;
      totalParkingAverage += avg;
      let obj = {name: parking.name, number_of_units: parking.number_of_parkings, avg:avg, id: `parking.${parking.id}` };
      return obj;
    });

    this.types.room.total = totalRoomUnits;
    this.types.parking.total = totalParkingUnits;
    this.types.room.average = this.totalAverage('room');
    this.types.parking.average = this.totalAverage('parking');

  },
}

export default {
  name: 'occupancy',
  components,
  computed,
  watch,
  methods,
  data () {
    let number_of_days = 30;
    let from_date_unformated = moment()
    let from_date = from_date_unformated.format('MM/DD/YYYY');
    let to_date_unformated = moment(from_date_unformated).add(number_of_days, 'days');
    let to_date = to_date_unformated.format('MM/DD/YYYY');
    return {
      loading: true,
      chartLoading: false,
      errors: {},
      timeout: null,
      number_of_days,
      columns: [
        'Unit Type',
        'Available Units',
        '30-day Avg Occupancy'
      ],
      types: {
        room: {
          total: 0,
          data: [],
          show:false,
        },
        parking: {
          total: 0,
          data: [],
          show:false,
        },
      },
      form: {
        from_date,
        to_date,
        type: 'room'
      },
      chartOptions: {
        height: 400,
        hAxis: {
          title: 'Day',
        },
        vAxis: {
          title: 'Units',
        },
        colors: ['#a52714', '#097138'],
      },
      chartData: [],
      
    }
  },
  mounted () {
    this.loading = true;
    let params = {
      with_lease_flag: true
    }
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id, params: params}),
    ]).then(() => {
      this.loading = false;
      this.getTableData();
    });
  }
}
