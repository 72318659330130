import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import tabs from '../tabs'
import { currencySymbol } from 'filters'


const components = {
  layout,
  datepicker,
  tabs,
}

const filters = {
  currencySymbol
}



const computed = {
    location () {
      return _.cloneDeep(this.$store.state.locations.active)
    },

    contributionData () {
      return _.cloneDeep(this.$store.state.analytics.channels_contribution);
    },

    currencyCode() {
      return this.location.currency ? currencySymbol(this.location.currency.code) : "Na";
    }

}

const watch = {
  ['form.from_date'] (n, o) {
    let from_date_unformated = moment(this.form.from_date, [this.dateFormat])
    let to_date_unformated = moment(from_date_unformated);
    let from_date = _.cloneDeep(from_date_unformated.startOf('month').format(this.dateFormat));
    let to_date = _.cloneDeep(to_date_unformated.endOf('month').format(this.dateFormat));
    this.form.from_date = from_date;
    this.form.to_date = to_date;
    this.getTableData();
  },


}

const methods = {

  parseDates (data) {
    let from_date = null;
    let to_date = null;
    if (data.from_date) {
      from_date = moment(data.from_date, [this.dateFormat]).startOf('month');
      data.from_date = from_date.format('YYYY-MM-DD');
    }
    if (data.to_date) {
      to_date = moment(from_date, [this.dateFormat]).endOf('month');
      data.to_date = to_date.format('YYYY-MM-DD');
    }

    return data;
  },

  nextMonth (e) {
    e.preventDefault();
    this.form.from_date = moment(this.form.from_date, [this.dateFormat]).add(1, 'month').startOf('month').format(this.dateFormat);
    this.form.to_date = moment(this.form.from_date, [this.dateFormat]).endOf('month').format(this.dateFormat);
    this.getTableData();
  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.form.from_date, [this.dateFormat]).subtract(1, 'month')
    this.form.from_date = prev.startOf('month').format(this.dateFormat);
    this.form.to_date = prev.endOf('month').format(this.dateFormat);
    this.getTableData();
  },

  setData () {
    let results = _.cloneDeep(this.contributionData);
    console.log('results', results);
    let total = results.total;

    _.forEach(results.data, (channel, index) => {
      let percentage = Number(total > 0 ? (channel.contribution/total) * 100 : 0).toFixed(2);
      Object.assign(channel, {percentage})
    });
    this.tableData = _.cloneDeep(results);
  },

  getTableData () {
    this.errors = {};
    if (!this.form.from_date)
      return;

    this.loading = true;
    let data = _.clone(this.form);
    data = this.parseDates(data);
      this.$store.dispatch('analytics.contributions', {
          id: this.$route.params.id,
          params: _.cloneDeep(data)
        }).then(() => {
          let errors = _.cloneDeep(this.$store.state.analytics.errors);
        if (errors) {
          toastr.error(errors.message);
          if (errors.errors) {
            this.errors = _.cloneDeep(this.$store.state.analytics.errors.errors);
          }
          this.loading = false;
        }
        this.setData();
        this.loading = false;
      });
  }
}

export default {
  name: 'dashboard',
  components,
  computed,
  watch,
  methods,
  filters,
  data () {
    let dateFormat = 'MMMM/YYYY';
    let from_date_unformated = moment().subtract(1,'months').startOf('month');
    let from_date = from_date_unformated.format(dateFormat);
    let to_date_unformated = moment().subtract(1,'months').endOf('month');
    let to_date = to_date_unformated.format(dateFormat);
    return {
      loading: false,
      errors: {},
      date_picker_settings: {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        dateFormat: 'MM/yy',
      },
      form: {
        from_date,
        to_date,
      },
      dateFormat,
      tableData: [],
    }
  },
  mounted () {
    this.loading = true;
    let params = {
      with_lease_flag: true
    }
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id, params: params}),
    ]).then(() => {
      this.loading = false;
      this.getTableData();
    });

  }
}
