import { user, DataLayer} from 'services'
let computed = {
  user () {
    return user.getUser();
  },
} 
export default {
    name: "new-header",
    computed,
    props: {
        activeTab: {
          type: String,
          required: true
        }
      },
    data () {
      return {
        isMenuActive: false,
      }
    },
    methods: {
        toggleMenu() {
                this.isMenuActive = !this.isMenuActive;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.isMenuActive = false;
        },
        addscheduleDemoInitiateDataLayer(button_text) {
            if (!isServer) {
                DataLayer.scheduleDemoInitiate(this.user,button_text);
            }
            this.$router.push({ name: 'contact-us', query: { source: this.activeTab, buttonText: button_text } });
        },
    }
};