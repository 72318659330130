import toastr from 'toastr'
import logoSlider from 'components/logoSlider'
import newfooter from 'components/newfooter'
import { user, DataLayer} from 'services'

let computed = {
  sortedAirports() {
    return this.airports.slice().sort((a, b) => a.airport_name.localeCompare(b.airport_name));
  },
  user () {
    return user.getUser();
  },
}

const components = {
  logoSlider,
  'new-footer':newfooter,
}


let methods = {
  pageTrigger() {
    this.formSubmitted = true;
    window.scrollTo(0, 0);
  },
  initializeSelectMenu() {
    let vm = this;
    let changing = false;
    $("#interested-in-select").selectmenu({
      change: function(event, ui) {
        let originalSelect = $("#interested-in-select");
        originalSelect.val(ui.item.value).trigger("change");
        originalSelect[0].setCustomValidity("");
        $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').css('color', 'rgba(41, 56, 81, 1)');
      },
      open: function(event, ui) {
        $(this).next('.ui-selectmenu-menu').addClass('interest-select');
        $(this).next('.ui-selectmenu-button').css('border-bottom', '1px solid black');
        $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(-135deg)');
      },
      close: function(event, ui) {
        $(this).next('.ui-selectmenu-button').css('border-bottom', '');
        $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(45deg)');
      }
    });
    $("#airport-select").selectmenu({
      change: function(event, ui) {
        let hiddenInput = $('input[name="airport_name"]');

        if (hiddenInput) {
            hiddenInput.val(ui.item.value);
            hiddenInput.trigger('change');
        }
        $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').css('color', 'rgba(41, 56, 81, 1)');
        changing = true;
      },
      open: function (event, ui) {
        let button = $(this).selectmenu("widget");
        let menu = $(this).selectmenu("menuWidget");
        let selectElement = $("#airport-select");
        changing = false;

        button.find(".search-input").remove();
        let notApplicableItem = menu.find("li").filter(function() {
          return $(this).text().trim().toLowerCase() === "not applicable";
        });
        notApplicableItem.show();
        if (notApplicableItem.length) {
          notApplicableItem.prependTo(menu);
        }
 
        let currentValue = $(this).find("option:selected").text().trim();
        button.find(".ui-selectmenu-text").hide();
        if (currentValue == 'Enter Airport'){
          currentValue = '';
        }

        button.append(`<input type="text" class="search-input" placeholder="Enter Airport" />`);
        let searchInput = button.find(".search-input");
        searchInput.on("blur", function (event) {
          event.stopPropagation();
        });
        searchInput.val(currentValue).on("blur", function (event) {
          event.stopPropagation();
        });
        searchInput.val(currentValue).focus();

        let hasMatch = false;
        menu.find("li").each(function () {
            let text = $(this).text().toLowerCase();
            if (text === "not applicable") {
              return;
            }
            const isDisabled = $(this).attr("aria-disabled") === "true";
            let matches = !isDisabled && text.includes(currentValue.toLowerCase());
            $(this).toggle(matches);
            if (matches) hasMatch = true;

            if ($(this).text().trim() === currentValue) {
              $(this).css('border-bottom', 'none');
            }
            notApplicableItem.prependTo(menu);
        });

        if (!hasMatch) {
          notApplicableItem.show();
        }

        searchInput.on("keydown", function (event) {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
          if (event.key === " ") {
            event.preventDefault();
            let cursorPosition = searchInput[0].selectionStart;
            let currentValue = $(this).val();
            let newValue = currentValue.slice(0, cursorPosition) + " " + currentValue.slice(cursorPosition);
            $(this).val(newValue);
            searchInput[0].setSelectionRange(cursorPosition + 1, cursorPosition + 1);
            return false;
          }
          if (event.key === "Backspace" && currentValue) {
            event.preventDefault();
            $(this).val("");
            currentValue = "";
            selectElement.val("");
            menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
            menu.find("li:visible:last").css("border-bottom", "none");
            let hiddenInput = $('input[name="airport_name"]');
            if (hiddenInput) {
                hiddenInput.val(currentValue);
                hiddenInput.trigger('change');
            }
          }
        });
        let debounceTimeout;
        searchInput.on("keyup", function (event) {
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            return false;
          }
          menu.addClass("loading").css("pointer-events", "none");
          clearTimeout(debounceTimeout);

          let searchTerm = $(this).val().toLowerCase();
          let originalValue = $(this).val(); 
          hasMatch = false;
  
          menu.find("li").each(function () {
              let text = $(this).text().toLowerCase();
              if (text === "not applicable") {
                return;
              }
              const isDisabled = $(this).attr("aria-disabled") === "true";
              let matches = !isDisabled && text.includes(searchTerm);
              $(this).toggle(matches);
              if (matches) hasMatch = true;
              notApplicableItem.prependTo(menu);
          });

          if (!hasMatch) {
            let notApplicableItem = menu.find("li").filter(function() {
              return $(this).text().trim().toLowerCase() === "not applicable";
            });
            notApplicableItem.show();
          } 
          if (originalValue === "") {
            $(this).val("");
            menu.find("li").each(function () {
              const isDisabled = $(this).attr("aria-disabled") === "true";
              if (!isDisabled) {
                $(this).show();
              }
            });
            menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
            menu.find("li:visible:last").css("border-bottom", "none");
          }
          menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
          menu.find("li:visible:last").css("border-bottom", "none");
          let firstVisibleItem = menu.find("li:visible").first();
          if (firstVisibleItem.length) {
            let menuContainer = menu.closest(".ui-menu");
            menuContainer.scrollTop(0);
          }
          debounceTimeout = setTimeout(() => {
            menu.removeClass("loading").css("pointer-events", "");
          }, 1000);
        });

        let buttonWidth = $("#airport-select-button").outerWidth();
        $("#airport-select-menu").css({
            width: `${buttonWidth}px`,
        }).attr("style", `width: ${buttonWidth}px !important;`);
        vm.$nextTick(() => {
          vm.resizeSelectMenu();
        });
      },
      close: function (event, ui) {
        let button = $(this).selectmenu("widget");
        let menu = $(this).selectmenu("menuWidget");
        let searchInput = button.find(".search-input");
        let selectedText = $(this).find("option:selected").text().trim();

        if (searchInput.is(":focus") && !changing) {
          $("#airport-select").selectmenu("open");
          return false;
        }
    
        if (searchInput.length) {
            button.find(".ui-selectmenu-text").text(selectedText).show();
            searchInput.remove();
        }
        menu.find("li").each(function () {
          const isDisabled = $(this).attr("aria-disabled") === "true";
          if (!isDisabled) {
            $(this).show();
          }
        });

        let notApplicableItem = menu.find("li").filter(function() {
          return $(this).text().trim().toLowerCase() === "not applicable";
        });
      }
    });
    this.$nextTick(() => {
      this.resizeSelectMenu();
    });
  },
  resizeSelectMenu() {
    let containerWidth = $(".hs-input").outerWidth();
    $("#airport-select").selectmenu("menuWidget").css("width", containerWidth+"px");
    $("#airport-select-button").css("width", containerWidth+"px");
    $("#interested-in-select-button").css("width", containerWidth+"px");
    let buttonWidth = $("#airport-select-button").outerWidth();
    $("#airport-select-menu").css({width: `${buttonWidth}px`,}).attr("style", `width: ${buttonWidth}px !important;`);
  },
  handleResize() {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    if (
        Math.abs(currentWidth - this.lastWindowWidth) > 50 ||
        Math.abs(currentHeight - this.lastWindowHeight) > 50
    ) {
        this.lastWindowWidth = currentWidth;
        this.lastWindowHeight = currentHeight;
        this.closeSelectMenuOnZoom();
    }
  },
  closeSelectMenuOnZoom() {
    $("#airport-select").selectmenu("close");
    $("#interested-in-select").selectmenu("close");
    this.$nextTick(() => {
      this.resizeSelectMenu();
    });
  },
  adjustContainerClass() {
    if (window.innerWidth > 1150) {
      this.containerClass = 'container';
    } else {
      this.containerClass = '';
    }
  },
  adjustHubspotForm() {
    setTimeout(() => {
      let firstnameLabel = document.querySelector(".hub-form label[for*='firstname']");
      if (firstnameLabel) {
        firstnameLabel.textContent = "First name";
      }
      let lastnameLabel = document.querySelector(".hub-form label[for*='lastname']");
      if (lastnameLabel) {
        lastnameLabel.textContent = "Last name";
      }
      let requiredAsterisk = document.querySelector('.hs_email label .hs-form-required');
      if (requiredAsterisk) {
          requiredAsterisk.remove();
      }
      let emailLabel = document.querySelector(".hub-form input[type='email']");
      if (emailLabel) {
        emailLabel.removeAttribute('required');
      }
      let phoneLabel = document.querySelector(".hub-form label[for*='phone']");
      if (phoneLabel) {
        phoneLabel.textContent = "Phone number";
      }
      let airportLabel = document.querySelector(".hub-form label[for*='airport']");
      if (airportLabel) {
        airportLabel.textContent = "Nearest Airports";
      }
      let airportInputDiv = document.querySelector(".hs_airport_name .input");
        if (airportInputDiv) {
          // airportInputDiv.innerHTML = '';
          let selectElement = document.createElement('select');
          selectElement.name = 'airport_name';
          selectElement.id = 'airport-select';
          selectElement.required = true;
          selectElement.classList.add('hs-input');
          let defaultOption = document.createElement('option');
          defaultOption.value = '';
          defaultOption.text = 'Enter Airport';
          defaultOption.disabled = true;
          defaultOption.selected = true;
          defaultOption.setAttribute('aria-disabled', 'true');
          selectElement.appendChild(defaultOption);

          this.sortedAirports.forEach(airport => {
            let option = document.createElement('option');
            option.value = airport.airport_name;
            option.text = `${airport.airport_name} (${airport.iata_code})`;
            selectElement.appendChild(option);
          });

          let notApplicable = document.createElement('option');
          notApplicable.value = "not_applicable";
          notApplicable.text = "Not applicable";
          notApplicable.id = 'not_applicable';
          notApplicable.style.display = 'block';
          selectElement.appendChild(notApplicable);

          let hiddenInput = document.querySelector('input[name="airport_name"]');
          hiddenInput.required = false;
          if (hiddenInput) {
            hiddenInput.type = 'hidden';
          }

          airportInputDiv.appendChild(selectElement);
          selectElement.addEventListener('focus', function () {
            if (selectElement.value === '') {
              defaultOption.style.display = 'none';
            }
          });
        
          selectElement.addEventListener('blur', function () {
            if (selectElement.value === '') {
              defaultOption.style.display = 'block';
            }
          });
        }
      let interestLabel = document.querySelector(".hub-form label[for*='interested']");
      if (interestLabel) {
        interestLabel.textContent = "You’re interested in:";
      }
      let submitButton = document.querySelector(".hub-form input[type='submit']");
      if (submitButton) {
          submitButton.value = "Start Your Journey";
      }
      let selectElement = document.querySelector(".hub-form select[name='interested_in']");
      if (selectElement) {
        selectElement.id = 'interested-in-select';
        selectElement.options[0].text = 'Select one';
        selectElement.addEventListener('focus', function () {
          selectElement.options[0].style.display = 'none';
        });

        selectElement.addEventListener('blur', function () {
            selectElement.options[0].style.display = 'block';
        });
        selectElement.addEventListener('change', function () {
          selectElement.classList.remove('invalid');
        });
      }


      let container = document.querySelector(".hub-form"); 
      let interestedInDiv = document.querySelector(".hub-form div.hs-fieldtype-select");
      if (container && interestedInDiv) {
        const formTitle = document.createElement('div');
        formTitle.classList.add('form-title');
        formTitle.textContent = "How can we help?*";
        container.insertBefore(formTitle,interestedInDiv);
      }

      let form = document.querySelector(".hub-form");
      if (form) {
        form.addEventListener('submit', function (event) {
          let interestedInSelect = document.querySelector("#interested-in-select");
          if (!interestedInSelect.value || interestedInSelect.value === "") {
            interestedInSelect.classList.add("invalid");
            interestedInSelect.setCustomValidity("Please select an option");
          } else {
            interestedInSelect.classList.remove("invalid");
            interestedInSelect.setCustomValidity("");
          }

          let airportSelect = document.querySelector("#airport-select");
          if (!airportSelect.value || airportSelect.value === "") {
            airportSelect.classList.add("invalid");
            airportSelect.setCustomValidity("Please select an option");
          } else {
            airportSelect.classList.remove("invalid");
            airportSelect.setCustomValidity("");
          }

          if (!form.checkValidity()) {
            event.preventDefault();
          }
        });
      }

      let hiddenField = document.querySelector('.hub-form input[name="lead_type"]');
      if (hiddenField) {
        if (this.sourcePage === 'propertyManager') {
            hiddenField.value = 'Property Manager';
        } else if (this.sourcePage === 'demandChannel') {
            hiddenField.value = 'Demand Generator';
        }
      }
      this.loading = false;
      this.initializeSelectMenu();
      this.autoMoveBetweenFields();
    }, 500);
  },
  async formSubmit ($form) {
    let isValid = true;
    let submitButton = document.querySelector(".hub-form input[type='submit']");
    if (submitButton) {
        submitButton.style.display = 'none';
    }
      let formData = $form.serializeArray().reduce(function(obj, item) {
          obj[item.name] = item.value;
          return obj;
      }, {});

      this.form['first_name'] = formData['firstname'];
      this.form['last_name'] = formData['lastname'];
      this.form['phone_number'] = formData['phone'];
      this.form['email'] = formData['email'];
      this.form['airport_name'] = formData['airport_name'];
      this.form['inerested_in'] = formData['interested_in'];
      this.form['lead_type'] = formData['lead_type'];
      
      if (!isServer) {
        DataLayer.generateLead(this.user, 'Contact Form', formData['email']);
        DataLayer.scheduleDemoSuccess (formData['email'], formData['phone'], this.user, this.buttonText, this.form);
      }
  },
  fetchAirports() {
    this.$store.dispatch('dashboards.getAirports').then(() => {
        this.airports = _.cloneDeep(this.$store.state.dashboards.airports.airports);
        this.error = '';
        this.fetchForm();
      }).catch(err => {
        this.error = err.response ? err.response.data.error : 'An error occurred';
        this.airports = [];
      });
  },
  fetchForm() {
    this.$store.dispatch('dashboards.getContactUsFormData').then(() => {
      let data = _.cloneDeep(this.$store.state.dashboards.contact_us_form);

      let hubSpotScript = document.createElement('script')
      hubSpotScript.setAttribute('src', 'https://js.hsforms.net/forms/v2.js')
      console.log(data,hubSpotScript)
      document.head.appendChild(hubSpotScript)

      hubSpotScript.onload = () => { 
          hbspt.forms.create({
              // region: "na1",
              target: "#hubspot-form",
              portalId: data.portal_id,
              formId: data.form_id,
              onFormSubmit: this.formSubmit,
              onFormSubmitted: this.pageTrigger,
              css: "",
              cssClass: "hub-form",
          });

          setTimeout(() => {
            this.adjustHubspotForm();
          }, 500);
      }
  });

  },
  autoMoveBetweenFields() {
    const fields = document.querySelectorAll('.hub-form input, .hub-form select');
  
    fields.forEach((field, index) => {
      field.addEventListener('input', (event) => {
        const maxLength = field.getAttribute('maxlength');
        const isLastField = index === fields.length - 1;
        if (maxLength && field.value.length >= maxLength && !isLastField) {
          fields[index + 1].focus();
        }
      });
  
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          const isLastField = index === fields.length - 1;
          if (!isLastField) {
            fields[index + 1].focus();
          } else {
            const form = document.querySelector('.hub-form');
            if (form.checkValidity()) {
              form.submit();
            } else {
              toastr.error('Please fill out all required fields');
            }
          }
        }
      });
    });
  },
  focusNextField(refName) {
    const nextField = this.$refs[refName];
    if (nextField && nextField.focus) {
        nextField.focus();
    }
  },
}
export default {
  name: 'contact-us',
  methods,
  components,
  computed,
  data () {
    return {
      buttonText: '',
      headerText: '',
      sourcePage: 'propertyManager',
      errors: {},
      containerClass: '',
      processing: false,
      formSubmitted:false,
      lastWindowWidth: null,
      lastWindowHeight: null,
      airports: [],
      selectedAirport: '',
      loading: true,
      form: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        airport_name: null,
        interested_in: null,
        lead_type: null,
      }
    }
  },
  created() {
    if (this.$route.query.source) {
      this.sourcePage = this.$route.query.source;
    }
    this.buttonText = this.$route.query.buttonText;
  },
  mounted() {
    window.scrollTo(0, 0);
    this.lastWindowWidth = window.innerWidth;
    this.lastWindowHeight = window.innerHeight;
    this.adjustContainerClass();
    window.addEventListener('resize', this.adjustContainerClass);
    window.addEventListener("resize", this.handleResize);
    this.fetchAirports();
  },
  beforeDestroy() {
    $("#airport-select").selectmenu("destroy");
  },
  beforeRouteLeave(to, from, next) {
    $("#airport-select").selectmenu("destroy");
    next();
  }
}
