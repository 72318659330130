var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("new-header", { attrs: { activeTab: _vm.currentTab } }),
      _vm._v(" "),
      _c("div", { staticClass: "body-container" }, [
        _c(
          "div",
          { staticClass: "full-section" },
          [
            _c("div", { staticClass: "intro" }, [
              _c("div", { staticClass: "row hero-section" }, [
                _c("div", { staticClass: "col-6 hero-text" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-header cyan-bg schedule-demo-btn",
                      on: {
                        click: function($event) {
                          return _vm.addscheduleDemoInitiateDataLayer(
                            "Schedule a demo"
                          )
                        }
                      }
                    },
                    [_vm._v("Schedule a demo")]
                  )
                ]),
                _vm._v(" "),
                _vm._m(2)
              ])
            ]),
            _vm._v(" "),
            _c("logo-slider")
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "section-cyan-bg" }, [
          _c(
            "div",
            { class: ["revenue-calculator-section", _vm.revnueClass] },
            [
              _vm._m(3),
              _vm._v(" "),
              _c("hr", { staticClass: "separator-line" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    width: "100%"
                  }
                },
                [
                  _c("div", { staticClass: "form-section" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-justify-between select-container"
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "tooltip-container form-label",
                              attrs: { for: "airport" }
                            },
                            [_vm._v(" Select nearest airport")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown",
                              attrs: { id: "select-container" }
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAirport,
                                      expression: "selectedAirport"
                                    }
                                  ],
                                  class: _vm.a_selectClass,
                                  attrs: { id: "airport" },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedAirport = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.onAirportChange
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [_vm._v("Enter nearest airport")]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.sortedAirports, function(airport) {
                                    return _c(
                                      "option",
                                      {
                                        key: airport.iata_code,
                                        domProps: { value: airport.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              airport.airport_name +
                                                " (" +
                                                airport.iata_code +
                                                ")"
                                            ) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    (_vm.isAirportMatched || !_vm.selectedAirport) &&
                    !_vm.noSearchResult
                      ? _c(
                          "div",
                          {
                            class: [
                              "matched d-flex flex-column flex-md-row",
                              _vm.gridClass
                            ]
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-justify-between flex-align-center"
                              },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _vm._m(6),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "yes-no-options" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.shuttleService,
                                          expression: "shuttleService"
                                        }
                                      ],
                                      ref: "ShuttleService",
                                      staticClass: "hidden-radio",
                                      attrs: {
                                        type: "radio",
                                        id: "shuttle-yes",
                                        name: "shuttle-service",
                                        value: "yes"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.shuttleService,
                                          "yes"
                                        )
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.shuttleService = "yes"
                                          },
                                          function($event) {
                                            return _vm.focusNextField(
                                              "valetParking"
                                            )
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "yes-no-option",
                                        attrs: { for: "shuttle-yes" }
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.shuttleService,
                                          expression: "shuttleService"
                                        }
                                      ],
                                      staticClass: "hidden-radio",
                                      attrs: {
                                        type: "radio",
                                        id: "shuttle-no",
                                        name: "shuttle-service",
                                        value: "no"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.shuttleService,
                                          "no"
                                        )
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.shuttleService = "no"
                                          },
                                          function($event) {
                                            return _vm.focusNextField(
                                              "valetParking"
                                            )
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "yes-no-option",
                                        attrs: { for: "shuttle-no" }
                                      },
                                      [_vm._v("No")]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(7),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-justify-between flex-align-center"
                              },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _vm._m(8),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "yes-no-options" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.valetParking,
                                          expression: "valetParking"
                                        }
                                      ],
                                      ref: "valetParking",
                                      staticClass: "hidden-radio",
                                      attrs: {
                                        type: "radio",
                                        id: "valet-yes",
                                        name: "valet-parking",
                                        value: "yes"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.valetParking, "yes")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.valetParking = "yes"
                                          },
                                          function($event) {
                                            return _vm.focusNextField(
                                              "isCoveredParking"
                                            )
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "yes-no-option",
                                        attrs: { for: "valet-yes" }
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.valetParking,
                                          expression: "valetParking"
                                        }
                                      ],
                                      staticClass: "hidden-radio",
                                      attrs: {
                                        type: "radio",
                                        id: "valet-no",
                                        name: "valet-parking",
                                        value: "no"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.valetParking, "no")
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.valetParking = "no"
                                          },
                                          function($event) {
                                            return _vm.focusNextField(
                                              "isCoveredParking"
                                            )
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "yes-no-option",
                                        attrs: { for: "valet-no" }
                                      },
                                      [_vm._v("No")]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(9),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex flex-justify-between" },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _vm._m(10),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown select",
                                      attrs: { id: "shuttle-container" }
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.coveredParking,
                                              expression: "coveredParking"
                                            }
                                          ],
                                          ref: "isCoveredParking",
                                          class: _vm.c_selectClass,
                                          attrs: { id: "shuttle" },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.coveredParking = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              _vm.oncoverChange
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: { value: "", disabled: "" }
                                            },
                                            [_vm._v("Select")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "yes" } },
                                            [_vm._v("Covered")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "no" } },
                                            [_vm._v("Uncovered")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(11),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "value" }
                                },
                                [_vm._v("Number of spots")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "numeric-input" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.numSpots,
                                      expression: "numSpots",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  ref: "allcoveredParking",
                                  attrs: {
                                    type: "number",
                                    id: "value",
                                    placeholder: "Enter number",
                                    min: "0",
                                    inputmode: "numeric"
                                  },
                                  domProps: { value: _vm.numSpots },
                                  on: {
                                    keydown: _vm.blockMinusSign,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.numSpots = _vm._n($event.target.value)
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            !_vm.calculatedRevenue
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "calculate-btn calc white-bg",
                                    on: { click: _vm.calculateRevenue }
                                  },
                                  [_vm._v("Calculate")]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "calculate-btn reset white-bg",
                                    on: { click: _vm.resetForm }
                                  },
                                  [_vm._v("Clear")]
                                )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "flex flex-align-center unmatch" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "revenue-calculator-description unmatch-d"
                              },
                              [
                                _vm._v(
                                  "\n                            Contact us to get us an estimated revenue\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn-header white-bg",
                                on: {
                                  click: function($event) {
                                    return _vm.addscheduleDemoInitiateDataLayer(
                                      "Contact us"
                                    )
                                  }
                                }
                              },
                              [_vm._v("Contact Us")]
                            )
                          ]
                        )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.calculatedRevenue
                ? _c("div", { staticClass: "revenue-section" }, [
                    _c(
                      "div",
                      { staticClass: "revenue-block monthly-revenue" },
                      [
                        _c("p", [_vm._v("Monthly Revenue")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "revenue-amount" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                (_vm.selectedAirportObj.country_code === "CA"
                                  ? "CA$"
                                  : "$") + _vm.monthlyRevenue.toLocaleString()
                              ) +
                              "\n                        "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "revenue-block annual-revenue" }, [
                      _c("p", [_vm._v("Annual Revenue")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "revenue-amount" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              (_vm.selectedAirportObj.country_code === "CA"
                                ? "CA$"
                                : "$") + _vm.annualRevenue.toLocaleString()
                            ) +
                            "\n                        "
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "key-features-section" }, [
            _c("div", { staticClass: "key-features-content" }, [
              _c("div", { staticClass: "key-features-text" }, [
                _vm._m(12),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-header top-btn black-bg schedule-demo-btn",
                    on: {
                      click: function($event) {
                        return _vm.addscheduleDemoInitiateDataLayer(
                          "Schedule a demo"
                        )
                      }
                    }
                  },
                  [_vm._v("Schedule a demo")]
                )
              ]),
              _vm._v(" "),
              _vm._m(13),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-header btm-btn black-bg schedule-demo-btn",
                  on: {
                    click: function($event) {
                      return _vm.addscheduleDemoInitiateDataLayer(
                        "Schedule a demo"
                      )
                    }
                  }
                },
                [_vm._v("Schedule a demo")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "features-table-container" }, [
            _c("div", { staticClass: "features-table-title" }, [
              _vm._v(
                "\n                    Explore more below:\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "features-table-section" },
              [
                _vm._l(_vm.visibleSections, function(section, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticStyle: { "padding-top": "2rem" } }, [
                      _c(
                        "div",
                        {
                          class: [
                            "key-features-section-header",
                            section.title ===
                            "Configurable and customizable to your needs"
                              ? "w-header"
                              : ""
                          ]
                        },
                        [_vm._v(_vm._s(section.title))]
                      ),
                      _vm._v(" "),
                      _c("table", { staticClass: "key-features-table" }, [
                        _vm._m(14, true),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(section.features, function(feature, i) {
                            return _c(
                              "tr",
                              {
                                key: i,
                                style: {
                                  backgroundColor:
                                    i % 2 === 0 ? "#FFFFFF" : "#FAFAFA"
                                }
                              },
                              [
                                _c("td", { staticClass: "feature-name" }, [
                                  _vm._v(_vm._s(feature.name))
                                ]),
                                _vm._v(" "),
                                _vm._m(15, true),
                                _vm._v(" "),
                                _vm._m(16, true)
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    index < _vm.visibleSections.length - 1 && _vm.showLessButton
                      ? _c("hr", { staticClass: "feature-separator-line" })
                      : _vm._e()
                  ])
                }),
                _vm._v(" "),
                _vm.sections.length > _vm.initialVisibleSections
                  ? _c(
                      "div",
                      {
                        staticClass: "button-container",
                        class: {
                          "show-more-only": !_vm.showLessButton,
                          "show-less-only":
                            !_vm.showMoreButton && !_vm.showLessButton,
                          "show-less-visible": _vm.showLessButton
                        }
                      },
                      [
                        _vm.showMoreButton
                          ? _c(
                              "button",
                              {
                                staticClass: "show-more-button",
                                on: { click: _vm.showMore }
                              },
                              [_vm._v("Show more")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLessButton && _vm.showMoreButton
                          ? _c("div", { staticClass: "separator" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLessButton
                          ? _c(
                              "button",
                              {
                                staticClass: "show-less-button",
                                on: { click: _vm.showLess }
                              },
                              [_vm._v("Show less")]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm._m(17),
          _vm._v(" "),
          _c("div", { staticClass: "testimonial-div" }, [
            _c("div", { staticClass: "testimonial" }, [
              _vm._m(18),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "testimonial-cards" },
                [
                  _vm._l(_vm.orderedCards, function(card, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          "testimonial-card",
                          _vm.getExtraClass(index),
                          { faded: !_vm.isCenterCard(index) }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.setCenterCard(index)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "quote",
                          attrs: { src: card.quoteImage, alt: "Symbol Left" }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "testimonial-parag" }, [
                          _vm._v(_vm._s(card.text))
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "testimonial-separator" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "testimonial-footer" }, [
                          _c("img", {
                            staticClass: "testimonial-logo",
                            attrs: { src: card.logo, alt: "Company Logo" }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "testimonial-client-info" },
                            [
                              _c("p", { staticClass: "testimonial-client" }, [
                                _vm._v(_vm._s(card.client))
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "testimonial-client" }, [
                                _vm._v(_vm._s(card.role))
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "testimonial-client-company" },
                                [_vm._v(_vm._s(card.company))]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._m(19)
                ],
                2
              ),
              _vm._v(" "),
              _vm._m(20)
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row contact-container" }, [
          _c("div", { staticClass: "left-section container col-6" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: "/assets/schedule.png", alt: "contact" }
            }),
            _vm._v(" "),
            _vm._m(21),
            _vm._v(" "),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(
                "Ready to experience the power of Reservations Lab firsthand? Schedule a demo today and discover how our platform can transform your parking spaces' revenue potential."
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "action-button",
                on: {
                  click: function($event) {
                    return _vm.addscheduleDemoInitiateDataLayer(
                      "Schedule a demo"
                    )
                  }
                }
              },
              [_vm._v("Schedule a demo")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right-section container col-6" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: "/assets/message.png", alt: "contact" }
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "contact-header" }, [
              _vm._v("Get in Touch")
            ]),
            _vm._v(" "),
            _vm._m(22),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "action-button",
                on: {
                  click: function($event) {
                    return _vm.addscheduleDemoInitiateDataLayer("Contact us")
                  }
                }
              },
              [_vm._v("Contact us")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("new-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "main-heading" }, [
      _c("span", { staticClass: "maximizing-revenue" }, [
        _vm._v("Maximizing Revenue")
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "for" }, [_vm._v("for")]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "property-manager" }, [
        _vm._v("Property Managers")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "intro-paragraph" }, [
      _vm._v("\n                            Welcome to "),
      _c("span", { staticClass: "highlight" }, [_vm._v("Reservations Lab")]),
      _vm._v(
        ", where underutilized parking spaces become profitable assets. Our intelligent platform helps property managers like you transform vacant parking spots into steady revenue streams through seamless multi-platform distribution and smart dynamic pricing. We handle everything from listing to optimization, integrating effortlessly with your existing systems while requiring zero operational overhead. Join leading properties already maximizing their parking revenue with "
      ),
      _c("span", { staticClass: "highlight" }, [_vm._v("Reservations Lab.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 hero-image" }, [
      _c("img", {
        staticClass: "hero-gif",
        attrs: { src: "/assets/counter.gif", alt: "Animated GIF" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "desktop-image",
        attrs: { src: "/assets/Hero-image.png", alt: "Descriptive alt text" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "mobile-image",
        attrs: {
          src: "/assets/Hero-image-long.png",
          alt: "Descriptive alt text"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "revenue-calculator-content d-flex flex-column flex-md-row flex-justify-between flex-align-center"
      },
      [
        _c("h2", { staticClass: "revenue-calculator-title" }, [
          _vm._v("Revenue Calculator")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "revenue-calculator-description" }, [
          _vm._v(
            "\n                        Calculate the potential revenue increase for your parking spaces using our revenue calculator.\n                        See how Reservations Lab can help you optimize your available space and boost your bottom line.\n                    "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus main-p" }, [
      _c("button", { staticClass: "add-button" }, [_vm._v("+")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus sub" }, [
      _c("button", { staticClass: "add-button" }, [_vm._v("+")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "shuttle-service" } },
      [
        _vm._v("Shuttle service?\n                                    "),
        _c("span", { staticClass: "tooltip-icon", attrs: { tabindex: "0" } }, [
          _c("img", {
            staticClass: "icon default-icon",
            attrs: { src: "/assets/tool-tip-n-h.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon hover-icon",
            attrs: { src: "/assets/tool-tip.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "tooltip-content" }, [
            _vm._v(
              "\n                                        A free shuttle service that runs regularly to/from the airport\n                                    "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus sub" }, [
      _c("button", { staticClass: "add-button" }, [_vm._v("+")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "valet-parking" } },
      [
        _vm._v("Valet Parking?\n                                    "),
        _c("span", { staticClass: "tooltip-icon", attrs: { tabindex: "0" } }, [
          _c("img", {
            staticClass: "icon default-icon",
            attrs: { src: "/assets/tool-tip-n-h.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon hover-icon",
            attrs: { src: "/assets/tool-tip.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "tooltip-content" }, [
            _vm._v(
              "\n                                        Guests can have their car parked and retrieved by an employee\n                                    "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus sub" }, [
      _c("button", { staticClass: "add-button" }, [_vm._v("+")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "form-label", attrs: { for: "shuttle" } },
      [
        _vm._v("Is parking covered\n                                    "),
        _c("span", { staticClass: "tooltip-icon", attrs: { tabindex: "0" } }, [
          _c("img", {
            staticClass: "icon default-icon",
            attrs: { src: "/assets/tool-tip-n-h.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon hover-icon",
            attrs: { src: "/assets/tool-tip.png", alt: "info" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "tooltip-content" }, [
            _vm._v(
              "\n                                        If parking is underground or fully covered select covered\n                                    "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus sub" }, [
      _c("button", { staticClass: "add-button" }, [_vm._v("+")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", [_vm._v("Key Features")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                                Discover the key features that make Reservations Lab the ultimate revenue-maximizing solution for parking space managers:\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "features-grid" }, [
      _c("div", { staticClass: "feature-card card-1" }, [
        _c("img", {
          staticClass: "feature-icon",
          attrs: {
            src: "/assets/optimize.png",
            alt: "Revenue Optimization Icon"
          }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v("Revenue Optimization:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                                Maximize parking revenue through intelligent pricing that automatically adapts to demand and market conditions.\n                            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-card card-2" }, [
        _c("img", {
          staticClass: "feature-icon",
          attrs: {
            src: "/assets/flexible.png",
            alt: "Real-Time Analytics Icon"
          }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v("Flexible Guest and Rate Management:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                                Control the availability, rates, and bookings through one intuitive dashboard - no technical expertise required.\n                            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-card card-3" }, [
        _c("img", {
          staticClass: "feature-icon",
          attrs: {
            src: "/assets/solutions.png",
            alt: "User-Friendly Interface Icon"
          }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v("Zero Risk Start:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                                Launch within days with no upfront costs - our team handles the entire setup and onboarding process.\n                            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-card card-4" }, [
        _c("img", {
          staticClass: "feature-icon",
          attrs: { src: "/assets/integration.png", alt: "Secure Payments Icon" }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v("Easy Integration:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                                Quick setup with your existing systems through our flexible API - technical support included.\n                            "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "feature-left" }, [_vm._v("Feature")]),
        _vm._v(" "),
        _c("th", [_vm._v("Reservations Lab")]),
        _vm._v(" "),
        _c("th", [_vm._v("Competitors")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "icon-cell" }, [
      _c("img", { attrs: { src: "/assets/icon-Check.png", alt: "Check Icon" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "icon-cell" }, [
      _c("img", { attrs: { src: "/assets/icon-Close.png", alt: "Close Icon" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "testimonial-section" }, [
      _c("div", { staticClass: "testimonial-content" }, [
        _c("img", {
          staticClass: "testimonial-image",
          attrs: { src: "/assets/satisfaction.png", alt: "Reservations Lab" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "testimonial-text" }, [
          _c("p", [
            _vm._v("At "),
            _c("span", { staticClass: "highlight" }, [
              _vm._v("Reservations Lab")
            ]),
            _vm._v(
              ", your satisfaction is our driving force. We're proud to have empowered countless parking space managers to achieve exceptional results and unlock the full potential of their parking assets."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " But don't just take our word for it – our clients' success stories speak volumes:"
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "line-130",
        attrs: { alt: "arrow", src: "/assets/Line.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "testimonial-header" }, [
      _c("h2", { staticClass: "testimonial-title" }, [
        _vm._v("Client Testimonials")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "testimonial-subtitle" }, [
        _vm._v(
          "\n                        Here's what our clients have to say about their experience with Reservations Lab:\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tablet-c center-image-container" }, [
      _c("img", {
        staticClass: "center-image",
        attrs: { src: "/assets/carParking.png", alt: "Centered Image" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "general-c center-image-container" }, [
      _c("img", {
        staticClass: "center-image",
        attrs: { src: "/assets/carParking.png", alt: "Centered Image" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "contact-header" }, [
      _vm._v("Schedule "),
      _c("br"),
      _vm._v("a Demo")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        "Start maximizing your parking spaces' revenue potential today. Get in touch with us to learn more about how Reservations Lab can help you unlock the full value of your assets."
      ),
      _c("br"),
      _c("br"),
      _c("span", { staticClass: "after-break" }, [
        _vm._v(
          " Contact us now and take the first step towards boosting your parking space revenue."
        )
      ]),
      _c("br"),
      _c("span", { staticClass: "after-break" }, [
        _vm._v("We look forward to helping you achieve your revenue goals!")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60fa16c7", { render: render, staticRenderFns: staticRenderFns })
  }
}