import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import pagination from 'components/pagination'
import { funcModal, confirm } from 'components/modals'
import tabs from '../tabs'
import { hasPermits } from 'directives'
import { permits } from 'services'
import { dateFormat } from 'filters'

const directives = {
  hasPermits
}

const filters = {
  dateFormat
}

const components = {
  layout,
  datepicker,
  tabs,
  pagination,
  funcModal,
  confirm
}

const computed = {
  showAffectedTypes () {
    if (!this.roomTypes.length || !this.parkingTypes.length) {
      if (!this.roomTypes.length) {this.blackout_type = 'parking'}
      if (!this.parkingTypes.length) {this.blackout_type = 'room'}
      return true;
    } else if (this.blackout_type == 'room' || this.blackout_type == 'parking'){
      return true;
    }
    return false;
  },

  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },
  eligibleTypes () {
    if (!this.active_blackout) return [];
    return this.active_blackout.parking_types && this.active_blackout.parking_types.length ?
      this.active_blackout.parking_types:
      this.active_blackout.room_types
  },
  roomTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.roomTypes);
    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    for (let i in data) {
      this.form.room_types.push(
        {
          id: data[i].id,
          checked: false,
          available_num: null 
        }
      )
    }
    return data;
  },

  formRoomTypes () {
    let $types = this.form.room_types.filter((type) => {
      return type.checked == 1
    });
    return $types;
  },
  parkingTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.parkingTypes);
    data = data.filter(function (type) { return !type.parent_id; });

    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    for (let i in data) {
      this.form.parking_types.push(
        {
          has_children: data[i].childTypes && !_.isEmpty(data[i].childTypes),
          id: data[i].id,
          checked: false,
          available_num: null 
        }
      )
    }
    return data;
  },

  formParkingTypes () {
    let $types = this.form.parking_types.filter((type) => {
      return type.checked == 1
    });
    return $types;
  },

  bundleTypes () {
    let data = _.cloneDeep(this.$store.state.locations.active.bundleTypes);
    if (this.isLocationUser()) {
      data = data.filter(function (rate) { return rate['rate_type'] !== 'L'; });
    }
    return data;
  },

  hasSelectChannelsPermit () {
    return permits.hasLocationPermit(this.$route.params.id, 'location_channel_select');
  },

  blackouts () {
    return _.cloneDeep(this.$store.state.rateManagement.blackout_dates)
  }
}

const methods = {
  setBlackoutType ($type) {
    this.blackout_type = $type;
  },

  getBlackouts (page) {
    this.$route.query.page = page;
    this.loading = true;
    this.$store.dispatch('rateManagement.getBlackouts', {
      page,
      location_id: this.$route.params.id,
      from: this.from_date ? moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null,
      to: this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null
    }).then(() => {
      this.loading = false;
    });
  },

  showBlackout (blackout) {
    this.modalTitle = "Show Blackout Details";
    this.active_blackout = blackout;
    this.$refs.modal.open();
  },

  trueDays (item) {
    let days = [
      'sunday', 'monday', 'tuesday', 'wednesday',
      'thursday', 'friday', 'saturday'
    ];

    let to_return = [];
    for (let i in days) {
      if (item[days[i]])
        to_return.push(days[i].charAt(0).toUpperCase() + days[i].slice(1))
    }

    return to_return.join(', ')
  },

  removeBlackout (blackout) {
    let text = `Are you sure you want to delete blackout date
    from ${dateFormat(blackout.from_date,'MM/DD/YYYY')} to ${dateFormat(blackout.to_date,'MM/DD/YYYY')}
    `;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.$set(this.loadings, blackout.id, true);
      this.$store.dispatch('rateManagement.deleteBlackoutDate', {id: blackout.id, location_id: this.$route.params.id}).then(() => {
        this.$set(this.loadings, blackout.id, false);
      });
    });
  },
   
  submitBlackout () {
    this.errors = {};
    this.processing = true;
    let data = _.clone(_.extend(this.form, this.blackoutForm));
    data = _.extend(data, this.selected_days);

    data.room_types = this.formRoomTypes;
    data.parking_types = this.formParkingTypes;

    this.blackout_type == 'parking' ?
      delete data.room_types :
      delete data.parking_types;

    this.parseDates(data);
    let promises = [];
    if (this.blackout_type == 'parking') {
      for (let i in this.formParkingTypes) {
        data.parking_types = [];
        
        data.parking_types.push(this.formParkingTypes[i]['id']);
        data.available_num = this.formParkingTypes[i]['available_num'];
        let _data = _.clone(data);
        promises.push(this.$store.dispatch('rateManagement.submitBlackoutDate', _data))
      }
    }

    if (this.blackout_type == 'room') {
      for (let i in this.formRoomTypes) {
        data.room_types = [];
        
        data.room_types.push(this.formRoomTypes[i]['id']);
        data.available_num = this.formRoomTypes[i]['available_num'];
        let _data = _.clone(data);
        promises.push(this.$store.dispatch('rateManagement.submitBlackoutDate', _data))
      }
    }  
      Promise.all(promises).then(() => {
        this.processing = false;
          let errors;
          if (errors = this.$store.state.rateManagement.errors) {
            if (errors.errors) {
              this.errors = _.cloneDeep(errors.errors);
            }
    
            toastr.error(errors.message);
          } else {
            if (!isServer) {
             $("#newBlackout").toggleClass("in");
            }
            toastr.success('Data has been saved');
          }
      });
   
  },

  parseDates (data) {
    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD 00:00:00');
    }
    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD 00:00:00');
    }
  },

  selectAll (type) {

    if (type == 'channels') {
      //Note: type of channels is OBJECT
      for (let i in this.location.channels) {
        this.form.channels.push(this.location.channels[i].id);
      }
    } else if (type == 'parkingTypes') {
      this.parkingTypes.forEach((item) => {
        this.form.parking_types.push(item.id);
      });
    } else if (type == 'roomTypes') {
      this.roomTypes.forEach((item) => {
        this.form.room_types.push(item.id);
      });
    } else if (type == 'bundleTypes') {
      this.bundleTypes.forEach((item) => {
        this.form.bundle_types.push(item.id);
      });
    }
  },

  canUncheckDay (day) {
    let count = 0;
    for (let i in this.selected_days) {
      if (!this.selected_days[i] && day != i) {
        count++;
      }
    }

    return count < 6;
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

const watch = {
  formParkingTypes (n, o) {
    if(n.length) this.has_children = false;
    this.has_children = n.some((type) => {
      return type.has_children == true;
    });  
  }
}

export default {
  name: 'rate-modifications',
  components,
  filters,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      from_date: '',
      to_date: '',
      loading: true,
      loadings: {},
      processing: false,
      active_blackout: null,
      errors: {},
      locId: this.$route.params.id,
      type: "blackout",//blackout, adjustment, long_term, adjustment_bundles
      blackout_type: 'parking',//parking, room
      daysOfWeek: [
        "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
      ],
      selected_days: {
        saturday: true,
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true
      },
      form: {
        from_date: null,
        to_date: null,
        room_types: [],
        parking_types: [],
        bundle_types: [],
        channels: []
      },
      blackoutForm: {
        available_num: null
      },
      has_children: false,
    }
  },
  created () {

    if (permits.hasLocationPermit(this.locId, 'blackoutDate_create')) {
      this.type = 'blackout'
    }

    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id}),
      this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id, all: false}),
      this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id}),
      this.$store.dispatch('locations.getChannels', this.$route.params.id),
      this.getBlackouts(1)
    ]).then(() => {
      this.loading = false;
      this.selectAll('channels');
    });
  }
}
