var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container header" }, [
    _c(
      "div",
      {
        staticClass:
          "header-element flex flex-justify-between flex-align-center padding-top-2x padding-bottom-2x"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "burger-menu", on: { click: _vm.toggleMenu } },
          [_c("i", { staticClass: "fa fa-bars" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "header-menu flex flex-justify-between flex-align-center"
          },
          [
            _c(
              "router-link",
              {
                attrs: { to: { name: "property-manager" } },
                on: {
                  click: function($event) {
                    return _vm.setActiveTab("propertyManager")
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.activeTab === "propertyManager"
                        ? "active-tab"
                        : "inactive-tab"
                    ]
                  },
                  [
                    _vm._v(
                      "\n                    Property Managers\n                "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: { to: { name: "demandChannel" } },
                on: {
                  click: function($event) {
                    return _vm.setActiveTab("demandChannel")
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.activeTab === "demandChannel"
                        ? "active-tab"
                        : "inactive-tab"
                    ]
                  },
                  [
                    _vm._v(
                      "\n                    Demand Channel\n                "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn-header cyan-bg",
                attrs: { to: { name: "login" } }
              },
              [_vm._v("Sign In")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-header white-bg",
                on: {
                  click: function($event) {
                    return _vm.addscheduleDemoInitiateDataLayer("Contact Us")
                  }
                }
              },
              [_vm._v("Contact Us")]
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm.isMenuActive
      ? _c("div", { staticClass: "mobile-menu-overlay" }, [
          _c("div", { staticClass: "mobile-menu-main" }, [
            _c(
              "div",
              {
                staticClass:
                  "mobile-menu-header flex flex-justify-between flex-align-center"
              },
              [
                _c("span", { staticClass: "menu-title" }, [_vm._v("Menu")]),
                _vm._v(" "),
                _c("span", { staticClass: "close-btn" }, [
                  _vm._v("Close"),
                  _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/assets/close-24.png", alt: "Close" },
                    on: { click: _vm.toggleMenu }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mobile-menu-content" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: { name: "property-manager" } },
                    on: {
                      click: function($event) {
                        _vm.setActiveTab("propertyManager")
                        _vm.toggleMenu()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.activeTab === "propertyManager"
                            ? "active-tab"
                            : "inactive-tab"
                        ]
                      },
                      [
                        _vm._v(
                          "\n                    Property Managers\n                "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: { to: { name: "demandChannel" } },
                    on: {
                      click: function($event) {
                        _vm.setActiveTab("demandChannel")
                        _vm.toggleMenu()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.activeTab === "demandChannel"
                            ? "active-tab"
                            : "inactive-tab"
                        ]
                      },
                      [
                        _vm._v(
                          "\n                    Demand Channel\n                "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-div" },
                  [
                    _c("hr", { staticClass: "header-separator" }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-header white-bg-m",
                        on: {
                          click: function($event) {
                            _vm.addscheduleDemoInitiateDataLayer("Contact Us")
                            _vm.toggleMenu()
                          }
                        }
                      },
                      [_vm._v("Contact Us")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn-header cyan-bg",
                        attrs: { to: { name: "login" } },
                        on: {
                          click: function($event) {
                            return _vm.toggleMenu()
                          }
                        }
                      },
                      [_vm._v("Login")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "rl-logo",
        attrs: { src: "/assets/logo.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-21d612e0", { render: render, staticRenderFns: staticRenderFns })
  }
}