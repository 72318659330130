export default {
  name: "logo-slider",
  data () {
    return {
      logos: [
          { src: "/assets/quality.png", alt: "Logo 1" },
          { src: "/assets/Sheraton.png", alt: "Logo 2" },
          { src: "/assets/jiffy.png", alt: "Logo 3" },
          { src: "/assets/parknjet.png", alt: "Logo 4" },
          { src: "/assets/routes.png", alt: "Logo 5" },
          { src: "/assets/crowneplaza.png", alt: "Logo 6" },
          { src: "/assets/Hyatt-t.png", alt: "Logo 7" },
          { src: "/assets/marriott-t.png", alt: "Logo 8" },
          { src: "/assets/Radisson-t.png", alt: "Logo 9" },
          { src: "/assets/valetconnections-t.png", alt: "Logo 10" },
          { src: "/assets/westin-t.png", alt: "Logo 11" },
          { src: "/assets/hilton.png", alt: "Logo 12" },
          { src: "/assets/holiday.png", alt: "Logo 13" },
          { src: "/assets/laz.png", alt: "Logo 14" },
          { src: "/assets/valetconnections-t.png", alt: "Logo 15" },
          { src: "/assets/logoSliderCar.png", alt: "Logo 16" },
      ],
      }
  },
}