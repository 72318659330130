import {
  salesPerformance, occupancy, dashboard, softAndHardBlackouts
} from 'pages/locations/analytics'

export default [
  // analytics
  // { path: '/locations/:id/analytics/sales-performance', name: 'locations.analytics.salesPerformance', component: salesPerformance
  //   , meta:{permits:['location_user', 'analytics_ales_performance']}},

  { path: '/locations/:id/analytics/occupancy', name: 'locations.analytics.occupancy', component: occupancy
    , meta:{permits:['location_user', 'analytics_occupancy']}},

  { path: '/locations/:id/analytics/dashboard', name: 'locations.analytics.dashboard', component: dashboard
    , meta:{permits:['location_user', 'analytics_channels_contributions']}},
  
    { path: '/locations/:id/analytics/soft-and-hard-blackouts', name: 'locations.analytics.soft-and-hard-blackouts', component: softAndHardBlackouts
    , meta:{permits:['location_user', 'analytics_soft_hard_blackouts']}},
]
