import moment from 'moment'
import bus from 'bus'
import hasPermits from 'directives/hasPermits'
import { user, permits } from 'services'
import sSelect from 'components/sSelect'

const directives = {
  hasPermits
}

const components = {
  sSelect
}

const methods = {
  changeSelected (selected) {
    let url = window.location.href;
    let parkingLeaseIndex = url.indexOf("parking-lease-types");
    if (parkingLeaseIndex !== -1) {
      url = url.substring(0, parkingLeaseIndex);
      url += 'rate-management/types';
    }
    url = url.replace(this.activeLocation.value, selected.value);
    window.location.href = url;
  },
  changeRoute (route) {
    this.$store.dispatch('nav.changeRoute', route);
  },
  logout () {
    this.$store.dispatch('user.logout').then(() => {
      window.location.href = "/login";
    });
  },
  toggleSidebar () {
    if ($('body').hasClass('sidebar-collapse')) {
      $('body').removeClass('sidebar-collapse')
      this.sideBarIsActive = true;
    } else {
      $('body').addClass('sidebar-collapse')
      this.sideBarIsActive = false;
    }

    bus.$emit('layout.sideBarToggled', this.sideBarIsActive)
  },

  hasAccessToReports () {
    let u = user.getUser();
    const permissions = [
      'report_locations', 'report_blackouts', 
      'report_commission', 'report_checkin_checkout', 
      'report_arrivals', 'report_departures', 
      'report_utilization'
    ]

    if (permits.hasPermit('location_user')) {
      for (let i in u.locations) {
        let found = _.findIndex(u.locations[i].roles[0].permissions, (item) => permissions.indexOf(item.name) > -1) > -1
        
        if (found) return true
      }
    }

    if (permits.hasPermits(permissions)) {
      return true
    }

    return false
  },

  hasAccessToRefundRequests () {
    let u = user.getUser();
    const permissions = [
      'refundRequest_list', 'refundRequest_update' ]

    if (permits.hasPermit('location_user')) {
      for (let i in u.locations) {
        let found = _.findIndex(u.locations[i].roles[0].permissions, (item) => permissions.indexOf(item.name) > -1) > -1
        
        if (found) return true
      }
    } 

    if (permits.hasPermits(permissions) || permits.hasPermit("channel_user") || permits.hasPermit("super_admin")) {
      return true
    }

    return false
  }
}

const computed = {

  isChannelUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('channel_user');
  },

  activeNav () {
    return this.$store.state.nav.active;
  },

  locations () {
    return _.cloneDeep(this.$store.state.locations.all);
  },

  allLocations () {
    if (this.locations.length) {
      return _.map(this.locations, item => {
        return {
          value: item.id,
          text: item.name
        }
      });
    } else {
      return [];
    }
    
},

  activeLocation () {
    return this.allLocations.find(loc => loc.value == this.$route.params.id);
  },

  isAdmin () {
    return this.user && this.user.type == 'ims';
  },

  location () {
    return this.$store.state.locations.active;
  }
}

const watch = {
  location () {
    if (this.location && this.location.cancellation_policies) {
      this.parkingCancellationPolicy = this.location.cancellation_policies.some((cp) => {
        return cp.type == 'parking';
      });
    }
  }
}


export default {
  props: ['noBox', 'title', 'breadcrumb', 'noSidebar'],
  methods,
  directives,
  computed,
  components,
  watch,
  data () {
    return {
      year: moment().format('YYYY'),
      user: user.getUser(),
      sideBarIsActive: false,
      isLocationPage : false,
      parkingCancellationPolicy: true,
    }
  },
  created () {
    // console.log('this.$route.name', this.$route.name);
    this.changeRoute(this.$route.name);
    if (!isServer) {
      if (window.location.href.indexOf("locations/") > -1 && !this.isChannelUser) {
        this.$store.dispatch('locations.getAll', {_q: "", _all: true})
      }
    }
  },
  mounted () {
    if (this.noSidebar) {
      $('body').addClass('sidebar-collapse');
    }

    console.log("$('body').hasClass('sidebar-collapse')", $('body').hasClass('sidebar-collapse'));
    if ($('body').hasClass('sidebar-collapse')) {
      this.sideBarIsActive = false;
    } else {
      this.sideBarIsActive = true;
    }

    if (!isServer) {
      if (window.location.href.indexOf("locations/") > -1) {
        this.isLocationPage = true;
        if (window.matchMedia('(max-width: 767px)').matches) {
          $('body').addClass('sidebar-collapse');
          this.sideBarIsActive = false;
        }
      }    
    }
  }
}
