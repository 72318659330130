import { user, DataLayer} from 'services'
let computed = {
  user () {
    return user.getUser();
  },
} 
export default {
    name: "new-footer",
    computed,
    data () {
      return {
        email: null,
        currentYear: new Date().getFullYear(),
      }
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      addscheduleDemoInitiateDataLayer(button_text) {
        const currentSource = this.$route.name === 'property-manager' ? 'propertyManager' : 'demandChannel';
        if (!isServer) {
          DataLayer.scheduleDemoInitiate(this.user,button_text);
        }
        this.$router.push({ name: 'contact-us', query: { source: currentSource, buttonText: button_text } });
      },
      addLeadTypeDataLayer (lead_type = 'Socials') {
        if (!isServer) {
          DataLayer.generateLead(this.user, lead_type, this.email);
        }
      }
    }
};