import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
import pagination from 'components/pagination'
import { dateFormat, currencySymbol } from 'filters'
import { funcModal, confirm } from 'components/modals'
import  datepicker  from 'components/datepicker'
import tabs from '../tabs'
import { permits } from 'services'

const components = {
  layout,
  pagination,
  funcModal,
  confirm,
  tabs,
  datepicker
}

const directives = {
  hasPermits
}

const filters = {
  dateFormat,
  currencySymbol
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },
  currency() {
    return _.cloneDeep(this.location.currency);
  },
  longTerms () {
    return _.cloneDeep(this.$store.state.rateManagement.long_term_discounts)
  },
  adjustments () {
    return _.cloneDeep(this.$store.state.rateManagement.rate_adjustments)
  },
  eligibleTypes () {
    if (!this.active_blackout) return [];
    return this.active_blackout.parking_types.length ?
      this.active_blackout.parking_types:
      this.active_blackout.room_types
  },
  isDisabled() {
    let data =  _.cloneDeep(this.$store.state.rateManagement.rate_adjustments)
    return data.data.length === 0 ? true : false
  },
}

const methods = {
  actionTaken(adjustment) {
    let text = `${adjustment.modification_type} by ${currencySymbol(this.currency.code)}${Number(adjustment.modification_amount).toFixed(2)}`;
    if (adjustment.modification_amount_type == 'percentage') {
      text = `${adjustment.modification_type} by ${Number(adjustment.modification_amount).toFixed(2)}%`;
    }
    return text;
  },
  search() {
    this.getType();
  },
  go () {
    this.resetSearchInputs();
    this.getType();
  },
  getType () {
    this.type = this.$refs.types.value;
    if (!this.type) return;

    this.loadings = {};

    if (this.type == 'long_terms') {
      this.getLongTerm(1)
    } else if (this.type == 'adjustments') {
      this.getAdjustments(1)
    }
  },
  resetSearchInputs() {
    this.from_date = '';
    this.to_date = '';
    this.type_name = '';
  },

  getLongTerm (page) {
    this.$route.query.page = page;
    this.loading = true;
    this.$store.dispatch('rateManagement.getLongTermDiscounts', {
      page,
      location_id: this.location_id,
      from: this.from_date ? moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null,
      to: this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null
    }).then(() => {
      this.loading = false;
    });
  },

  getAdjustments (page) {
    this.$route.query.page = page;
    this.loading = true;
    this.$store.dispatch('rateManagement.getRateAdjustments', {
      params: {
        page,
        filter: this.filter != 'all' ? this.filter : null,
        adj_type: this.adjType != 'all' ? this.adjType : null,
        name: this.type_name.length ? this.type_name : null,
        from: this.from_date ? moment(this.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null,
        to: this.to_date ? moment(this.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD HH:mm:ss') : null
      },
      location_id: this.location_id
    }).then(() => {
      this.loading = false;
    });
  },

  removeBlackout (blackout) {
    let text = `Are you sure you want to delete blackout date
    from ${dateFormat(blackout.from_date,'MM/DD/YYYY')} to ${dateFormat(blackout.to_date,'MM/DD/YYYY')}
    `;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.$set(this.loadings, blackout.id, true);
      this.$store.dispatch('rateManagement.deleteBlackoutDate', {id: blackout.id, location_id: this.location_id}).then(() => {
        this.$set(this.loadings, blackout.id, false);
      });
    });
  },

  removeLong (longterm) {
    let text = `Are you sure you want to delete long term discount
    from ${dateFormat(longterm.from_date,'MM/DD/YYYY')} to ${dateFormat(longterm.to_date,'MM/DD/YYYY')}
    `;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.$set(this.loadings, longterm.id, true);
      this.$store.dispatch('rateManagement.deleteLongTermDiscount', {id: longterm.id, location_id: this.location_id}).then(() => {
        this.$set(this.loadings, longterm.id, false);
      });
    });
  },

  removeAdjustment (rate, force = false) {
    let text = `Are you sure you want to delete  " ${this.actionTaken(rate)} " ?`;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.$set(this.loadings, rate.id, true);
      this.$store.dispatch('locations.removeRate', {type: rate.type, rate, force}).then(() => {
        this.$set(this.loadings, rate.id, false);
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if(errors.status_code == 400) {
            this.$refs.deleteConfirm.confirm('This rate has reservations in it, are you realy sure?', () => {
              this.removeAdjustment(rate, true);
            })
          } else if (errors) {
            _.forEach(errors, (error) => {
              toastr.error(error[0]);
            })
          }
        }
      });
    });
  },

  removeAdjustments (rate, force = false) {
    let text = `Are you sure you want to delete  " ${this.actionTaken(rate)} " ?`;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.$set(this.loadings, rate.rates_adjustments_history_id, true);
      this.$store.dispatch('locations.removeRates', {location_id: this.location_id, rate, force}).then(() => {
        this.$set(this.loadings, rate.rates_adjustments_history_id, false);
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if (errors.message && errors.message.includes("You don't have permission to delete")) {
            toastr.error(errors.message);
          }
          else if(errors.status_code == 400) {
            this.$refs.deleteConfirm.confirm('This rate has reservations in it, are you realy sure?', () => {
              this.removeAdjustments(rate, true);
            })
          } else if (errors) {
            _.forEach(errors, (error) => {
              toastr.error(error[0]);
            })
          }
        }
      });
    });
  },

  clearHistory (force = false) {
    let text = `Are you sure you want to clear all history ?`;
    this.$refs.deleteConfirm.confirm(text, () => {
      this.adjustments.data.forEach((value, index) => {
        this.$set(this.loadings,value.rates_adjustments_history_id, true);
      });  
      this.$store.dispatch('locations.clearHistory', {location_id: this.location_id, force}).then(() => {
        this.adjustments.data.forEach((value, index) => {
          this.$set(this.loadings,value.rates_adjustments_history_id, false);
        });  
      });
    });
  },

  showBlackout (blackout) {
    this.modalTitle = "Show Blackout Details";
    this.active_blackout = blackout;
    this.$refs.modal.open();
  },

  showLongTerm (long) {
    this.modalTitle = "Show Long Term Discount Details";
    this.active_long_term_discount = long;
    this.$refs.modal.open();
  },

  showAdjustment (adj) {
    this.modalTitle = "Show Rate Adjustment Details";
    adj.modification_amount = Number(adj.modification_amount).toFixed(2);
    this.active_rate_adjustment = adj;
    this.$refs.modal.open();
  },

  trueDays (item) {
    let days = [
      'sunday', 'monday', 'tuesday', 'wednesday',
      'thursday', 'friday', 'saturday'
    ];

    let to_return = [];
    for (let i in days) {
      if (item[days[i]])
        to_return.push(days[i].charAt(0).toUpperCase() + days[i].slice(1))
    }

    return to_return.join(', ')
  },
  
  toggle(id) {
    const index = this.opened.indexOf(id);
    if (index > -1) {
      this.opened.splice(index, 1)
      this.arrow[id] = 'down'
    } else {
      this.opened.push(id)
      this.arrow[id] = 'up'
    }
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: 'rate-modifications',
  components,
  computed,
  methods,
  filters,
  directives,
  data () {
    return {
      loading: true,
      loadings: {},
      type: null,//blackouts,
      location_id: null,
      errors: {},
      modalTitle: null,
      active_blackout: null,
      active_long_term_discount: null,
      active_rate_adjustment: null,
      filter: 'all', //all, room, type, bundle
      adjType: 'all', //all, manual, dynamic
      type_name: '',
      from_date: '',
      to_date: '',
      opened: [],
      arrow: [],
    }
  },
  created () {
    this.location_id = this.$route.params.id;
    this.$store.dispatch('locations.get', {id: this.$route.params.id}).then(() => {
      this.loading = false
    });
  }
}
