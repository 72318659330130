import { http } from 'services'
// initial state
const state = () => ({
  reservation: null,
  checkin_list: null,
  checkout_list: null,
  contact_us_form: [],
  airports: [],
  revenue: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['dashboards.validateReservation'] ({ commit, state }, data) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations/${data.id}/validation?location_id=${data.location_id}`)).then ((res) => {
      commit('VALIDATE_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.checkInReservation'] ({ commit, state }, {id, location_id}) {
    commit('CLEAR_ERRORS')

    return (http.post(`locations/${location_id}/reservations/${id}`, {
      action: 'checkin'
    })).then ((res) => {
      commit('CHECKIN_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.checkOutReservation'] ({ commit, state }, {id, location_id}) {
    commit('CLEAR_ERRORS')

    return (http.post(`locations/${location_id}/reservations/${id}`, {
      action: 'checkout'
    })).then ((res) => {
      commit('CHECKOUT_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.getReservation'] ({ commit, state }, {item}) {
    commit('GET_RESERVATION', item);
  },

  ['dashboards.showReservation'] ({ commit, state }, {id, from, type}) {
    commit('CLEAR_ERRORS')

    return (http.post(`reservations/${id}/no-show`, {
      from
    })).then ((res) => {
      if (from == 'location') {
        commit('GET_RESERVATION', res.data.history.reservation);
        if (type == "checkout") {
          commit('NOSHOW_CHECKOUT_RESERVATION');
        } else {
          commit('NOSHOW_CHECKIN_RESERVATION');
        } 
      } else {
        commit('GET_RESERVATION', res.data);
      }
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.update_no_show_reseration'] ({ commit, state }, {data}) {
    commit('CLEAR_ERRORS')
    commit('NOSHOW_CHECKIN_RESERVATION');
    commit('NOSHOW_CHECKOUT_RESERVATION');
  },

  ['dashboards.getCheckinList'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    if (!params) {
      params = {}
    }
    params._all = true;
    
    commit('SET_ALL_CHECKIN_FLAG', true);
    if (params.type != 'all' && params.type !== null ) {
      commit('SET_ALL_CHECKIN_FLAG', false);
    } 
    return (http.get(`locations/${location_id}/arrivals`, {params})).then ((res) => {
      commit('GET_CHECKIN_LIST', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.getCheckOutList'] ({ commit, state }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    if (!params) {
      params = {}
    }
    params._all = true;
    commit('SET_ALL_CHECKOUT_FLAG', true);
    if (params.type !='all' && params.type != null ) {
      commit('SET_ALL_CHECKOUT_FLAG', false);
    } 
    return (http.get(`locations/${location_id}/departures`, {params})).then ((res) => {
      commit('GET_CHECKOUT_LIST', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.cancelReservation'] ({ commit, state }, {channel_id, reservation_id, refund_type = 'without_refund', amount = 0}) {
    commit('CLEAR_ERRORS')

    const param = refund_type == 'partial_refund' ?  {refund_type : refund_type , amount : amount} :  {refund_type : refund_type};
    return (http.put(`channels/${channel_id}/reservations/${reservation_id}/cancel`,param)).then ((res) => {
      commit('CANCEL_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },
  ['dashboards.getContactUsFormData'] ({ commit }) {
    commit('CLEAR_ERRORS')

    return (http.get('contact-us-form'))
    .then((res) => {
      commit('GET_CONTACT_US_FORM_DATA', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },
  ['dashboards.getAirports'] ({ commit }) {
    commit('CLEAR_ERRORS')

    return (http.get('airports'))
    .then((res) => {
      commit('GET_AIRPORTS', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.getRevenue'] ({ commit }) {
    commit('CLEAR_ERRORS')

    return (http.get('get-revenue'))
    .then((res) => {
      commit('GET_Revenue', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

}

// mutations
const mutations = {

  VALIDATE_RESERVATION (state, res) {
    state.reservation = res.data
  },

  GET_CONTACT_US_FORM_DATA (state, res) {
    state.contact_us_form = res.data;
  },

  GET_AIRPORTS (state, res) {
    state.airports = res.data;
  },

  GET_Revenue (state, res) {
    state.revenue = res.data.airports_revenue;
  },

  GET_CHECKIN_LIST (state, res) {
    state.checkin_list = res.data
  },

  SET_ALL_CHECKIN_FLAG (state, res) {
    state.checkin_get_all = res
  },

  SET_ALL_CHECKOUT_FLAG (state, res) {
    state.checkout_get_all = res
  },

  GET_CHECKOUT_LIST (state, res) {
    state.checkout_list = res.data
  },

  GET_RESERVATION (state, res) {
    state.reservation = res;
  },

  CHECKIN_RESERVATION (state, res) {
    let index = _.findIndex(state.checkin_list, (item) => {
      return item.id == res.data.id
    });

    if (index > -1 ) {
      state.checkin_list[index] = _.assign(state.checkin_list[index], res.data);
      state.checkin_list = _.cloneDeep(state.checkin_list);
    }
  },

  NOSHOW_CHECKIN_RESERVATION (state) {
    let items = [];
    if (state.checkin_list) {
      state.checkin_list.filter((item) => {
        if (item.history.reservation.id == state.reservation.id) {
          items.push(item);
        }
      });
    }

    _.forEach(items, element => {
      let index = state.checkin_list.findIndex(i => i.id === element.id);
      if (index > -1 ) {
        state.checkin_list[index].history.reservation.no_show = state.reservation.no_show;
        state.checkin_list = _.cloneDeep(state.checkin_list);
      }
    });
  },

  NOSHOW_CHECKOUT_RESERVATION (state) {
    let items = [];
    if (state.checkout_list) {
      state.checkout_list.filter((item) => {
        if (item.history.reservation.id == state.reservation.id) {
          items.push(item);
        }
      });
    }

    _.forEach(items, element => {
      let index = state.checkout_list.findIndex(i => i.id === element.id);
      if (index > -1 ) {
        state.checkout_list[index].history.reservation.no_show = state.reservation.no_show;
        state.checkout_list = _.cloneDeep(state.checkout_list);
      }
    });
  },

  CHECKOUT_RESERVATION (state, res) {
    let index = _.findIndex(state.checkout_list, (item) => {
      return item.id == res.data.id
    });

    if (index > -1) {
      state.checkout_list[index] = _.assign(state.checkout_list[index], res.data);
      state.checkout_list = _.cloneDeep(state.checkout_list);
    }
  },

  CANCEL_RESERVATION (state, reservation) {
    state.reservation = reservation.data;
  },

  //ERRORS
  DASHBOARDS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
