var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-box": true, title: _vm.location ? _vm.location.name : "" } },
    [
      _c("tabs"),
      _vm._v(" "),
      _vm.typesLoading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "box-header with-border margin-bottom-8x" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-inline margin-left-2x" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "s-select",
                          {
                            attrs: {
                              selected: _vm.selectedTypes,
                              options: _vm.leaseParkingTypes,
                              multiple: true
                            },
                            on: { selectedChanged: _vm.onChangeSelectedTypes }
                          },
                          _vm._l(_vm.leaseParkingTypes, function(
                            leaseParkingType
                          ) {
                            return _c(
                              "option",
                              { domProps: { value: leaseParkingType.id } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      (leaseParkingType.deleted_at ? "*" : "") +
                                        leaseParkingType.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("datepicker", {
                          staticClass: "form-control",
                          attrs: {
                            "min-date": "none",
                            placeholder: "Filter by From Date"
                          },
                          model: {
                            value: _vm.form.from_date,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "from_date", $$v)
                            },
                            expression: "form.from_date"
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.from_date
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.from_date) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("datepicker", {
                          staticClass: "form-control",
                          attrs: {
                            "min-date": _vm.form.from_date,
                            placeholder: "Filter by To Date"
                          },
                          model: {
                            value: _vm.form.to_date,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "to_date", $$v)
                            },
                            expression: "form.to_date"
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.to_date) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.soft_days_before,
                              expression: "form.soft_days_before"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Max # Soft Days Before",
                            type: "text"
                          },
                          domProps: { value: _vm.form.soft_days_before },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "soft_days_before",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.soft_days_before
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.soft_days_before) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.soft_days_after,
                              expression: "form.soft_days_after"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Max # Soft Days After",
                            type: "text"
                          },
                          domProps: { value: _vm.form.soft_days_after },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "soft_days_after",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.soft_days_after
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.soft_days_after) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-md",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.getSoftHardBlackouts()
                            }
                          }
                        },
                        [_vm._v("\n              Get Blackouts\n            ")]
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-body margin-bottom-8x" },
              [
                _vm.softAndHardBlackoutsLoading === true
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("i", {
                        staticClass: "fa fa-spinner fa-spin fa-3x fa-fw"
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.typesBlackoutsData, function(
                  typeSoftAndHardBlackout,
                  typeIndex
                ) {
                  return _vm.softAndHardBlackoutsLoading === false
                    ? _c("div", [
                        _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "box-header with-border" }, [
                            _c("h3", [
                              _vm._v(_vm._s(typeSoftAndHardBlackout.type.name))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group" }, [
                              typeSoftAndHardBlackout.type.is_average_percentage
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "input-group-addon",
                                      attrs: { id: "basic-addon1" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-percent",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "input-group-addon",
                                      attrs: { id: "basic-addon1" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-hashtag",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      typeSoftAndHardBlackout.type
                                        .average_spots,
                                    expression:
                                      "typeSoftAndHardBlackout.type.average_spots"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { placeholder: "average", type: "text" },
                                domProps: {
                                  value:
                                    typeSoftAndHardBlackout.type.average_spots
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      typeSoftAndHardBlackout.type,
                                      "average_spots",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    typeSoftAndHardBlackout.type
                                      .is_average_percentage,
                                  expression:
                                    "typeSoftAndHardBlackout.type.is_average_percentage"
                                }
                              ],
                              attrs: {
                                id: "is-average-percentage" + typeIndex,
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  typeSoftAndHardBlackout.type
                                    .is_average_percentage
                                )
                                  ? _vm._i(
                                      typeSoftAndHardBlackout.type
                                        .is_average_percentage,
                                      null
                                    ) > -1
                                  : typeSoftAndHardBlackout.type
                                      .is_average_percentage
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      typeSoftAndHardBlackout.type
                                        .is_average_percentage,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          typeSoftAndHardBlackout.type,
                                          "is_average_percentage",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          typeSoftAndHardBlackout.type,
                                          "is_average_percentage",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      typeSoftAndHardBlackout.type,
                                      "is_average_percentage",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: "is-average-percentage" + typeIndex
                                }
                              },
                              [_vm._v("Percentage")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "box-body margin-bottom-2x",
                            staticStyle: { "border-left": "3px solid #222d32" }
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center"
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("#")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("From Date")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("To Date")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Created Date")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Deleted Date")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Main Impact")]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v("Available number of spots")
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v("Interval Between Previous Period")
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Soft days before")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Soft days after")])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    typeSoftAndHardBlackout.grouped_blackout_periods,
                                    function(blackout, index) {
                                      return _c("tr", [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .moment(blackout.from, [
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ])
                                                .format("MM/DD/YYYY")
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .moment(blackout.to, [
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ])
                                                .format("MM/DD/YYYY")
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .moment(blackout.created_at, [
                                                  "YYYY-MM-DD HH:mm:ss"
                                                ])
                                                .format("MM/DD/YYYY")
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              blackout.deleted_at
                                                ? _vm
                                                    .moment(
                                                      blackout.deleted_at,
                                                      ["YYYY-MM-DD HH:mm:ss"]
                                                    )
                                                    .format("MM/DD/YYYY")
                                                : "-"
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              blackout.is_hard ? "Hard" : "Soft"
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(blackout.available_num))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(blackout.interval_before)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          blackout.is_hard
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value:
                                                      blackout.soft_days_before,
                                                    expression:
                                                      "blackout.soft_days_before",
                                                    modifiers: { number: true }
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder:
                                                    "Soft Days Before",
                                                  type: "number",
                                                  min: "0"
                                                },
                                                domProps: {
                                                  value:
                                                    blackout.soft_days_before
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      blackout,
                                                      "soft_days_before",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$forceUpdate()
                                                  }
                                                }
                                              })
                                            : _c("span", [_vm._v("-")])
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          blackout.is_hard
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value:
                                                      blackout.soft_days_after,
                                                    expression:
                                                      "blackout.soft_days_after",
                                                    modifiers: { number: true }
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder:
                                                    "Soft Days After",
                                                  type: "number",
                                                  min: "0"
                                                },
                                                domProps: {
                                                  value:
                                                    blackout.soft_days_after
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      blackout,
                                                      "soft_days_after",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$forceUpdate()
                                                  }
                                                }
                                              })
                                            : _c("span", [_vm._v("-")])
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.showSoftHardBlackoutsChartsData[
                                      typeIndex
                                    ].showHardBlackouts,
                                  expression:
                                    "showSoftHardBlackoutsChartsData[typeIndex].showHardBlackouts"
                                }
                              ],
                              attrs: {
                                id: "show-hard-blackouts" + typeIndex,
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.showSoftHardBlackoutsChartsData[typeIndex]
                                    .showHardBlackouts
                                )
                                  ? _vm._i(
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ].showHardBlackouts,
                                      null
                                    ) > -1
                                  : _vm.showSoftHardBlackoutsChartsData[
                                      typeIndex
                                    ].showHardBlackouts
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ].showHardBlackouts,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.showSoftHardBlackoutsChartsData[
                                            typeIndex
                                          ],
                                          "showHardBlackouts",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.showSoftHardBlackoutsChartsData[
                                            typeIndex
                                          ],
                                          "showHardBlackouts",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ],
                                      "showHardBlackouts",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: "show-hard-blackouts" + typeIndex
                                }
                              },
                              [_vm._v("Show Hard Blackouts")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.showSoftHardBlackoutsChartsData[
                                      typeIndex
                                    ].showSoftBlackouts,
                                  expression:
                                    "showSoftHardBlackoutsChartsData[typeIndex].showSoftBlackouts"
                                }
                              ],
                              attrs: {
                                id: "show-soft-blackouts" + typeIndex,
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.showSoftHardBlackoutsChartsData[typeIndex]
                                    .showSoftBlackouts
                                )
                                  ? _vm._i(
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ].showSoftBlackouts,
                                      null
                                    ) > -1
                                  : _vm.showSoftHardBlackoutsChartsData[
                                      typeIndex
                                    ].showSoftBlackouts
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ].showSoftBlackouts,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.showSoftHardBlackoutsChartsData[
                                            typeIndex
                                          ],
                                          "showSoftBlackouts",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.showSoftHardBlackoutsChartsData[
                                            typeIndex
                                          ],
                                          "showSoftBlackouts",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.showSoftHardBlackoutsChartsData[
                                        typeIndex
                                      ],
                                      "showSoftBlackouts",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: "show-soft-blackouts" + typeIndex
                                }
                              },
                              [_vm._v("Show Soft Blackouts")]
                            ),
                            _vm._v(" "),
                            _c("apexchart", {
                              attrs: {
                                type: "line",
                                height: "350",
                                options:
                                  _vm.displayedSoftAndHardBlackouts[typeIndex]
                                    .chartOptions,
                                series:
                                  _vm.displayedSoftAndHardBlackouts[typeIndex]
                                    .seriesData
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.softAndHardBlackoutsLoading === false
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-md",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.calculate()
                            }
                          }
                        },
                        [_vm._v("\n          Calculate\n        ")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.calculationsLoading === true
              ? _c("div", { staticClass: "text-center" }, [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationsLoading === false
              ? _c("div", { staticClass: "box-body p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "chart-box chart-box--shadow table-responsive"
                    },
                    [
                      _c("div", { staticClass: "chart-box chart-box--title" }, [
                        _c("h3", [_vm._v("Soft and Hard Blackouts ")])
                      ]),
                      _vm._v(" "),
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              _c("th", [
                                _vm._v(
                                  "\n                Type Name\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                Total Loss\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.softAndHardBlackoutsCalculations[0].days,
                                function(day) {
                                  return _c("th", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm
                                            .moment(day.date, ["YYYY-MM-DD"])
                                            .format("ddd")
                                        ) +
                                        " \n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm
                                            .moment(day.date, ["YYYY-MM-DD"])
                                            .format("MM-DD")
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              )
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.softAndHardBlackoutsCalculations, function(
                            leaseTypeData
                          ) {
                            return _c(
                              "tr",
                              [
                                _c("th", { staticClass: "rought-td" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(leaseTypeData.type.name) +
                                      "\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          leaseTypeData.total_loss
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._l(leaseTypeData.days, function(day, i) {
                                  return _c(
                                    "td",
                                    {
                                      key: i,
                                      class: [
                                        { "bg-success": day.lost_amount <= 0 },
                                        {
                                          peak_day:
                                            day.peak_period != null &&
                                            !_vm.isChannelUser
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          ref: "cells",
                                          refInFor: true,
                                          attrs: {
                                            "data-placement": "top",
                                            "data-original-title":
                                              " lost spot/s"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  day.lost_amount
                                                )
                                              ) +
                                              "\n                \n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-71aff2ad", { render: render, staticRenderFns: staticRenderFns })
  }
}