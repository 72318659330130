import toastr from 'toastr'
import layout from 'layouts/default'
import {dateTime} from 'services'
import placesAutocomplete from 'components/placesAutocomplete'
import switchBtn from 'components/switchBtn'
import googleMapsMovableMarker from 'components/googleMapsMovableMarker'
import moment from 'moment'

const directives = {
}

const components = {
  layout,
  placesAutocomplete,
  switchBtn,
  googleMapsMovableMarker
}

const methods = {
  onLocationChanged (location) {
    this.formData.latitude = location.lat;
    this.formData.longitude = location.lng;
  },
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;

    if (this.formData.state_id == "") {
      this.formData.state_id = null;
    }

    this.$store.dispatch('locations.add', _.cloneDeep(this.formData)).then(() => {
      this.submit_loading = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('A new location has been created');

        this.formData = {
          name: null,
          phone: null,
          address: null,
          number_of_rooms: null,
          number_of_parkings: null,
          country_id: "",
          state_id: ""
        }

        this.$router.push({name: 'locations.edit', params: {
          id: this.$store.state.locations.active.id
        }});
      }
    })
  }
}

const computed = {
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: ""}];
    if (this.formData.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.formData.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  },
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },

  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },
  center () {
    if (this.googlePlace) {
      let location = {
        lat: this.googlePlace.geometry.location.lat(),
        lng: this.googlePlace.geometry.location.lng()
      };
      this.formData.latitude = this.googlePlace.geometry.location.lat();
      this.formData.longitude = this.googlePlace.geometry.location.lng();
      this.googlePlace = null;
      return location;
    } else if (this.formData.latitude && this.formData.longitude) {
      let location = {
        lat: parseFloat(this.formData.latitude),
        lng: parseFloat(this.formData.longitude)
      };
      return location;
    }

    return null
  },
}

const data = {
  loading: false,
  submit_loading: false,
  errors: {},
  googlePlace: null,
  formData: {
    name: null,
    phone: null,
    address: null,
    port_type: "airport",
    number_of_rooms: null,
    number_of_parkings: null,
    country_id: null,
    state_id: null,
    timezone_id: null,
    checkin_time:null,
    checkout_time:null,
    latitude: null,
    longitude: null,
  },
  times: dateTime.getHalfHours(),
}

export default {
  name: 'new-location',
  components,
  methods,
  computed,
  directives,
  data () {
    return data
  },
  created () {
    this.$store.dispatch('address.getCountries');
    this.$store.dispatch('currencies.getAll');
    this.$store.dispatch('timezones.getAll');
  }
}
