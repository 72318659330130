var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      attrs: {
        "no-box": true,
        title: _vm.selected_location ? _vm.selected_location.name : ""
      }
    },
    [
      _c("tabs"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "validate-reservation margin-bottom-6x" },
        [_c("validate-reservation")],
        1
      ),
      _vm._v(" "),
      _vm.selected_location
        ? _c("div", { staticClass: "checkin-checkout-list margin-bottom-6x" }, [
            _c(
              "div",
              {
                class: [
                  _vm.errors ? "flex margin-bottom-1x" : "flex margin-bottom-5x"
                ]
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "margin-top-1x",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.prevDay.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-chevron-left",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-3" },
                  [
                    _c("datepicker", {
                      staticClass: "form-control",
                      attrs: { placeholder: "MM/DD/YYYY", "min-date": "none" },
                      model: {
                        value: _vm.selected_date,
                        callback: function($$v) {
                          _vm.selected_date = $$v
                        },
                        expression: "selected_date"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "margin-top-1x",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.nextDay.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-chevron-right",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default margin-left-4x",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.goBackToToday.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n        Today\n      ")]
                )
              ]
            ),
            _vm._v(" "),
            _vm.errors
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-danger flex margin-bottom-5x margin-left-5x"
                  },
                  [_vm._v("\n      " + _vm._s(_vm.errors) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.selected_location.id,
                        permits: ["reservations_list"]
                      },
                      expression:
                        "{id: selected_location.id, permits: ['reservations_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "col-lg-6"
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("checkin", {
                          attrs: {
                            location: _vm.selected_location,
                            date: _vm.selected_date
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.selected_location.id,
                        permits: ["reservations_list"]
                      },
                      expression:
                        "{id: selected_location.id, permits: ['reservations_list']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "col-lg-6"
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("checkout", {
                          attrs: {
                            location: _vm.selected_location,
                            date: _vm.selected_date
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60b28ecc", { render: render, staticRenderFns: staticRenderFns })
  }
}