var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer-content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("hr", { staticClass: "v-separator-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-text" }, [
        _c("div", { staticClass: "text" }, [
          _c(
            "p",
            { staticClass: "blog" },
            [
              _c(
                "router-link",
                {
                  staticClass: "footer-link",
                  attrs: { to: { name: "login" } }
                },
                [_vm._v("Log In")]
              ),
              _c("br")
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _c("p", { staticClass: "blog" }),
          _c(
            "div",
            {
              staticClass: "blog footer-contact footer-link",
              on: {
                click: function($event) {
                  return _vm.addscheduleDemoInitiateDataLayer("Contact us")
                }
              }
            },
            [_vm._v("Contact Us")]
          ),
          _c("br"),
          _vm._v(" "),
          _c("p")
        ])
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "h-separator-line" }),
      _vm._v(" "),
      _c("hr", { staticClass: "v-separator-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "social" }, [
        _c("p", { staticClass: "connect" }, [
          _vm._v("\n                Stay Connected\n            ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "social-logo" }, [
          _c(
            "a",
            {
              staticStyle: { height: "40px" },
              attrs: {
                href: "https://www.linkedin.com/company/reservations-lab/",
                target: "_blank",
                rel: "noopener noreferrer"
              },
              on: {
                click: function($event) {
                  return _vm.addLeadTypeDataLayer()
                }
              }
            },
            [
              _c("img", {
                staticClass: "social-icon",
                attrs: { src: "/assets/linkedin-64.png", alt: "LinkedIn Logo" }
              })
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer-bottom" }, [
      _c(
        "div",
        { staticClass: "footer-bottom-left" },
        [
          _vm._v("\n            Copyright © "),
          _c(
            "router-link",
            {
              staticClass: "footer-link",
              attrs: { to: { name: "property-manager" } },
              nativeOn: {
                click: function($event) {
                  return _vm.scrollToTop.apply(null, arguments)
                }
              }
            },
            [
              _c("span", { staticClass: "underline" }, [
                _vm._v("Reservations Lab")
              ])
            ]
          ),
          _vm._v(
            " " + _vm._s(_vm.currentYear) + ". All rights reserved.\n        "
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-logo" }, [
      _c("img", {
        staticClass: "img-logo img-mobile",
        attrs: { src: "/assets/logo.png", alt: "Website Logo" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-logo img-tablet",
        attrs: { src: "/assets/footer-Logo.png", alt: "Website Logo" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1e565fc4", { render: render, staticRenderFns: staticRenderFns })
  }
}