var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row margin-top-4x" },
    [
      _c("div", { staticClass: "col-sm-12" }, [
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
            ])
          : _vm.reservations && _vm.reservations.data
          ? _c(
              "div",
              [
                _c("reservation-details", {
                  ref: "reservationDetailsModal",
                  attrs: { reservation: _vm.activeReservation, type: "checkin" }
                }),
                _vm._v(" "),
                _c("form", { attrs: { autocomplete: "off" } }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("label", [
                        _vm._v(
                          "\n              Search by Res #\n              "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.search,
                              expression: "filters.search"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.filters.search },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "search",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-2" },
                      [
                        _c("label", { staticClass: "no-margin" }, [
                          _vm._v(
                            "\n              Filter by location\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("s-select", {
                          staticClass: "locations-list",
                          attrs: {
                            options: _vm.locations,
                            selected: _vm.selected_location
                          },
                          on: { selectedChanged: _vm.onChangeLocation }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "radio" }, [
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.type,
                                expression: "filters.type"
                              }
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: null,
                              checked: _vm._q(_vm.filters.type, null)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "type", null)
                              }
                            }
                          }),
                          _vm._v("\n                All\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.type,
                                expression: "filters.type"
                              }
                            ],
                            attrs: { type: "radio", value: "parking" },
                            domProps: {
                              checked: _vm._q(_vm.filters.type, "parking")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "type", "parking")
                              }
                            }
                          }),
                          _vm._v("\n                Parking\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.type,
                                expression: "filters.type"
                              }
                            ],
                            attrs: { type: "radio", value: "room" },
                            domProps: {
                              checked: _vm._q(_vm.filters.type, "room")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "type", "room")
                              }
                            }
                          }),
                          _vm._v("\n                Room\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.type,
                                expression: "filters.type"
                              }
                            ],
                            attrs: { type: "radio", value: "both" },
                            domProps: {
                              checked: _vm._q(_vm.filters.type, "both")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "type", "both")
                              }
                            }
                          }),
                          _vm._v("\n                Both\n              ")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "radio" }, [
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.cancelled,
                                expression: "filters.cancelled"
                              }
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: null,
                              checked: _vm._q(_vm.filters.cancelled, null)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "cancelled", null)
                              }
                            }
                          }),
                          _vm._v("\n                All\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.cancelled,
                                expression: "filters.cancelled"
                              }
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.filters.cancelled, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "cancelled", 0)
                              }
                            }
                          }),
                          _vm._v("\n                Active\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "margin-right-1x" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.cancelled,
                                expression: "filters.cancelled"
                              }
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.filters.cancelled, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.filters, "cancelled", 1)
                              }
                            }
                          }),
                          _vm._v("\n                Cancelled\n              ")
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("datepicker", {
                            staticClass: "form-control",
                            attrs: { "min-date": "none" },
                            model: {
                              value: _vm.filters.from_date,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "from_date", $$v)
                              },
                              expression: "filters.from_date"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("datepicker", {
                            staticClass: "form-control",
                            attrs: {
                              "min-date": _vm.filters.from_date || "none"
                            },
                            model: {
                              value: _vm.filters.to_date,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "to_date", $$v)
                              },
                              expression: "filters.to_date"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.applyFilters(1)
                            }
                          }
                        },
                        [_vm._v("Apply")]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-striped" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Res #")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Creation Date")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Status")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Location Name")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Reserved By")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Info")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Location Fees")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Taxes Total")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Subtotal")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Grand Total")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Due at Lot")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Location Commission")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Channel Commission")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("RL fee")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Amount Owed")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Partial Refund")]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: [
                                  "update_reservation_dates",
                                  "update_reservation"
                                ],
                                expression:
                                  "['update_reservation_dates', 'update_reservation']",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Edit")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.reservations.data, function(item) {
                        return _c(
                          "tr",
                          { class: { "bg-warning": item.no_show } },
                          [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.loadReservation(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.reservation_number) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.loadings[item.id]
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spin fa-spinner"
                                    })
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.created_at,
                                    "MM/DD/YYYY hh:mm A"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? item.cancelled
                                      ? "Cancelled"
                                      : "Active"
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? item.active_history.location.name
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.reserved_by))]),
                            _vm._v(" "),
                            item.active_history
                              ? _c(
                                  "td",
                                  _vm._l(item.active_history.dates, function(
                                    date
                                  ) {
                                    return item.active_history
                                      ? _c(
                                          "table",
                                          {
                                            staticClass: "table",
                                            class: {
                                              "bg-warning": item.no_show
                                            }
                                          },
                                          [
                                            _c("tr", [
                                              _c("th", [_vm._v("Type")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(_vm._s(date.type_type))
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("tr", [
                                              _c("th", [_vm._v("From Date")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      date.from_date,
                                                      "MM/DD/YYYY hh:mm A"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("tr", [
                                              _c("th", [_vm._v("To Date")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      date.to_date,
                                                      "MM/DD/YYYY hh:mm A"
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.openFeesBreakDown(
                                      item.channel_id,
                                      item.id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      item.active_history
                                        ? _vm.currencyCode(
                                            item.active_history.location
                                          ) +
                                            item.active_history
                                              .location_fees_total
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) +
                                        Number(
                                          item.active_history.total_tax
                                        ).toFixed(2)
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) + item.active_history.subtotal
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) + item.active_history.grand_total
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? item.active_history.due_at_location
                                      ? "Yes"
                                      : "No"
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) + item.active_history.commissions_total
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) +
                                        Number(
                                          item.active_history.channel_total
                                        ).toFixed(2)
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) +
                                        Number(
                                          item.active_history.grand_total -
                                            Number(
                                              item.active_history
                                                .channel_total +
                                                item.active_history
                                                  .location_total
                                            ).toFixed(2)
                                        ).toFixed(2)
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) +
                                        Number(
                                          item.active_history.due_at_location
                                            ? item.active_history.grand_total -
                                                Number(
                                                  item.active_history
                                                    .channel_total +
                                                    item.active_history
                                                      .location_total
                                                ).toFixed(2)
                                            : item.active_history.grand_total -
                                                item.active_history
                                                  .channel_total
                                        ).toFixed(2)
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.active_history
                                    ? _vm.currencyCode(
                                        item.active_history.location
                                      ) + item.active_history.partial_refund
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: [
                                      "update_reservation_dates",
                                      "update_reservation"
                                    ],
                                    expression:
                                      "['update_reservation_dates', 'update_reservation']",
                                    arg: "allow"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "reservations.edit",
                                        params: { id: item.id }
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-pencil",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("pagination", {
                  attrs: { "pages-number": _vm.reservations.last_page },
                  on: {
                    pageChanged: function(page) {
                      return _vm.applyFilters(page)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "func-modal",
        {
          ref: "feesBreakDown",
          attrs: { title: "Fees Breakdown" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "body" }, [
            !_vm.fees_loading && _vm.fees_breakdown.length
              ? _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Fee Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Amount")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.fees_breakdown, function(fee) {
                      return _c("tr", { key: fee.id }, [
                        _c("td", [_vm._v(_vm._s(fee.name))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(fee.doller_amount))])
                      ])
                    }),
                    0
                  )
                ])
              : !_vm.fees_loading && !_vm.fees_breakdown.length
              ? _c("div", [
                  _vm._v(
                    "\n        No fees charged for this reservation\n      "
                  )
                ])
              : _c("div", { staticClass: "text-center" }, [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
                ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Res type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Active res")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { staticClass: "no-margin" }, [
        _vm._v("\n                Pick up from\n              ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { staticClass: "no-margin" }, [
        _vm._v("\n                Pick up to\n              ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("label", { attrs: { for: "" } })])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9a60cd9a", { render: render, staticRenderFns: staticRenderFns })
  }
}