import dashboardHandler from 'pages/dashboards'
import mainPage from 'pages/main'
import demandChannel from 'pages/demandChannel'
import propertyManager from 'pages/propertyManager'
import PageNotFound from 'pages/notfound'
import Login from 'pages/login'
import contactUsForm from 'pages/contactUsForm'
import resetPassword from 'pages/resetPassword'
import setPassword from 'pages/setPassword'
import Settings from 'pages/settings'
import carTypesIframe from 'pages/carTypesIframe'

export default [].concat(
  require('./locations').default,
  require('./appLogs').default,
  require('./rateManagement').default,
  require('./guestManagement').default,
  require('./bookingConditions').default,
  require('./locationInformation').default,
  require('./locationAdministration').default,
  require('./analytics').default,
  require('./locationProfile').default,
  require('./channels').default,
  require('./roles').default,
  require('./reservations').default,
  require('./users').default,
  require('./vendors').default,
  require('./reports').default,
  require('./audits').default,
  require('./guaranteeCard').default,
  require('./refundRequests').default,
  require('./pointOfInterest').default,
  [
    //User settings
    { path: '/settings', name: 'settings', component: Settings},

    //Main and login
    { path: '/login', name: 'login', component: Login, meta: {public: true}},
    { path: '/reset-password', name: 'resetPassword', component: resetPassword
      , meta: {public: true}},
    { path: '/set-password', name: 'setPassword', component: setPassword
      , meta: {public: true}},
    { path: '/home', name: 'home', component: dashboardHandler },
    { path: '/demand-channel', name: 'demandChannel', component: demandChannel, meta: {public: true}},
    { path: '/property-manager', name: 'propertyManager', component: propertyManager, meta: {public: true}},
    { path: '/contact-us', name: 'contact-us', component: contactUsForm, meta: {public: true}},
    { path: '/', name: 'property-manager', component: propertyManager, meta: {public: true} },
    { path: '*', name: 'notfound', component: PageNotFound
      , meta: {public: true}},
      { path: '/car-types-iframe', name: 'carTypesIframe', component: carTypesIframe, meta: {public: true}},
    ]
);
