var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading
        ? _c("h1", [_c("small", [_vm._v("New location")])])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c("div", { staticClass: "text-right" }, [
            _vm.submit_loading
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { disabled: _vm.submit_loading, type: "button" }
                  },
                  [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                    _vm._v("\n        SAVING...\n      ")
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmitFom.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n        SAVE\n      ")]
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "locationName" } }, [
                    _vm._v("Location Name")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.name,
                        expression: "formData.name",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "locationName",
                      placeholder: "Location Name"
                    },
                    domProps: { value: _vm.formData.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.name
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.name[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "totalRoomsNumber" } }, [
                    _vm._v("Total Rooms")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.formData.number_of_rooms,
                        expression: "formData.number_of_rooms",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "totalRoomsNumber",
                      placeholder: "Number of rooms"
                    },
                    domProps: { value: _vm.formData.number_of_rooms },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "number_of_rooms",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.number_of_rooms
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.number_of_rooms[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "totalParkingsNumber" } }, [
                    _vm._v("Total Parking Spots")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.formData.number_of_parkings,
                        expression: "formData.number_of_parkings",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "totalParkingsNumber",
                      placeholder: "Total number of parking spaces"
                    },
                    domProps: { value: _vm.formData.number_of_parkings },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "number_of_parkings",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.number_of_parkings
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.number_of_parkings[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "locationPhone" } }, [
                    _vm._v("Phone Number")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.phone,
                        expression: "formData.phone",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "locationPhone",
                      placeholder: "Location Phone Number"
                    },
                    domProps: { value: _vm.formData.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "phone",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.phone
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.phone[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Currency")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.currency_id,
                          expression: "formData.currency_id"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "Currency" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "currency_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("Select Currency")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.currencies, function(curr) {
                        return _c("option", { domProps: { value: curr.id } }, [
                          _vm._v(_vm._s(curr.name + " - " + curr.code))
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.errors.currency_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.currency_id[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.country_id,
                          expression: "formData.country_id"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "Country" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "country_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.countries, function(country) {
                      return _c("option", { domProps: { value: country.id } }, [
                        _vm._v(_vm._s(country.name))
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.errors.country_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.country_id[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("State")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.state_id,
                          expression: "formData.state_id"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "Country" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "state_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.states, function(state) {
                      return _c("option", { domProps: { value: state.id } }, [
                        _vm._v(_vm._s(state.name))
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.errors.state_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.state_id[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "timezone" } }, [
                    _vm._v("Timezone")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.timezone_id,
                          expression: "formData.timezone_id"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { id: "timezone" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "timezone_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.timezones, function(timezone) {
                      return _c(
                        "option",
                        { domProps: { value: timezone.id } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(timezone.name) +
                              "\n            "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.errors.timezone_id
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.timezone_id[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.city,
                        expression: "formData.city",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "City name" },
                    domProps: { value: _vm.formData.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "city",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.city
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.city[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Port Type")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group radio" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.port_type,
                            expression: "formData.port_type"
                          }
                        ],
                        attrs: { type: "radio", value: "airport" },
                        domProps: {
                          checked: _vm._q(_vm.formData.port_type, "airport")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "port_type",
                              "airport"
                            )
                          }
                        }
                      }),
                      _vm._v("\n              Airport\n            ")
                    ]),
                    _vm._v("\n             \n            "),
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.port_type,
                            expression: "formData.port_type"
                          }
                        ],
                        attrs: { type: "radio", value: "seaport" },
                        domProps: {
                          checked: _vm._q(_vm.formData.port_type, "seaport")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "port_type",
                              "seaport"
                            )
                          }
                        }
                      }),
                      _vm._v("\n              Seaport\n            ")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.errors.port_type
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.port_type[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "checkinTime" } }, [
                    _vm._v("Check-in Time")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.checkin_time,
                          expression: "formData.checkin_time"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { id: "checkinTime" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "checkin_time",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("-- Choose time --")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.times, function(time) {
                        return _c(
                          "option",
                          { domProps: { value: time.value } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(time.label) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.errors.checkin_time
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.checkin_time[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "checkoutTime" } }, [
                    _vm._v("Check-out Time")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.checkout_time,
                          expression: "formData.checkout_time"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { id: "checkoutTime" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formData,
                            "checkout_time",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("-- Choose time --")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.times, function(time) {
                        return _c(
                          "option",
                          { domProps: { value: time.value } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(time.label) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.errors.checkout_time
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.checkout_time[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "locationAddress" } }, [
                    _vm._v("Address")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.address,
                        expression: "formData.address",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "locationAddress",
                      placeholder: "Address"
                    },
                    domProps: { value: _vm.formData.address },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "address",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.address
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.address[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "locationZipCode" } }, [
                    _vm._v("Zip/Postal Code")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.formData.zip_code,
                        expression: "formData.zip_code",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "locationZipCode",
                      placeholder: "Address"
                    },
                    domProps: { value: _vm.formData.zip_code },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formData,
                          "zip_code",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.zip_code
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.zip_code[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "locationLatitude" } }, [
                      _vm._v("Geo Coordinates")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("placesAutocomplete", {
                          staticClass: "form-control margin-bottom-3x",
                          attrs: {
                            type: "text",
                            id: "locationPlace",
                            placeholder: "Search for your city or place"
                          },
                          model: {
                            value: _vm.googlePlace,
                            callback: function($$v) {
                              _vm.googlePlace = $$v
                            },
                            expression: "googlePlace"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("u", [
                      _c("i", [
                        _vm._v(
                          "\n              Search for your place and then click on the map to pin your location\n            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("google-maps-movable-marker", {
                      staticClass: "admin-location-map",
                      attrs: {
                        center: _vm.center,
                        "default-marker": {
                          lat: parseFloat(_vm.formData.latitude),
                          lng: parseFloat(_vm.formData.longitude)
                        }
                      },
                      on: { locationChanged: _vm.onLocationChanged }
                    }),
                    _vm._v(" "),
                    _vm.errors.latitude
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.latitude[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errors.longitude
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.longitude[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6e75a41b", { render: render, staticRenderFns: staticRenderFns })
  }
}