import { permits } from 'services'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import sSelect from 'components/sSelect'
import tabs from '../tabs'
import { GChart } from 'vue-google-charts'
import toastr from 'toastr'

const components = {
  layout,
  datepicker,
  tabs,
  GChart,
  sSelect
}

if (!isServer) {
  let VueApexCharts = require('vue-apexcharts');
  components.apexchart = VueApexCharts;
}

const computed = {
  isChannelUser() {
    return !permits.hasPermit('super_admin') && permits.hasPermit('channel_user');
  },

  location() {
    return _.cloneDeep(this.$store.state.locations.active)
  },

  leaseParkingTypes() {
    let types = _.cloneDeep(this.$store.state.locations.active.leaseParkingTypes);
    types.forEach(type => {
      type.text = type.deleted_at ? '*' + type.name : type.name;
      type.value = type.id
    })
    return types;
  },

  SoftAndHardBlackouts() {
    let blackouts = _.cloneDeep(this.$store.state.analytics.softAndHardBlackouts);
    blackouts.forEach(blackout => {
      blackout.type.is_average_percentage = true;
      blackout.type.name =  (blackout.type.deleted_at ? '*'  : '') + blackout.type.name;
    });
    return blackouts;
  },

  //to trigger displayedSoftAndHardBlackouts when showSoftHardBlackoutsCharts values changes
  showSoftHardBlackoutsCharts(){
    let typesData = _.cloneDeep(this.typesBlackoutsData);
    let showHideArray = [];
    if (typesData != null && typesData != undefined) {
      typesData.forEach(() => {
        let show = {
          showHardBlackouts: true,
          showSoftBlackouts: true
        }
        showHideArray.push(show);
      });
    }
    return showHideArray;
  },

  displayedSoftAndHardBlackouts() {
    let typesData = _.cloneDeep(this.typesBlackoutsData);
    let showSoftHardBlackoutsCharts = this.showSoftHardBlackoutsChartsData;
    let displayedData = [];
    if (typesData != null && typesData != undefined) {
      typesData.forEach((element, typeIndex) => {
        const daysDataArray = Object.keys(element.days_data).map(date => ({
          date,
          ...element.days_data[date]
        }));
        const numberOfLeaseSpots = daysDataArray.filter((day, index, arr) => {
          if (index === 0) return true;
          if (day.number_of_lease_spots !== arr[index - 1].number_of_lease_spots) return true;
          return index === arr.length - 1 || day.number_of_lease_spots !== arr[index + 1].number_of_lease_spots;
        }).map((day, index, arr) => {
          let adjustedDate = new Date(day.date);
          if (index < arr.length - 1 && day.number_of_lease_spots < arr[index + 1].number_of_lease_spots) {
            adjustedDate.setHours(6, 0, 0, 0);
          } else if (index > 0 && day.number_of_lease_spots > arr[index - 1].number_of_lease_spots) {
            adjustedDate.setHours(6, 0, 0, 0);
          } else if (index < arr.length - 1 && day.number_of_lease_spots > arr[index + 1].number_of_lease_spots) {
            adjustedDate.setHours(18, 0, 0, 0);
          } else if (index > 0 && day.number_of_lease_spots < arr[index - 1].number_of_lease_spots) {
            adjustedDate.setHours(18, 0, 0, 0);
          }
          return { x: adjustedDate, y: day.number_of_lease_spots };
        });

        const numberOfOccupiedSpots = daysDataArray.filter((day, index, arr) => {
          if (index === 0) return true;
          if (day.number_of_occupied_spots !== arr[index - 1].number_of_occupied_spots) return true;
          return index === arr.length - 1 || day.number_of_occupied_spots !== arr[index + 1].number_of_occupied_spots;
        }).map((day, index, arr) => {
          let adjustedDate = new Date(day.date);
          if (index < arr.length - 1 && day.number_of_occupied_spots < arr[index + 1].number_of_occupied_spots) {
            adjustedDate.setHours(6, 0, 0, 0);
          } else if (index > 0 && day.number_of_occupied_spots > arr[index - 1].number_of_occupied_spots) {
            adjustedDate.setHours(6, 0, 0, 0);
          } else if (index < arr.length - 1 && day.number_of_occupied_spots > arr[index + 1].number_of_occupied_spots) {
            adjustedDate.setHours(18, 0, 0, 0);
          } else if (index > 0 && day.number_of_occupied_spots < arr[index - 1].number_of_occupied_spots) {
            adjustedDate.setHours(18, 0, 0, 0);
          }
          return { x: adjustedDate, y: day.number_of_occupied_spots };
        });

        let typeData = {
          seriesData : [
            {
              name: 'Number of lease spots',
              type: 'line',
              data: numberOfLeaseSpots,
            },
            {
              name: 'Number of occupied spots',
              type: 'line',
              data: numberOfOccupiedSpots,
            },
          ],

          chartOptions : {
            chart: {
              height: 350,
              type: 'line',
              stacked: false,
            },
            stroke: {
              width: [2, 2],
              curve: 'straight',
            },
            fill: {
              opacity: [1, 1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
              },
            },
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              title: {
                text: 'Number of spots',
              },
              min: 0,
            },
            markers: {
              size: 5,
              hover: {
                size: 8,
              },
            },
            tooltip: {
              shared: false,
              followCursor: true,
              x: {
                show: true,
              },
              y: {
                formatter: function (y) {
                  if (typeof y !== 'undefined') {
                    return y.toFixed(0) + ' spots';
                  }
                  return y;
                },
              },
            },
            colors: ['#8fce00', '#2986cc'], // Customize colors for each series
          },
        }
        for (let i = 0; i < element.grouped_blackout_periods.length; i++) {
          let availableNumber =  element.grouped_blackout_periods[i].available_num === 0 ? 0.3 : element.grouped_blackout_periods[i].available_num;
          //hard days
          if (showSoftHardBlackoutsCharts[typeIndex].showHardBlackouts) {
            let hardSeriesData = [
              {
                x: new Date(moment(element.grouped_blackout_periods[i].from, ['YYYY-MM-DD']).format('YYYY-MM-DD')),
                y: availableNumber
              },
              {
                x: element.grouped_blackout_periods[i].to ? new Date(moment(element.grouped_blackout_periods[i].to, ['YYYY-MM-DD']).format('YYYY-MM-DD') + " 23:59:59") : new Date(moment().add(20, 'years').format('YYYY-MM-DD')),
                y: availableNumber
              },
            ];
            if (element.grouped_blackout_periods[i].is_hard) {
              let serh = {
                name: `Hard Blackout (${element.grouped_blackout_periods[i].available_num} available)`,
                data: hardSeriesData,
                type: 'area',
              };
              typeData.seriesData.push(serh);
              typeData.chartOptions.colors.push('#FF0000');
              typeData.chartOptions.stroke.width.push(2);
            } else {
              let serh = {
                name: `Soft for deleted Blackout`,
                data: hardSeriesData,
                type: 'area',
              };
              typeData.seriesData.push(serh);
              typeData.chartOptions.colors.push('#ffd966');
              typeData.chartOptions.stroke.width.push(2);
            }
          }
          //soft days before
          if (element.grouped_blackout_periods[i].soft_days_before > 0 && element.grouped_blackout_periods[i].is_hard && showSoftHardBlackoutsCharts[typeIndex].showSoftBlackouts) {
            let limitBefore = moment(element.grouped_blackout_periods[i].from, ['YYYY-MM-DD']).subtract(element.grouped_blackout_periods[i].soft_days_before, 'days')
            // if number_of_lease_spots at limitBefore = 0 try to add day 
            while(limitBefore < moment(element.grouped_blackout_periods[i].from, ['YYYY-MM-DD'])) {
              if (!element.days_data[limitBefore.format('YYYY-MM-DD')] || element.days_data[limitBefore.format('YYYY-MM-DD')].number_of_lease_spots < 1) {
                limitBefore = limitBefore.add(1, 'days');
              }
              else {
                break;
              }
            }
            let softSeriesData = [
              {
                x: new Date(limitBefore.format('YYYY-MM-DD')),
                y: element.days_data[limitBefore.format('YYYY-MM-DD')].number_of_lease_spots
              },
              {
                x: new Date(moment(element.grouped_blackout_periods[i].from, ['YYYY-MM-DD']).format('YYYY-MM-DD')),
                y: availableNumber
              },
            ];
            let sers = {
              name: `soft Blackouts`,
              data: softSeriesData,
              type: 'area',
            };
            typeData.seriesData.push(sers);
            typeData.chartOptions.colors.push('#ffd966');
            typeData.chartOptions.stroke.width.push(2);
          }
          //soft days after
          if (element.grouped_blackout_periods[i].soft_days_after > 0 && element.grouped_blackout_periods[i].is_hard && showSoftHardBlackoutsCharts[typeIndex].showSoftBlackouts) {
            let limitAfter = moment(element.grouped_blackout_periods[i].to, ['YYYY-MM-DD']).add(element.grouped_blackout_periods[i].soft_days_after, 'days')
            // if number_of_lease_spots at limitBefore = 0 try to add day 
            while(limitAfter > moment(element.grouped_blackout_periods[i].to, ['YYYY-MM-DD'])) {
              if (!element.days_data[limitAfter.format('YYYY-MM-DD')] || element.days_data[limitAfter.format('YYYY-MM-DD')].number_of_lease_spots < 1) {
                limitAfter = limitAfter.subtract(1, 'days');
              }
              else {
                break;
              }
            }
            let softSeriesData = [
              {
                x: new Date(moment(element.grouped_blackout_periods[i].to, ['YYYY-MM-DD']).format('YYYY-MM-DD') + " 23:59:59"),
                y: availableNumber
              },
              {
                x: new Date(limitAfter.format('YYYY-MM-DD') + " 23:59:59"),
                y: element.days_data[limitAfter.format('YYYY-MM-DD')].number_of_lease_spots
              },
            ];
            let sers = {
              name: `soft Blackouts`,
              data: softSeriesData,
              type: 'area',
            };
            typeData.seriesData.push(sers);
            typeData.chartOptions.colors.push('#ffd966');
            typeData.chartOptions.stroke.width.push(2);
          }
        }
        displayedData.push(typeData);
      });
    }
    return displayedData;
  },

  softAndHardBlackoutsCalculations() {
    let calculations = _.cloneDeep(this.$store.state.analytics.softAndHardBlackoutsCalculations);
    if (calculations != null && calculations != undefined) {
      calculations.forEach(typeData => {
        let typeTotals = 0;
        let daysArray = Object.values(typeData.days);
        daysArray.forEach(day => {
          typeTotals += day.lost_amount;
        });
        typeData.total_loss = typeTotals
      });
    }
    return calculations;
  },
}

const watch = {
  ['form.from_date'] (newVal,oldVal) {
    if(newVal && oldVal && this.moment(newVal, 'MM/DD/YYYY', true).year() !== this.moment(oldVal, 'MM/DD/YYYY', true).year()){
        this.getLocationLeaseTypes();
    }
  },

  ['form.to_date'] (newVal,oldVal) {
    if(newVal && oldVal && this.moment(newVal, 'MM/DD/YYYY', true).year() !== this.moment(oldVal, 'MM/DD/YYYY', true).year()){
        this.getLocationLeaseTypes();
    }
  },

  SoftAndHardBlackouts: {
    handler(newVal,oldVal) {
      if(newVal && newVal.length > 0){
        this.typesBlackoutsData = _.cloneDeep(newVal);
      }
    },
    deep: true,
    immediate: true
  },

  showSoftHardBlackoutsCharts: {
    handler(newVal) {
      if(newVal && newVal.length > 0){
        this.showSoftHardBlackoutsChartsData = _.cloneDeep(newVal);
      }
    },
    deep: true,
    immediate: true

  },

  typesBlackoutsData: {
    handler(newVal, oldVal) {
      if (newVal && oldVal && newVal.length > 0 && oldVal.length > 0) {
        newVal.forEach((type, typeIndex) => {
          type.grouped_blackout_periods.forEach((period, periodIndex) => {
            if (periodIndex != 0 && parseInt(period.soft_days_before) + parseInt(type.grouped_blackout_periods[periodIndex - 1].soft_days_after) > parseInt(period.interval_before)) {
              toastr.error(`Type ${type.type.name} soft days before of period ${periodIndex + 1} and soft days after of period ${periodIndex} must be less or equal interval between them`);
              if (period.available_num <= type.grouped_blackout_periods[periodIndex - 1].available_num) {
                let newSoftDaysAfter = period.interval_before - period.soft_days_before
                type.grouped_blackout_periods[periodIndex - 1].soft_days_after = newSoftDaysAfter < 0 ? 0 : newSoftDaysAfter;
                period.soft_days_before = period.interval_before - type.grouped_blackout_periods[periodIndex - 1].soft_days_after;
              }
              else {
                let newSoftDaysBefore = period.interval_before - type.grouped_blackout_periods[periodIndex - 1].soft_days_after;
                period.soft_days_before = newSoftDaysBefore < 0 ? 0 : newSoftDaysBefore;
                type.grouped_blackout_periods[periodIndex - 1].soft_days_after = period.interval_before - period.soft_days_before;
              }
            }
            if (period.soft_days_before < 0) {
              period.soft_days_before = 0;
            } 
            if (period.soft_days_after < 0) {
              period.soft_days_after = 0;
            }
          })
        })
      }
    },
    deep: true,
    immediate: true
  }
}

const methods = {
  onChangeSelectedTypes (selectedTypes) {
    this.selectedTypes = selectedTypes;
  },

  parseDates(data) {
    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }
    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    return data;
  },

  setTab(tab) {
    this.selectedTab = tab;
  },

  getSoftHardBlackouts() {
    this.errors = {};
    if (this.selectedTypes.length < 1) {
      toastr.error("You must choose type first");
      this.softAndHardBlackoutsLoading = null;
    }
    else if (!this.form.from_date) {
      this.errors.from_date = "From date is empty";
      this.softAndHardBlackoutsLoading = null;
    }
    else if (!this.form.to_date || this.moment(this.form.from_date, 'MM/DD/YYYY', true).isAfter(this.moment(this.form.to_date, 'MM/DD/YYYY', true))) {
      this.errors.to_date = "To date is less than from date";
      this.softAndHardBlackoutsLoading = null;
    }
    else if (!this.form.soft_days_before || this.form.soft_days_before < 0) {
      this.errors.soft_days_before = "Must be at least 0";
      this.softAndHardBlackoutsLoading = null;
    }
    else if (!this.form.soft_days_after || this.form.soft_days_after < 0) {
      this.errors.soft_days_after = "Must be at least 0";
      this.softAndHardBlackoutsLoading = null;
    }
    else {
      this.softAndHardBlackoutsLoading = true;
      this.calculationsLoading = null;
      let data = _.cloneDeep(this.form);
      data = this.parseDates(data);
      data.type_ids = this.selectedTypes.map(type => type.id);
      this.$store.dispatch('analytics.softAndHardBlackouts', {
        id: this.$route.params.id,
        params: data
      }).then(() => {
        if (this.$store.state.analytics.errors) {
          this.softAndHardBlackoutsLoading = null;
          this.errors = _.cloneDeep(this.$store.state.analytics.errors.errors);
          toastr.error(this.$store.state.analytics.errors.message);
        }
        else {
          this.softAndHardBlackoutsLoading = false;
        }
      })
    }
  },
  calculate() {
    this.calculationsLoading = true;
    let data = _.cloneDeep(this.typesBlackoutsData);
    this.$store.dispatch('analytics.calculateSoftAndHardBlackouts', {
      id: this.$route.params.id,
      data: data
    }).then(() => {
      if (this.$store.state.analytics.errors) {
        this.calculationsLoading = null;
        toastr.error(this.$store.state.analytics.errors.errors.soft_days);
      }
      else {
        this.calculationsLoading = false;
      }
    })

  },
  getLocationLeaseTypes() {
    if(this.form.to_date && this.form.from_date && this.moment(this.form.to_date, 'MM/DD/YYYY', true).isAfter(this.moment(this.form.from_date, 'MM/DD/YYYY', true)) ){
      this.typesLoading = true;
      this.$store.dispatch('locations.leaseParkingTypes', {
        id: this.$route.params.id,
        from: this.form.from_date,
        to: this.form.to_date,
      }).then(() => {
        this.typesLoading = false;
      });
    }
  },
}

export default {
  name: 'occupancy',
  components,
  computed,
  watch,
  methods,
  data() {
    return {
      moment,
      numberOfDays: 0,
      calculationsLoading: null,
      softAndHardBlackoutsLoading: null,
      typesLoading: true,
      errors: {},
      form: {
        from_date: moment().startOf('month').format('MM/DD/YYYY'),
        to_date: moment().endOf('month').format('MM/DD/YYYY'),
        soft_days_before: 10,
        soft_days_after: 10,
        type_ids: [],
      },
      selectedTypes: [],
      typesBlackoutsData: [],
      showSoftHardBlackoutsChartsData: []
    }
  },
  mounted() {
    let params = {
      with_lease_flag: true
    }
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id, params: params}),
    ]).then(() => {
      this.getLocationLeaseTypes();
    });
  }
}
