import toastr from 'toastr'
import { confirm } from 'components/modals'
import sSelect from 'components/sSelect'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  confirm,
  sSelect
}

const computed = {
  parkingTypes () {
    let parkingTypes = _.cloneDeep(this.$store.state.locations.parkingTypesWithStructure);
    let filteredParkingTypes = parkingTypes.filter(parkingType => parkingType.carDimension === null && (parkingType.CarBodyTypes && parkingType.CarBodyTypes.length === 0));
    return filteredParkingTypes;
  },

  parkingTypesWithCarTypes () {
    let parkingTypes = _.cloneDeep(this.$store.state.locations.parkingTypesWithStructure);
    let filteredParkingTypes = parkingTypes.filter(parkingType => parkingType.carDimension !== null || (parkingType.CarBodyTypes && parkingType.CarBodyTypes.length > 0));
    return filteredParkingTypes;
  },

  carBodyTypes () {
    let carTypes = _.cloneDeep(this.$store.state.carTypesIframe.carBodyTypes);
    carTypes.forEach(type => {
      type.text  = type.name + '  ( L : ' + type.length + '″,   W : ' + type.width + '″,   H : ' + type.height + '″ )';
      type.value   = type.id;
    })
    return carTypes;
  }
}

const methods = {
  onChangeSelectedTypes (selectedTypes) {
    this.data.car_types_id = selectedTypes;
  },
  createCarTypesStructure() {
    this.editActive = false;
    this.formActive = !this.formActive;
    this.clearData();
  },
  editCarTypesStructure (index,id) {
    this.clearData();
    this.$set(this.editLoadings, id, true);
    let parkingType = this.parkingTypesWithCarTypes[index];
    if(parkingType){
      let carTypes = _.map(parkingType.CarBodyTypes, type => {
        if(type) {
          return {
            id: type.id,
            name: type.name,
            value: type.id,
            text: type.name,
          }
        }
      });
      this.data = {
        index: index,
        type_id: parkingType.id,
        is_body_types: (parkingType.CarBodyTypes && parkingType.CarBodyTypes.length > 0),
        car_types_id: (parkingType.CarBodyTypes && parkingType.CarBodyTypes.length > 0) ? carTypes : [],
        is_dimensions: parkingType.carDimension !== null,
        car_length: {
          value: parkingType.carDimension !== null ? parkingType.carDimension.length : null,
          unit: parkingType.carDimension !== null ? parkingType.carDimension.length_unit : 'Inch'
        },
        car_width: {
          value: parkingType.carDimension !== null ? parkingType.carDimension.width : null,
          unit: parkingType.carDimension !== null ? parkingType.carDimension.width_unit : 'Inch'
        },
        car_height: {
          value: parkingType.carDimension !== null ? parkingType.carDimension.height : null,
          unit: parkingType.carDimension !== null ? parkingType.carDimension.height_unit : 'Inch'
        }
      };
      this.formActive = false;
      this.editActive = true;
    }
    this.$set(this.editLoadings, id, false);
  },
  
  cancelEdit() {
    this.editActive = false;
    this.clearData();
  },

  removeCarTypesStructure (id) {
    if(id === this.data.type_id){
      this.editActive = false;  
      this.clearData();
    }
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.deleteLoadings, id, true);

      this.$store.dispatch('locations.removeCarTypeStructure', {
        locId: this.$route.params.id,
        id
      }).then((res) => {
        this.$set(this.deleteLoadings, id, false);
        let errors;
        if (errors = this.$store.state.locations.errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('data has been removed successfully');
        }
      });
    })
  },

  validateData () {
    if (!this.data.is_body_types && !this.data.is_dimensions) {
      toastr.error("You must choose a body type or dimension");
      return false;
    }
    else if (!this.data.type_id) {
      this.errors.type_id = [];
      this.errors.type_id.push('You must choose parking type')
      return false;
    }
    else if (this.data.is_body_types && this.data.car_types_id.length < 1) {
      this.errors.car_types_id = [];
      this.errors.car_types_id.push('You must choose body type')
      return false;
    }
    else if (this.data.is_dimensions && (isNaN(Number(this.data.car_length.value)) || isNaN(Number(this.data.car_width.value)) || isNaN(Number(this.data.car_height.value)))) {
      if (isNaN(Number(this.data.car_length.value))) {
        this.errors.car_length = [];
        this.errors.car_length.push('Maximum length must be a valid number')
      }
      if (isNaN(Number(this.data.car_width.value))) {
        this.errors.car_width = [];
        this.errors.car_width.push('Maximum width must be a valid number')
      }
      if (isNaN(Number(this.data.car_height.value))) {
        this.errors.car_height = [];
        this.errors.car_height.push('Maximum height must be a valid number')
      }
      return false;
    } else {
      return true;
    }
  },

  submitEdit (id) {
    this.errors = {};
    if (this.validateData()){
      this.processing = true;
      let data = _.cloneDeep(this.data);
      data.car_types_id  = data.car_types_id.map(item => item.id);
      this.$store.dispatch('locations.updateCarTypeStructure', {
        locId: this.$route.params.id,
        id: id,
        data: data
      })
      .then(() => {
        this.processing = false;
        let errors;
        if (errors = this.$store.state.locations.errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }

          toastr.error(errors.message);
        } else {
          toastr.success('Data has been saved');
          this.editActive = false; 
          this.clearData();
        }
      });
    }
  },

  submit () {
    this.errors = {};
    if (this.validateData()){
      this.processing = true;
      let data = _.cloneDeep(this.data);
      data.car_types_id  = data.car_types_id.map(item => item.id);
      this.$store.dispatch('locations.addCarTypeStructure', {
        locId: this.$route.params.id,
        data: data
      })
      .then(() => {
        this.processing = false;
        let errors;
        if (errors = this.$store.state.locations.errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }

          toastr.error(errors.message);
        } else {
          toastr.success('Data has been saved');
          this.clearData();
          
        }
      });
    }
    
  },

  clearData(){
    this.data = {
      index: null,
      type_id: null,
      is_body_types: true,
      car_types_id: [],
      is_dimensions: false,
      car_length: {
        value: null,
        unit: 'Inch'
      },
      car_width: {
        value: null,
        unit: 'Inch'
      },
      car_height: {
        value: null,
        unit: 'Inch'
      },
    }
  }
}

export default {
  name: "parking-types-car-structure",
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      errors: {},
      processing: false,
      deleteLoadings: {},
      editLoadings: {},
      formActive: false,
      editActive: false,
      data: {
          type_id: null,
          is_body_types: true,
          car_types_id: [],
          is_dimensions: false,
          car_length: {
            value: null,
            unit: 'Inch'
          },
          car_width: {
            value: null,
            unit: 'Inch'
          },
          car_height: {
            value: null,
            unit: 'Inch'
          },
        },
      units: [
        'Inch',
        'Feet',
        'm',
        'cm'
      ]
      
    }
  }
}
