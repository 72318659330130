var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logo-slider-container" }, [
    _c("div", { staticClass: "logo-slider-wrapper" }, [
      _c(
        "div",
        { staticClass: "logo-slider" },
        [
          _vm._l(_vm.logos, function(logo, index) {
            return _c("div", { key: index, staticClass: "logo-slide" }, [
              _c("img", {
                staticClass: "logo-img",
                attrs: { src: logo.src, alt: logo.alt }
              }),
              _vm._v(" "),
              index < _vm.logos.length
                ? _c("div", { staticClass: "separator" })
                : _vm._e()
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.logos, function(logo, index) {
            return _c(
              "div",
              { key: "duplicate-" + index, staticClass: "logo-slide" },
              [
                _c("img", {
                  staticClass: "logo-img",
                  attrs: { src: logo.src, alt: logo.alt }
                }),
                _vm._v(" "),
                index < _vm.logos.length - 1
                  ? _c("div", { staticClass: "separator" })
                  : _vm._e()
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b28c2002", { render: render, staticRenderFns: staticRenderFns })
  }
}