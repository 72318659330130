import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import lineCalendar from './lineCalendar'
import calendar from './calendar'
import list from './list'
import { hasPermits } from 'directives'
import { permits } from 'services'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  lineCalendar,
  calendar,
  list
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active)
  },
  roomTypes () {
    return _.cloneDeep(this.$store.state.locations.active.roomTypes)
  },
  parkingTypes () {
    return _.cloneDeep(this.$store.state.locations.active.parkingTypes)
  },
  bundleTypes () {
    return _.cloneDeep(this.$store.state.locations.active.bundleTypes)
  },
  errors () {
    let errors = _.cloneDeep(this.$store.state.locations.errors);
    return errors && errors.errors && errors.errors.from_date && errors.errors.from_date[0] ? 'This date is not valid' : '';
  }
}

const watch = {
  ['form.from_date'] () {
    let from_date_unformated = moment(this.form.from_date, ['MM/DD/YYYY']);
    let to_date_unformated = moment(this.form.from_date, ['MM/DD/YYYY']).add(this.number_of_days, 'days');
    this.form.to_date = to_date_unformated.format('MM/DD/YYYY');
    if (this.view_type == 'line_calendar') {
      this.getRates();
    }
    if (this.view_type == 'calendar') {
      this.changeCalenderStartDate(from_date_unformated.startOf('month'));
    }
  },
  view_type (n) {
    this.getRates();
  },
  ['location.channels']: {
    handler (n) {
      console.log('ccc', n);
      if (!n) return;

      if (n.length && !this.form.channel) {
        this.form.channel = n[0].channel.id

        if (this.form.type) {
          this.getRates();
        }
      }
    },
    deep: true
  }
}

const methods = {
  changeType() {
    sessionStorage.setItem(`__calendar_type_${this.location.id}__`, this.form.calendar_type);
    this.getRates();
  },

  changeViewType(viewType) {
    this.view_type = viewType;
    sessionStorage.setItem('__view_type__', viewType);
  },

  changeChannel() {
    sessionStorage.setItem(`__channel_${this.location.id}__`, this.form.channel);
    this.getRates();
  },

  getRates () {
    if (!this.form.type || !this.form.channel || !this.form.from_date)
      return;

    this.$store.dispatch('locations.setRateView', {
      channel: this.form.channel,
      type: this.form.type,
      view_type: this.view_type
    });

    this.loading = true;

    if (this.view_type == 'calendar') {
      let data = _.clone(this.form);
      let cstartDate = moment(this.calendarFromDate, ['YYYY-MM-DD']);

      data.from_date = cstartDate.format('YYYY-MM-DD');
      data.to_date = cstartDate.endOf('month').format('YYYY-MM-DD');
      this.$store.dispatch('locations.getCalendarRates', {
        locationId: this.$route.params.id,
        data
      })
      .then(() => {
        this.loading = false;
        // if (this.form.type == 'parking') {
        //   this.rate_types = _.cloneDeep(this.parkingTypes);
        // } else {
        //   this.rate_types = _.cloneDeep(this.roomTypes);
        // }
        //show errors if exist
      })

    } else if (this.view_type == 'line_calendar') {
      let data = _.clone(this.form);
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
      this.$store.dispatch('locations.getLineCalendarRates', {
        locationId: this.$route.params.id,
        data
      })
      .then(() => {
        this.loading = false;
      })

    } else if (this.view_type == 'list') {
      let typeIds = [];
      if(this.form.type == 'parking') {
        typeIds = this.parkingTypes.map(type => {return type.id})
      } else if (this.form.type == 'room') {
        typeIds = this.roomTypes.map(type => {return type.id})
      } else {
        typeIds = this.bundleTypes.map(type => {return type.id})
      }
      
      let requests = []
      typeIds.forEach(id => {
        requests.push(this.$store.dispatch('locations.getRatesForListView', {
          type: this.form.type,
          locationId: this.location.id,
          channelId: this.form.channel,
          typeId: id,
      }));
      })
      
      Promise.all(requests).then(() => {
        this.loading = false;
        //show errors if exist
      })
    } else {
      this.loading = false;
      this.showResults = false;
      return;
    }
    if (this.form.type == 'parking') {
      this.rate_types = _.cloneDeep(this.parkingTypes);
    } else if (this.form.type == 'bundle') {
      this.rate_types = _.cloneDeep(this.bundleTypes);
    } else {
      this.rate_types = _.cloneDeep(this.roomTypes);
    }

    this.showResults = true;
  },

  goBackToToday () {
    this.form.from_date = moment().format('MM/DD/YYYY');
  },

  nextMonth (e) {
    e.preventDefault();
    this.form.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).add(this.number_of_days, 'days').format('MM/DD/YYYY');
  },

  changeCalenderStartDate (newDate) {
    let newStartDate = newDate.format('YYYY-MM-DD');
    if (this.calendarFromDate != newStartDate) {
      this.calendarFromDate = newStartDate;
      this.getRates();
    }
  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.form.from_date, ['MM/DD/YYYY']).subtract(this.number_of_days, 'days');
    if (prev.isBefore(moment(), 'days')) {
      return;
    }
    this.form.from_date = prev.format('MM/DD/YYYY');
  }
}

export default {
  name: 'rate-views',
  components,
  computed,
  watch,
  methods,
  directives,
  data () {
    let number_of_days = 30;
    let from_date_unformated = moment();
    let from_date = moment().format('MM/DD/YYYY');
    let to_date_unformated = moment(from_date, ['MM/DD/YYYY']).add(number_of_days, 'days');
    let to_date = to_date_unformated.format('MM/DD/YYYY');

    return {
      loading: false,
      showResults: false,
      locId: this.$route.params.id,
      number_of_days,
      rate_types: [],
      view_type: 'calendar',// line_calendar, list, calendar
      calendarFromDate: moment().startOf('month').format('YYYY-MM-DD'),
      form: {
        from_date,
        to_date,
        type: null,// parking, room, bundle
        channel: null,
        calendar_type: 'all', // has this formmat ({type}_{id}) like, room_15, parking_44, bundle_6 or all
      }
    }
  },
  created () {
    let type = null
    if (permits.hasLocationPermit(this.location.id, 'channelParkingRate_list')) {
      type = 'parking'
    } else if (permits.hasLocationPermit(this.location.id, 'channelRoomRate_list')) {
      type = 'room'
    } else if (permits.hasLocationPermit(this.location.id, 'locationBundleRate_list')) {
      type = 'bundle'
    }
    let channels_ids = [];
    if (this.location && this.location.channels && this.location.channels.length > 0) {
      channels_ids = this.location.channels.map(channel => {
        return channel.channel.id;
      });
    }

    this.form.type = this.location.rate_view.type || type;
    this.form.channel = this.location.rate_view.channel || null;
    if (this.location && channels_ids.length && (!this.form.channel || !channels_ids.includes(this.form.channel))) {
      this.form.channel = this.location.channels[0].channel.id
    }
    this.view_type = this.location.rate_view.view_type || 'calendar';
    if (this.form.type && this.form.channel && (this.view_type == 'line_calendar' || this.view_type == 'calendar')) {
      this.getRates();
    }

  },

  mounted () {
    let view_type_from_storage = sessionStorage.getItem('__view_type__');
    if (_.includes(['list', 'line_calendar', 'calendar'],view_type_from_storage)) {
      this.view_type = view_type_from_storage;
    } else {
      this.view_type = 'calendar';
    }

    let calender_type_from_storage = sessionStorage.getItem(`__calendar_type_${this.location.id}__`);
    let channel_from_storage = sessionStorage.getItem(`__channel_${this.location.id}__`);
    if (channel_from_storage !== undefined && channel_from_storage  !== null) {
      this.form.channel = channel_from_storage;
    }
    if (calender_type_from_storage  !== undefined && calender_type_from_storage  !== null && calender_type_from_storage !== 'all') {
      let ctype = calender_type_from_storage.split('_');
      if (ctype.length > 1) {
        let type_type = ctype[0];
        let type_id = ctype[1];
        let typeTypes = `${type_type}Types`;
        let found = _.find(this[typeTypes], (type) => type.id == type_id );
        if (found) {
          this.form.calendar_type = calender_type_from_storage;
        }
      }
    }
  }
}
