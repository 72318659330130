import { hasPermits } from 'directives'
import { permits } from 'services'
// import './details.scss'

const directives = {
  hasPermits
}

const computed = {
  location() {
    return _.cloneDeep(this.$store.state.locations.active)
  },

  isSuperAdmin () {
    return permits.hasPermit('super_admin');
  },
};

export default {
  name: 'analytics-tabs',
  directives,
  computed,
  data () {
    return {
      locId: this.$route.params.id
    }
  },
}
