import { guestManagement, inHouseReport } from 'pages/locations'

export default [
  //guest management
  { path: '/locations/:id', name: 'locations.edit', /*component: editLocation*/
    redirect: {
      name: 'locations.guestManagement'
    },
    meta:{permits:['location_user', 'location_list']}},
  { path: '/locations/:id/guest-management', name: 'locations.guestManagement', component: guestManagement
    , meta:{permits:['location_user']}},
  { path: '/locations/:id/guest-management/in-house-report', name: 'locations.guestManagement.inHouseReport', component: inHouseReport
    , meta:{permits:['location_user']}},

]
