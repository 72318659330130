import moment from 'moment'
import logoSlider from 'components/logoSlider'
import newfooter from 'components/newfooter'
import newheader from 'components/newheader'
import { user, DataLayer} from 'services'

const components = {
  logoSlider,
  'new-footer':newfooter,
  'new-header':newheader,
}
let computed = {
  user () {
    return user.getUser();
  },
} 
export default {
  name: 'demandChannel',
  components,
  computed,
  data () {
    return {
      currentTab: 'demandChannel',
      year: moment().format('YYYY')
    }
  },
  methods: {
    addscheduleDemoInitiateDataLayer(button_text) {
      if (!isServer) {
          DataLayer.scheduleDemoInitiate(this.user,button_text);
      }
      this.$router.push({ name: 'contact-us', query: { source: 'demandChannel', buttonText: button_text } });
  }
  }
}
