var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demand" },
    [
      _c("new-header", { attrs: { activeTab: _vm.currentTab } }),
      _vm._v(" "),
      _c("div", { staticClass: "body-container" }, [
        _c(
          "div",
          { staticClass: "full-section" },
          [
            _c("div", { staticClass: "intro" }, [
              _c("div", { staticClass: "hero-section" }, [
                _c("div", { staticClass: "hero-text" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-header cyan-bg schedule-demo-btn",
                      on: {
                        click: function($event) {
                          return _vm.addscheduleDemoInitiateDataLayer(
                            "Schedule a demo"
                          )
                        }
                      }
                    },
                    [_vm._v("Schedule a demo")]
                  )
                ]),
                _vm._v(" "),
                _vm._m(2)
              ])
            ]),
            _vm._v(" "),
            _c("logo-slider")
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "main-white-bg" }, [
          _c("div", { staticClass: "revenue-calculator-section" }, [
            _c("div", { staticClass: "info-container" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "v-separator" }),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "v-separator" }),
              _vm._v(" "),
              _c("div", { staticClass: "frame" }, [
                _c("div", { staticClass: "frame-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "frame-number-large",
                      on: {
                        click: function($event) {
                          return _vm.addscheduleDemoInitiateDataLayer(
                            "Supply now"
                          )
                        }
                      }
                    },
                    [_vm._v("Unlock your supply now")]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(5)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-container" }, [
          _c("div", { staticClass: "right-section" }, [
            _c("h2", { staticClass: "contact-header" }, [
              _vm._v("Get in Touch")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "paragraph" }, [
              _vm._v(
                "Our team is ready to show you how easy it is to add parking to your offering. Schedule a quick demo to see our solution in action and discuss your specific needs."
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "action-button",
                on: {
                  click: function($event) {
                    return _vm.addscheduleDemoInitiateDataLayer("Contact us")
                  }
                }
              },
              [_vm._v("Contact us")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("new-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "main-heading" }, [
      _c("span", { staticClass: "maximizing-revenue" }, [
        _vm._v("Power Your Platform"),
        _c("br"),
        _vm._v("with Premium Parking")
      ]),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "intro-paragraph" }, [
      _c("span", { staticClass: "highlight" }, [_vm._v("Reservations Lab")]),
      _vm._v(
        " provides instant access to thousands of verified parking locations through one simple integration. Whether you're an Online Travel Agency adding parking as an ancillary service or a parking platform expanding your inventory, our API and white-label solutions make it seamless to enhance your offering.\n                            Access premium parking spots at airports, hotels, and ports while maintaining your brand experience. Our competitive rates and reliable inventory help you boost revenue while delivering more value to your users.\n                        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-image" }, [
      _c("img", {
        attrs: { src: "/assets/demandHome.png", alt: "Descriptive alt text" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "frame" }, [
      _c("div", { staticClass: "frame-content" }, [
        _c("div", { staticClass: "frame-number" }, [_vm._v("90+")]),
        _vm._v(" "),
        _c("div", { staticClass: "frame-label" }, [_vm._v("airports")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "frame f-300" }, [
      _c("div", { staticClass: "frame-content" }, [
        _c("div", { staticClass: "frame-number" }, [_vm._v("300+")]),
        _vm._v(" "),
        _c("div", { staticClass: "frame-label" }, [_vm._v("Locations")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-features-section" }, [
      _c("div", { staticClass: "key-features-content" }, [
        _c("div", { staticClass: "key-features-text" }, [
          _c("h2", [_vm._v("Key Features")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "features-grid" }, [
          _c("div", { staticClass: "feature-card card-1" }, [
            _c("img", {
              staticClass: "feature-icon",
              attrs: {
                src: "/assets/parking_space.png",
                alt: "Revenue Optimization Icon"
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Extensive Parking Network:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                                Access thousands of verified parking spaces across prime locations, ensuring your customers always find the perfect spot.\n                            "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature-card card-2" }, [
            _c("img", {
              staticClass: "feature-icon",
              attrs: {
                src: "/assets/integration.png",
                alt: "Real-Time Analytics Icon"
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Seamless Integration:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                                Connect to our inventory through simple API integration or white-label solutions, with minimal development effort and quick time-to-market.\n                            "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature-card card-3" }, [
            _c("img", {
              staticClass: "feature-icon",
              attrs: {
                src: "/assets/real_time.png",
                alt: "User-Friendly Interface Icon"
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Real-Time Availability:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                                Deliver instant, accurate parking availability to your customers, ensuring seamless booking experiences and eliminating overselling risks.\n                            "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature-card card-4" }, [
            _c("img", {
              staticClass: "feature-icon",
              attrs: { src: "/assets/pricing.png", alt: "Secure Payments Icon" }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Flexible Pricing:")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                                Access competitive rates and dynamic pricing options to maximize revenue while maintaining market-leading offers for your customers.\n                            "
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-22d153e7", { render: render, staticRenderFns: staticRenderFns })
  }
}