var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "reservation-edit", attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Edit Reservation")])])
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("form", {}, [
            _c(
              "div",
              { staticClass: "text-right btn-groups margin-bottom-2x" },
              [
                _vm.activeHistory
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.activeHistory.location_id,
                              permits: ["reservations_partial_refund"]
                            },
                            expression:
                              "{id: activeHistory.location_id, permits: ['reservations_partial_refund']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "btn btn-default",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openPartialRefundModal.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("\n        Partial Refund\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeHistory && _vm.activeHistory.partial_refund > 0
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "allow"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["channel_user"],
                            expression: "['channel_user']",
                            arg: "prevent"
                          }
                        ],
                        staticClass: "btn btn-default",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openRevertPartialRefundModal.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("\n        Revert Partial Refund\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeHistory &&
                !_vm.reservation.testing &&
                !_vm.partialRefundRequest &&
                _vm.activeHistory.location.allow_refund_request
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["channel_user"],
                            expression: "['channel_user']",
                            arg: "allow"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "prevent"
                          }
                        ],
                        staticClass: "btn btn-default",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openPartialRefundRequestModal.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("\n          Partial Refund Request\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.partialRefundRequest
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["channel_user"],
                            expression: "['channel_user']",
                            arg: "allow"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "prevent"
                          }
                        ],
                        staticClass: "btn btn-default",
                        attrs: {
                          to: {
                            name: "refundRequests.edit",
                            params: { id: _vm.partialRefundRequest.id }
                          }
                        }
                      },
                      [_vm._v("\n        Go to partial refund request\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeHistory &&
                !_vm.reservation.cancelled &&
                (!_vm.isReservationFinished ||
                  (_vm.isReservationFinished && _vm.isAdmin)) &&
                ((!_vm.isReservationStarted(false) &&
                  !_vm.reservation.history_id_when_begin) ||
                  _vm.isAdmin)
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["location_user"],
                            expression: "['location_user']",
                            arg: "prevent"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["channel_user", "reservations_cancel"],
                            expression:
                              "['channel_user', 'reservations_cancel']",
                            arg: "allow"
                          }
                        ],
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.cancelReservation()
                          }
                        }
                      },
                      [_vm._v("\n        Cancel\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeHistory && _vm.reservation.cancelled
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:prevent",
                            value: ["location_user"],
                            expression: "['location_user']",
                            arg: "prevent"
                          },
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["super_admin"],
                            expression: "['super_admin']",
                            arg: "allow"
                          }
                        ],
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.revertCancelReservation.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("\n        Revert Cancel\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.activeHistory.location.id,
                          permits: [
                            "update_reservation_dates",
                            "update_reservation"
                          ]
                        },
                        expression:
                          "{id: activeHistory.location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                        arg: "allow"
                      }
                    ]
                  },
                  [
                    _vm.submit_loading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              disabled: _vm.submit_loading,
                              type: "button"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            }),
                            _vm._v("\n            SAVING...\n        ")
                          ]
                        )
                      : _vm.activeHistory &&
                        (_vm.isReservationStarted(false) ||
                          _vm.reservation.history_id_when_begin) &&
                        _vm.isFromDateChanged() &&
                        _vm.isSuperAdmin
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["super_admin"],
                                expression: "['super_admin']",
                                arg: "allow"
                              }
                            ],
                            staticClass: "btn btn-success",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateReservation()
                              }
                            }
                          },
                          [_vm._v("\n          SAVE\n        ")]
                        )
                      : _c("div", [
                          _vm.isLocationUser
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.confirmUpdate.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("\n            SAVE\n          ")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmitFom.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("\n            SAVE\n          ")]
                              )
                        ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.back()
                      }
                    }
                  },
                  [_vm._v("Back")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-6" }, [
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("p", {}),
                  _c("h4", [_vm._v("Location Information:")]),
                  _vm._v(" "),
                  _c("p")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "col-xs-3" }, [
                          _vm._v("Reservation No.")
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.reservation.reservation_number))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "col-xs-3" }, [
                          _vm._v("Reserved By:")
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.activeHistory.reserved_for) +
                              "\n                  -\n                  " +
                              _vm._s(_vm.activeHistory.email) +
                              "\n                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("Location")]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.activeHistory.location.name))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Reservation Type")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.historyDetails.room_type
                            ? _c("span", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.activeHistory.total_days.room) +
                                    " \n                      night"
                                ),
                                _vm.activeHistory.total_days.room > 1
                                  ? _c("span", [_vm._v("s")])
                                  : _vm._e(),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.historyDetails.room_type) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.historyDetails.room_type &&
                          _vm.historyDetails.parking_type
                            ? _c("span", [_vm._v("&")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.historyDetails.parking_type
                            ? _c("span", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.activeHistory.total_days.parking
                                        .parking_days
                                    ) +
                                    " \n                      day"
                                ),
                                _vm.activeHistory.total_days.parking
                                  .parking_days > 1
                                  ? _c("span", [_vm._v("s")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.activeHistory.total_days.parking
                                  .parking_hours
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.activeHistory.total_days.parking
                                              .parking_hours
                                          ) +
                                          " \n                      hour"
                                      ),
                                      _vm.activeHistory.total_days.parking
                                        .parking_hours > 1
                                        ? _c("span", [_vm._v("s")])
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  "\n                      of " +
                                    _vm._s(_vm.historyDetails.parking_type) +
                                    " Parking\n                    "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Cancelled")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.reservation.cancelled ? "Yes" : "No")
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Creation Date")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.reservation.created_at,
                                "MM/DD/YYYY hh:mm A"
                              )
                            ) +
                              "  " +
                              _vm._s(_vm.timezone_name)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Latest Update")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.activeHistory.created_at,
                                "MM/DD/YYYY hh:mm A"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.historyDetails.parking_type
                        ? _c("tr", [
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.historyDetails.parking_type) +
                                  " Parking\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(_vm.historyDetails.parking_dates, function(
                                date
                              ) {
                                return _c("div", [
                                  _vm._v(
                                    "\n                      Check-in: " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          date.from_date,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                      Check-out: " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          date.to_date,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.historyDetails.room_type
                        ? _c("tr", [
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.historyDetails.room_type) +
                                  " Room\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(_vm.historyDetails.room_dates, function(
                                date
                              ) {
                                return _c("div", [
                                  _vm._v(
                                    "\n                      Check-in: " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          date.from_date,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                      Check-out: " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          date.to_date,
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.historyDetails.room_type
                        ? _c("tr", [
                            _c("th", [_vm._v("Room Guests")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(
                                _vm.historyDetails.number_of_guests,
                                function(n, i) {
                                  return _c("div", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(n) +
                                        " guest"
                                    ),
                                    n > 1
                                      ? _c("span", [_vm._v("s")])
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                      in room " +
                                        _vm._s(i + 1) +
                                        " " +
                                        _vm._s(
                                          _vm.historyDetails.guest_names
                                            ? "- " +
                                                _vm.historyDetails.guest_names[
                                                  i
                                                ]
                                            : ""
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                }
                              ),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.historyDetails.parking_type
                        ? _c("tr", [
                            _c("th", [_vm._v("Number of spots")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.historyDetails.number_of_spots) +
                                  "\n                  "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.historyDetails.room_type
                        ? _c("tr", [
                            _c("th", [_vm._v("Number of rooms")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.historyDetails.number_of_rooms) +
                                  "\n                  "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Customer Details")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.activeHistory.guest_name) +
                              " - \n                    " +
                              _vm._s(_vm.activeHistory.email) +
                              " -\n                    " +
                              _vm._s(_vm.activeHistory.phone) +
                              "\n                  "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-6" }, [
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("p", {}),
                  _c("h4", [_vm._v("Calculations:")]),
                  _vm._v(" "),
                  _c("p")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:prevent",
                                  value: ["location_user"],
                                  expression: "['location_user']",
                                  arg: "prevent"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n                    Subtotal: " +
                                  _vm._s(
                                    _vm.currencyCode(
                                      _vm.activeHistory.location
                                    ) + _vm.activeHistory.subtotal.toFixed(2)
                                  ) +
                                  "\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:prevent",
                                  value: ["location_user"],
                                  expression: "['location_user']",
                                  arg: "prevent"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n                    Fees: " +
                                  _vm._s(
                                    _vm.currencyCode(
                                      _vm.activeHistory.location
                                    ) + _vm.activeHistory.total_fees.toFixed(2)
                                  ) +
                                  "\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:prevent",
                                  value: ["location_user"],
                                  expression: "['location_user']",
                                  arg: "prevent"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n                    Taxes: " +
                                  _vm._s(
                                    _vm.currencyCode(
                                      _vm.activeHistory.location
                                    ) +
                                      _vm.activeHistory.orginal_total_tax.toFixed(
                                        2
                                      )
                                  ) +
                                  "\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.activeHistory.total_discount
                            ? _c("div", [
                                _vm._v(
                                  "\n                    Total Discount: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.activeHistory.total_discount.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activeHistory.partial_refund
                            ? _c("div", [
                                _vm._v(
                                  "\n                    Partial Refund: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.activeHistory.partial_refund.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                    Total: " +
                                _vm._s(
                                  _vm.currencyCode(_vm.activeHistory.location) +
                                    _vm.activeHistory.grand_total.toFixed(2)
                                ) +
                                "\n                    "
                            ),
                            _c(
                              "small",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["location_user"],
                                    expression: "['location_user']",
                                    arg: "allow"
                                  },
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:prevent",
                                    value: ["super_admin"],
                                    expression: "['super_admin']",
                                    arg: "prevent"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                      Does not include channel added fees\n                    "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                    Already Paid: \n                    "
                            ),
                            _vm.activeHistory.grand_total <
                            _vm.activeHistory.due_at_location_total
                              ? _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.currencyCode(
                                          _vm.activeHistory.location
                                        ) +
                                          (
                                            _vm.activeHistory.grand_total -
                                            _vm.activeHistory.location_total
                                          ).toFixed(2)
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.currencyCode(
                                          _vm.activeHistory.location
                                        ) +
                                          (
                                            _vm.activeHistory.grand_total -
                                            _vm.activeHistory
                                              .due_at_location_total
                                          ).toFixed(2)
                                      ) +
                                      "\n                    "
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("b", [
                              _vm._v(
                                "Due at Check-in: " +
                                  _vm._s(
                                    _vm.currencyCode(
                                      _vm.activeHistory.location
                                    ) +
                                      _vm.activeHistory.due_at_location_total.toFixed(
                                        2
                                      )
                                  )
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.historyDetails.room_type &&
                      _vm.reservation.credit_card_count
                        ? _c(
                            "tr",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: {
                                    id: _vm.activeHistory.location_id,
                                    permits: ["guarantee_reservation_card_list"]
                                  },
                                  expression:
                                    "{id: activeHistory.location_id, permits: ['guarantee_reservation_card_list']}",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _c("th"),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", [
                                  _c("b", [_vm._v("Credit card received")])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "margin-bottom-2x" }, [
                                  _vm._v(
                                    "\n                    You can view these credit card details 3 times until " +
                                      _vm._s(
                                        _vm.credit_card_max_view_date.format(
                                          "dddd, MM/DD/YYYY"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openCardModal.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    View credit card details\n                  "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeHistory.location.special_conditions
                        ? _c("tr", [
                            _c("th", [_vm._v("Special Conditions")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("stripTags")(
                                    _vm.activeHistory.location
                                      .special_conditions
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.reservation.channel
                        ? _c("tr", [
                            _c("th", [_vm._v("Seller")]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: "//" + _vm.reservation.channel.domain
                                  }
                                },
                                [_vm._v(_vm._s(_vm.reservation.channel.domain))]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeHistory.extra_fields &&
                      _vm.activeHistory.extra_fields.length
                        ? _c("tr", [
                            _c("th", [_vm._v("Aditional Information")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(_vm.activeHistory.extra_fields, function(
                                extra
                              ) {
                                return _c("div", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(extra.label) +
                                      ": " +
                                      _vm._s(
                                        Array.isArray(extra.value)
                                          ? extra.value.join(", ")
                                          : extra.value
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: {
                      id: _vm.activeHistory.location.id,
                      permits: [
                        "update_reservation_dates",
                        "update_reservation"
                      ]
                    },
                    expression:
                      "{id: activeHistory.location.id, permits: ['update_reservation_dates', 'update_reservation']}",
                    arg: "allow"
                  }
                ]
              },
              [
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("p", {}),
                  _c("h4", [_vm._v("Update Reservation:")]),
                  _vm._v(" "),
                  _c("p")
                ]),
                _vm._v(" "),
                _vm.activeHistory.location.extra_fields &&
                _vm.activeHistory.location.extra_fields.length > 0
                  ? _c("div", { staticClass: "col-xs-4" }, [
                      _c(
                        "div",
                        { staticClass: "margin-left-2x" },
                        [
                          _c("b", [_vm._v("Extra Fields")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm._l(
                            _vm.activeHistory.location.extra_fields,
                            function(item, index) {
                              return _c(
                                "div",
                                { key: "history" + index, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-10 col-sm-offset-1 margin-top-3x"
                                    },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("label", [
                                          _vm._v(_vm._s(item.label))
                                        ]),
                                        _vm._v(" "),
                                        item.per_car &&
                                        item.type != "room" &&
                                        _vm.parkingItem
                                          ? _c(
                                              "div",
                                              { staticClass: "row" },
                                              _vm._l(
                                                parseInt(
                                                  _vm.parkingItem
                                                    .number_of_spots
                                                ),
                                                function(i) {
                                                  return _vm.parkingItem
                                                    .number_of_spots > 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-sm-6"
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.label + i
                                                                ) +
                                                                " "
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.form[
                                                                    item.name
                                                                  ][i - 1],
                                                                expression:
                                                                  "form[item.name][i-1]"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control margin-bottom-2x",
                                                            attrs: {
                                                              type: "text"
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.form[
                                                                  item.name
                                                                ][i - 1]
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.form[
                                                                    item.name
                                                                  ],
                                                                  i - 1,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "padding-right-3x padding-left-3x"
                                                        },
                                                        [
                                                          Array.isArray(
                                                            _vm.form[item.name]
                                                          )
                                                            ? _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.form[
                                                                        item
                                                                          .name
                                                                      ][i - 1],
                                                                    expression:
                                                                      "form[item.name][i-1]"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control margin-bottom-2x",
                                                                attrs: {
                                                                  type: "text"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.form[
                                                                      item.name
                                                                    ][i - 1]
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.form[
                                                                        item
                                                                          .name
                                                                      ],
                                                                      i - 1,
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.form[
                                                                        item
                                                                          .name
                                                                      ],
                                                                    expression:
                                                                      "form[item.name]"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control margin-bottom-2x",
                                                                attrs: {
                                                                  type: "text"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.form[
                                                                      item.name
                                                                    ]
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      item.name,
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                        ]
                                                      )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.form[item.name],
                                                    expression:
                                                      "form[item.name]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.form[item.name]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.form,
                                                      item.name,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                        _vm._v(" "),
                                        _vm.errors[item.name]
                                          ? _c(
                                              "div",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.errors[item.name][0]
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }
                          )
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: {
                          id: _vm.activeHistory.location.id,
                          permits: ["update_reservation_dates"]
                        },
                        expression:
                          "{id: activeHistory.location.id, permits: ['update_reservation_dates']}",
                        arg: "allow"
                      }
                    ],
                    staticClass: "col-xs-4"
                  },
                  [
                    _vm._l(_vm.form.items, function(item, index) {
                      return _c("div", { key: "item_" + index }, [
                        _c("b", [
                          _vm._v(
                            "Date (" +
                              _vm._s(index + 1) +
                              "): " +
                              _vm._s(item.type)
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-10 margin-top-3x" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("From Date")]),
                                  _vm._v(" "),
                                  _c("VueCtkDateTimePicker", {
                                    attrs: {
                                      formatted:
                                        item.type == "room"
                                          ? "MM/DD/YYYY"
                                          : "MM/DD/YYYY hh:mm A",
                                      format: "YYYY-MM-DD HH:mm:00",
                                      "max-date": item.to_date,
                                      "only-date": item.type == "room"
                                    },
                                    model: {
                                      value: item.from_date,
                                      callback: function($$v) {
                                        _vm.$set(item, "from_date", $$v)
                                      },
                                      expression: "item.from_date"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors["items." + index + ".from_date"]
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors[
                                                  "items." +
                                                    index +
                                                    ".from_date"
                                                ][0]
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("To Date")]),
                                  _vm._v(" "),
                                  _c("VueCtkDateTimePicker", {
                                    attrs: {
                                      formatted:
                                        item.type == "room"
                                          ? "MM/DD/YYYY"
                                          : "MM/DD/YYYY hh:mm A",
                                      format: "YYYY-MM-DD HH:mm:00",
                                      "min-date": item.from_date,
                                      "only-date": item.type == "room"
                                    },
                                    model: {
                                      value: item.to_date,
                                      callback: function($$v) {
                                        _vm.$set(item, "to_date", $$v)
                                      },
                                      expression: "item.to_date"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors["items." + index + ".to_date"]
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors[
                                                  "items." + index + ".to_date"
                                                ][0]
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              item.type == "parking" &&
                              _vm.allowChangeSpotNumbers
                                ? _c("div", [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [_vm._v("Number of spots")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.number_of_spots,
                                            expression: "item.number_of_spots"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number" },
                                        domProps: {
                                          value: item.number_of_spots
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeNumber()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              item,
                                              "number_of_spots",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors[
                                        "items." + index + ".number_of_spots"
                                      ]
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.errors[
                                                      "items." +
                                                        index +
                                                        ".number_of_spots"
                                                    ][0]
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == "room"
                                ? _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("label", [
                                          _vm._v("Number of rooms")
                                        ]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.number_of_rooms,
                                              expression: "item.number_of_rooms"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: item.number_of_rooms
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "number_of_rooms",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.errors[
                                          "items." + index + ".number_of_rooms"
                                        ]
                                          ? _c(
                                              "div",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.errors[
                                                        "items." +
                                                          index +
                                                          ".number_of_rooms"
                                                      ][0]
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        Number(item.number_of_rooms),
                                        function(num, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "room_num" + ind,
                                              staticClass: "form-group"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Room (" +
                                                    _vm._s(ind + 1) +
                                                    ") - Guests in room"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.guests_in_rooms[ind],
                                                    expression:
                                                      "item.guests_in_rooms[ind]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    item.guests_in_rooms[ind]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.guests_in_rooms,
                                                      ind,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors[
                                                "items." +
                                                  index +
                                                  ".guests_in_rooms." +
                                                  ind
                                              ]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.errors[
                                                              "items." +
                                                                index +
                                                                ".guests_in_rooms." +
                                                                ind
                                                            ][0]
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        Number(item.number_of_rooms),
                                        function(num, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "room_name" + ind,
                                              staticClass: "form-group"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Room (" +
                                                    _vm._s(ind + 1) +
                                                    ") - Guests names"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.guest_names[ind],
                                                    expression:
                                                      "item.guest_names[ind]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: item.guest_names[ind]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.guest_names,
                                                      ind,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors[
                                                "items." +
                                                  index +
                                                  ".guest_names." +
                                                  ind
                                              ]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.errors[
                                                              "items." +
                                                                index +
                                                                ".guest_names." +
                                                                ind
                                                            ][0]
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _vm.costs_loading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              disabled: _vm.costs_loading,
                              type: "button"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            }),
                            _vm._v("\n            SEARCHING...\n        ")
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onSubmitCost.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("\n          SEARCH\n        ")]
                        )
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.costs
                  ? _c("div", { staticClass: "col-xs-4" }, [
                      _c("p", {}),
                      _c("h4", [_vm._v("New Calculations:")]),
                      _vm._v(" "),
                      _c("p"),
                      _vm._v(" "),
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", [
                                _vm._v(
                                  "\n                  Subtotal: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.sub_total.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                  Fees: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.fees_total.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                  Taxes: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.orginal_total_tax.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                  Total Discount: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.discount.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.activeHistory.partial_refund
                                ? _c("div", [
                                    _vm._v(
                                      "\n                  Partial Refund: " +
                                        _vm._s(
                                          _vm.currencyCode(
                                            _vm.activeHistory.location
                                          ) +
                                            _vm.costs.reservation.partial_refund.toFixed(
                                              2
                                            )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                  Total: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.grand_total.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                  "
                                ),
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["location_user"],
                                        expression: "['location_user']",
                                        arg: "allow"
                                      },
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:prevent",
                                        value: ["super_admin"],
                                        expression: "['super_admin']",
                                        arg: "prevent"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Does not include channel added fees\n                  "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    "New Amount To Pay: \n                  "
                                  ),
                                  _vm.costs.reservation.grand_total <
                                  _vm.costs.reservation.due_at_location
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.currencyCode(
                                                _vm.activeHistory.location
                                              ) +
                                                (
                                                  _vm.costs.reservation
                                                    .grand_total -
                                                  _vm.costs.reservation
                                                    .location_commission -
                                                  (_vm.costsActiveHistory
                                                    .grand_total -
                                                    _vm.costsActiveHistory
                                                      .location_total)
                                                ).toFixed(2)
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.currencyCode(
                                                _vm.activeHistory.location
                                              ) +
                                                (
                                                  _vm.costs.reservation
                                                    .grand_total -
                                                  _vm.costs.reservation
                                                    .due_at_location -
                                                  (_vm.costsActiveHistory
                                                    .grand_total -
                                                    _vm.costsActiveHistory
                                                      .due_at_location_total)
                                                ).toFixed(2)
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                  Due at Check-in: " +
                                    _vm._s(
                                      _vm.currencyCode(
                                        _vm.activeHistory.location
                                      ) +
                                        _vm.costs.reservation.due_at_location.toFixed(
                                          2
                                        )
                                    ) +
                                    "\n                "
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ]),
      _vm._v(" "),
      _vm.activeHistory
        ? _c("guarantee-card", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.activeHistory.location_id,
                  permits: ["guarantee_reservation_card_list"]
                },
                expression:
                  "{\n      id: activeHistory.location_id, permits: ['guarantee_reservation_card_list']\n    }",
                arg: "allow"
              }
            ],
            ref: "cardModal"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeHistory
        ? _c("partial-refund-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: {
                  id: _vm.activeHistory.location_id,
                  permits: ["reservations_partial_refund"]
                },
                expression:
                  "{\n      id: activeHistory.location_id, permits: ['reservations_partial_refund']\n    }",
                arg: "allow"
              }
            ],
            ref: "partialRefundModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeHistory && _vm.activeHistory.partial_refund > 0
        ? _c("revert-partial-refund-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            ref: "revertPartialRefundModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeHistory
        ? _c("cancel-reservation-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:prevent",
                value: ["channel_user", "location_user"],
                expression: "['channel_user', 'location_user']",
                arg: "prevent"
              }
            ],
            ref: "cancelReservationModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeHistory
        ? _c("update-reservation-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ],
            ref: "updateReservationModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeHistory
        ? _c("partial-refund-request-modal", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["channel_user"],
                expression: "['channel_user']",
                arg: "allow"
              }
            ],
            ref: "partialRefundRequestModal",
            on: { close: _vm.onCloseRefundModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDialog", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9ddad71e", { render: render, staticRenderFns: staticRenderFns })
  }
}