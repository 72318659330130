import { http } from 'services'

function initLocationKeys () {
  return {
    channels: [],
    roomTypes: [],
    parkingTypes: [],
    leaseParkingTypes: [],
    bundleTypes: [],
    fees_breakdown: [],
    fees: [],
    cancellationPolicies: [],
    extraFields: [],
    reservations: {},
    need_to_save: false,
    rate_view: {},
    profile: {},
    comments: [],
    parkingType: null,
    in_house_report_totals: [],
  }
}

// initial state
const state = () => ({
  all: [],
  all_locations: [],
  active: initLocationKeys(),
  linkedLocationParkingTypes: [],
  parkingTypesWithStructure: [],
  active_reservation: {},
  activeChannel: {},
  activeParkingTypeRates: null,
  activeRoomTypeRates: null,
  activeBundleTypeRates: null,
  calendarRates: null,
  // fakeBlackOut: null,
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all locations (params: {_q: search name, page})
  ['locations.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('locations', {
      params
    })).then ((res) => {
      commit('GET_ALL_LOCATIONS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //get single location by id
  ['locations.get'] ({commit, state}, {id, params}) {
    commit('CLEAR_ERRORS')

    // return;
    return (http.get(`locations/${id}`, {
      params
    })).then((location) => {
      commit('GET_LOCATION', location);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.roomTypes'] ({commit, state}, {id, filter = 'active'}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/room-types?_all=true&_filter=${filter}`))
    .then((res) => {
      commit('GET_LOCATION_ROOM_TYPES', res);
    })
  },

  ['locations.parkingTypes'] ({commit, state}, {id, filter = 'active', all = true, linked_location = false, with_car_types = false}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/parking-types?_all=${all}&_filter=${filter}&_with_car_types=${with_car_types}`))
    .then((res) => {
      if(with_car_types) {
        commit('GET_LOCATION_PARKING_TYPES_WITH_STRUCTURE', res);
      }
      else if (!linked_location) {
        commit('GET_LOCATION_PARKING_TYPES', res);
      } else {
        commit('GET_LINKED_LOCATION_PARKING_TYPES', res);
      }
    })
  },

  ['locations.leaseParkingTypes'] ({commit, state}, {id, from, to, filter = 'active', all = true}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/lease-parking-types?from=${from}&to=${to}&_all=${all}&_filter=${filter}`))
    .then ((res) => {
      commit('GET_LOCATION_LEASE_PARKING_TYPES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.bundleTypes'] ({commit, state}, {id ,filter = 'active'}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/bundles?_all=true&_filter=${filter}`))
    .then((res) => {
      commit('GET_LOCATION_BUNDLE_TYPES', res);
    })
  },

  ['locations.getFees'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/fees?_all=true`))
    .then((res) => {
      commit('GET_LOCATION_FEES', res);
    })
  },

  ['locations.getReservationFees'] ({commit}, {reservation_id, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${location_id}/reservations/${reservation_id}/fees`))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION_FEES', res);
    })
  },

  ['locations.cancellationPolicies'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/cancellation-policy?_all=true`))
    .then((res) => {
      commit('GET_LOCATION_CANCELLATION_POLICIES', res);
    })
  },

  ['locations.extraFields'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/extra-fields?_all=true`))
    .then((res) => {
      commit('GET_LOCATION_EXTRA_FIELDS', res);
    })
  },

  //get channels of a location
  ['locations.getChannels'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/channels?_all=true`))
    .then ((res) => {
      commit('GET_LOCATION_CHANNELS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getReservations'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${id}/reservations`, {
      params
    }))
    .then((res) => {
      commit('GET_LOCATION_RESERVATIONS', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },
  

  ['locations.getReservation'] ({commit}, {lId, id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${lId}/reservations/${id}`))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.partialRefund'] ({commit}, {lId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${lId}/reservations/${id}/partial-refund`, data))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.revertPartialRefund'] ({commit}, {lId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${lId}/reservations/${id}/revert-partial-refund`, data))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  //update location
  ['locations.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    // let config = {};

    return (http.put(`locations/${id}`, data)).then ((res) => {
      commit('EDIT_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //update location profile
  ['locations.editProfile'] ({ commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`locations/${id}/profile`, data)).then ((res) => {
      commit('EDIT_LOCATION_PROFILE', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getProfile'] ({ commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.get(`locations/${id}/profile`)).then ((res) => {
      commit('GET_LOCATION_PROFILE', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getComments'] ({ commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.get(`locations/${id}/comments`)).then ((res) => {
      commit('GET_LOCATION_COMMENS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.newComment'] ({ commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`locations/${id}/comments`, data)).then ((res) => {
      commit('NEW_LOCATION_COMMENT', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.updateComment'] ({ commit}, {locId, id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`locations/${locId}/comments/${id}`, data)).then ((res) => {
      commit('UPDATE_LOCATION_COMMENT', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.removeComment']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${locId}/comments/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_COMMENT', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.saveLeaseHistory'] ({ commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`arrangement-lease-history-job/${id}`, data)).then ((res) => {
    }).catch ((res) => {
    })
  },

  ['locations.updateChannels'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');


    return (http.post(`locations/${id}/channels`, data)).then ((res) => {
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.uploadImages'] ({commit}, {id, files, type}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
    }

    formData.append('type', type);

    return (http.post(`locations/${id}/photos`, formData, config)).then ((res) => {
      commit('UPLOAD_IMAGES', {res, type});
    })
    .catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.setFeaturedImage'] ({commit}, {id, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${location_id}/featured/${id}`)).then ((res) => {
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.upsertEmployee'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.post(`locations/${id}/users`, data))
    .then ((res) => {
      commit('LOCATIONS_UPSERT_EMPLOYEE', res);
    })
    .catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.removeEmployee'] ({ commit }, {locId, id}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`locations/${locId}/users/${id}`))
    .then ((res) => {
      commit('REMOVE_EMPLOYEE', res);
    })
    .catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.removeChannelFromLocation'] ({commit}, id) {
    commit('CLEAR_ERRORS')
    return commit('REMOVE_CHANNEL_FROM_LOCATION', id);
  },

  ['locations.addRoomRateToChannels'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`channel-room-rates`, data)).then ((res) => {
      commit('ADD_ROOM_RATE_TO_CHANNELS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.addParkingRateToChannels'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`channel-parking-rates`, data)).then ((res) => {
      commit('ADD_PARKING_RATE_TO_CHANNELS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.addBundleRate'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`channel-bundle-rates`, data)).then ((res) => {
      commit('ADD_BUNDLE_TO_CHANNELS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`locations/${id}`)).then ((res) => {
      commit('REMOVE_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`locations/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`locations`, data)).then ((res) => {
      commit('NEW_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //location types
  ['locations.newLocationRoomType'] ({commit}, data) {
    commit('CLEAR_ERRORS')
    console.log('data', data);
    // return;
    return (http.post('location-room-types', data)).then((res) => {
      commit('NEW_LOCATION_ROOM_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  ['locations.newLocationParkingType'] ({commit}, data) {
    commit('CLEAR_ERRORS')
    return (http.post('location-parking-types', data)).then((res) => {
      commit('NEW_LOCATION_PARKING_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  ['locations.getLocationParkingType'] ({commit}, {id}) {
    commit('CLEAR_ERRORS')
    return (http.get(`location-parking-types/${id}`)).then((res) => {
      commit('GET_LOCATION_PARKING_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  ['locations.newLocationBundleType'] ({commit}, data) {
    commit('CLEAR_ERRORS')
    return (http.post('bundles', data)).then((res) => {
      commit('NEW_LOCATION_BUNDLE_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  ['locations.updateParkingType'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`location-parking-types/${id}`, data)).then((res) => {
      commit('UPDATE_PARKING_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateRoomType'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`location-room-types/${id}`, data)).then((res) => {
      commit('UPDATE_ROOM_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateBundleType'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`bundles/${id}`, data)).then((res) => {
      commit('UPDATE_BUNDLE_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeParkingType'] ({commit}, {id, force, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`location-parking-types/${id}?location_id=${location_id}${force ? '&force=true' : ''}`)).then((res) => {
      commit('REMOVE_PARKING_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.revertCancel'] ({commit}, {lId, id}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${lId}/reservations/${id}/revert-cancel`))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.disputeReservation'] ({commit}, {lId, id, amount = 0}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${lId}/reservations/${id}/dispute`,{amount: amount}))
    .then((res) => {
      commit('GET_LOCATION_RESERVATION', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },
  
  ['locations.removeRoomType'] ({commit}, {id, force, location_id}) {
    commit('CLEAR_ERRORS')
    console.log('force', force);
    return (http.delete(`location-room-types/${id}?location_id=${location_id}${force ? '&force=true' : ''}`)).then((res) => {
      commit('REMOVE_ROOM_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeBundleType'] ({commit}, {id, force, location_id}) {
    commit('CLEAR_ERRORS')
    console.log('force', force);
    return (http.delete(`bundles/${id}?location_id=${location_id}${force ? '&force=true' : ''}`)).then((res) => {
      commit('REMOVE_BUNDLE_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },
  //undo remove rate types

  ['locations.undoRemoveParkingType'] ({commit}, {id, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`location-parking-types/${id}?location_id=${location_id}&_undelete=true`)).then((res) => {
      commit('REMOVE_PARKING_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.undoRemoveRoomType'] ({commit}, {id, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`location-room-types/${id}?location_id=${location_id}&_undelete=true`)).then((res) => {
      commit('REMOVE_ROOM_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.undoRemoveBundleType'] ({commit}, {id, location_id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`bundles/${id}?location_id=${location_id}&_undelete=true`)).then((res) => {
      commit('REMOVE_BUNDLE_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  //type rates
  ['locations.getParkingRatesByLocationType'] ({commit}, type_id) {
    commit('CLEAR_ERRORS')
    return (http.get(`channel-parking-rates?parking_type=${type_id}&_all=true`)).then((res) => {
      commit('GET_PARKING_RATES_OF_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.getRoomRatesByLocationType'] ({commit}, type_id) {
    commit('CLEAR_ERRORS')
    return (http.get(`channel-room-rates?room_type=${type_id}&_all=true`)).then((res) => {
      commit('GET_ROOM_RATES_OF_TYPE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeRate'] ({commit, dispatch}, {type, rate, force, channel_id = null, location_id = null}) {
    commit('CLEAR_ERRORS')

    let channel = '';
    if (channel_id) {
      channel = `channel_id=${channel_id}&`;
    }

    let location = '';
    if (location_id) {
      location = `location_id=${location_id}&`;
    }

    return (http.delete(`channel-${type.toLowerCase()}-rates/${rate.id}?${channel}${location}${force ? 'force=true' : ''}`)).then((res) => {
      commit(`REMOVE_${type.toUpperCase()}_RATE`, res); 
      dispatch('rateManagement.deleteRateAdjustment', {type, res})
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeRates'] ({commit, dispatch}, { location_id, rate, force}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`locations/${location_id}/rate-adjustment/${rate.rates_adjustments_history_id}?${force ? 'force=true' : ''}`)).then((res) => {
      
      dispatch('rateManagement.deleteRateAdjustments', {rate,res})
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.clearHistory'] ({commit, dispatch}, {location_id, force}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`locations/${location_id}/clear-rate-adjustment-history?${force ? 'force=true' : ''}`)).then((res) => {
      
      dispatch('rateManagement.clearRateHistory', {res})
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },
  
  ['locations.updateLocationRates'] ({commit}, {type, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`channel-${type.toLowerCase()}-rates/${data.id}`, data)).then((res) => {
      commit(`UPDATE_${type.toUpperCase()}_RATE`, res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.update_no_show_reseration'] ({commit}, no_show) {
    commit('CLEAR_ERRORS');
    commit('UPDATE_NO_SHOW_RESERVATION', no_show);
  },

  //channel rates

  ['locations.getRatesForListView'] ({commit}, {type, locationId, channelId, typeId, page = 1, perPage = 15}) {
    commit('CLEAR_ERRORS')
    let request, roomRates = {}, parkingRates = {}, bundleRates = {};
    if (type == 'room') {
      request = http.get(`locations/${locationId}/channels/${channelId}/rooms-rates/${typeId}?page=${page}&per_page=${perPage}`);
    } else if (type == 'parking') {
      request = http.get(`locations/${locationId}/channels/${channelId}/parkings-rates/${typeId}?page=${page}&per_page=${perPage}`);
    } else {
      request = http.get(`locations/${locationId}/channels/${channelId}/bundle-rates/${typeId}?page=${page}&per_page=${perPage}`);
    }

    return (request).then((res) => {
      if (type == 'room') {
        roomRates = res;
      } else if (type == 'parking') {
        parkingRates = res;
      } else {
        bundleRates = res;
      }
      commit('GET_CHANNEL_RATES', {channelId, roomRates, parkingRates, bundleRates, typeId, type});
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.getLineCalendarRates'] ({commit}, {locationId, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${locationId}/line-calendar-data`, data))
    .then(calendarRates => {
      commit('GET_CALENDAR_RATES', {data, calendarRates});
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.getCalendarRates'] ({commit}, {locationId, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${locationId}/calendar-data`, data))
    .then(calendarRates => {
      commit('GET_CALENDAR_RATES', {data, calendarRates});
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  //fees
  ['locations.newFee']({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${id}/fees`, data)).then((res) => {
      commit('NEW_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateFee'] ({commit}, {locId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${locId}/fees/${id}`, data)).then((res) => {
      commit('UPDATE_LOCATION_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeFee']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${locId}/fees/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  //cancellation policies
  ['locations.addPolicies']({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${id}/cancellation-policy/patch`, data)).then((res) => {
      commit('NEW_CANCELLATION_POLICIES', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removePolicy']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${locId}/cancellation-policy/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_CANCELLATION_POLICY', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  //extra fields
  ['locations.addExtraFields']({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${id}/extra-fields/patch`, data)).then((res) => {
      commit('NEW_EXTRA_FIELDS', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeExtraField']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${locId}/extra-fields/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_EXTRA_FIELD', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.addCarTypeStructure']({commit}, {locId, data}) {
    commit('CLEAR_ERRORS')
    return (http.post(`locations/${locId}/car-types-structure`, data)).then((res) => {
      commit('ADD_LOCATION_CAR_TYPE_STRUCTURE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateCarTypeStructure']({commit}, {locId, id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`locations/${locId}/car-types-structure/${id}`, data)).then((res) => {
      commit('UPDATE_LOCATION_CAR_TYPE_STRUCTURE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeCarTypeStructure']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`locations/${locId}/car-types-structure/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_CAR_TYPE_STRUCTURE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeImage'] ({commit}, {image, type}) {
    commit('CLEAR_ERRORS')
    commit('REMOVE_IMAGE_FROM_LOCATION', {image, type});
  },

  ['locations.setRateView'] ({commit}, data) {
    commit('CLEAR_ERRORS')
    commit('SET_LOCATION_VIEW_DATA', data);
  },

  ['locations.getInHouseReportTotals'] ({commit}, {location_id}) {
    commit('CLEAR_ERRORS')

    return (http.get(`locations/${location_id}/in-house-report-totals`)).then((res) => {
      commit('GET_IN_HOUSE_REPORT_TOTALS', res);
    })
    .catch ((res) => {
      commit('REPORTS_ERROR', res);
    });
  },
}

// mutations
const mutations = {
  GET_ALL_LOCATIONS (state, res) {
    state.all = res.data;
    if (res.data && res.data.hasOwnProperty("total")) {
      // 'all_locations' To get locations in a paginated format
      state.all_locations = res.data;
    }
  },

  REMOVE_IMAGE_FROM_LOCATION (state, {image, type}) {
    state.active[type + '_photos'] = state.active[type + '_photos'].filter((item) => item.id != image.id);
    // state.active.need_to_save = true;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_LOCATION (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_LOCATION (state, location) {
    let preserved = {};
    for (let key in initLocationKeys()) {
      preserved[key] = state.active[key];
    }

    state.active = location.data;
    state.active = Object.assign({}, state.active, preserved);
  },

  GET_LOCATION_FEES (state, res) {
    state.active.fees = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_RESERVATION_FEES (state, res) {
    state.active.fees_breakdown = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_CANCELLATION_POLICIES (state, res) {
    state.active.cancellationPolicies = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_EXTRA_FIELDS (state, res) {
    state.active.extraFields = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_ROOM_TYPES (state, res) {
    state.active.roomTypes = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_PARKING_TYPE (state, res) {
    state.active.parkingType = _.isEmpty(res.data) ? [] : res.data;
  },
  
  GET_LOCATION_PARKING_TYPES (state, res) {
    state.active.parkingTypes = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_LEASE_PARKING_TYPES (state, res) {
    state.active.leaseParkingTypes = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LINKED_LOCATION_PARKING_TYPES (state, res) {
    state.linkedLocationParkingTypes = _.isEmpty(res.data) ? [] : res.data;
  },
  
  GET_LOCATION_PARKING_TYPES_WITH_STRUCTURE (state, res) {
    state.parkingTypesWithStructure = _.isEmpty(res.data) ? [] : res.data;
  },

  GET_LOCATION_BUNDLE_TYPES (state, res) {
    state.active.bundleTypes = _.isEmpty(res.data) ? [] : res.data;
  },


  GET_LOCATION_CHANNELS (state, res) {
    state.active.channels = res.data;
    state.active = _.cloneDeep(state.active);
  },

  EDIT_LOCATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active.need_to_save = false;
    state.active = _.cloneDeep(state.active);
  },

  EDIT_LOCATION_PROFILE (state, res) {
    state.active.profile = _.assign(state.active.profile, res.data);
    state.active = _.cloneDeep(state.active);
  },

  GET_LOCATION_PROFILE (state, res) {
    state.active.profile = res.data;
  },

  GET_LOCATION_COMMENS (state, res) {
    state.active.comments = _.isEmpty(res.data) ? [] : res.data;
  },

  NEW_LOCATION_COMMENT (state, res) {
    state.active.comments.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_LOCATION_COMMENT (state, res) {
    let indx = _.findIndex(state.active.comments, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.comments[indx] = _.assign(state.active.comments[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_COMMENT (state, res) {
    state.active.comments = state.active.comments.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  UPLOAD_IMAGES (state, {res, type}) {
    // let photos = res.data.map((item) => {
    //   return {filename: env.default.backendUrl + item}
    // });
    state.active[type + '_photos'] = state.active[type + '_photos'].concat(res.data);
    state.active = _.cloneDeep(state.active);
  },

  GET_LOCATION_RESERVATIONS (state, reservations) {
    state.active.reservations = reservations.data;
    _.forEach(state.active.reservations.data, (item, index) => {
      state.active.reservations.data[index].active_history = _.find(state.active.reservations.data[index].history, (item) => item.active);
    });
  },

  GET_LOCATION_RESERVATION (state, reservation) {
    state.active_reservation = reservation.data;
  },

  NEW_LOCATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  LOCATIONS_UPSERT_EMPLOYEE (state, res) {
    let index = _.findIndex(state.active.users, (user) => user.id == res.data.id);
    if (index > -1) {
      state.active.users[index] = res.data;
      state.active = _.cloneDeep(state.active);
    } else {
      state.active.users = state.active.users.concat(res.data);
    }
  },

  REMOVE_EMPLOYEE (state, res) {
    state.active.users = state.active.users.filter(
      (user) => user.id != res.data.id);
  },

  REMOVE_CHANNEL_FROM_LOCATION (state, id) {
    state.active.channels = _.filter(state.active.channels,
      (item) => item.channel.id != id);

    state.active = _.cloneDeep(state.active);
  },

  //NEW TYPES
  NEW_LOCATION_ROOM_TYPE (state, res) {
    state.active.roomTypes.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_LOCATION_PARKING_TYPE (state, res) {
    state.active.parkingTypes.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_LOCATION_BUNDLE_TYPE (state, res) {
    state.active.bundleTypes.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  //update loction types
  UPDATE_PARKING_TYPE (state, res) {
    let indx = _.findIndex(state.active.parkingTypes, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.active.parkingTypes[indx] = _.assign(state.active.parkingTypes[indx], res.data);
      state.active = _.cloneDeep(state.active);
    }
  },

  UPDATE_ROOM_TYPE (state, res) {
    let indx = _.findIndex(state.active.roomTypes, (item) => {
      return item.id == res.data.id
    });

    if (indx > -1) {
      state.active.roomTypes[indx] = _.assign(state.active.roomTypes[indx], res.data);
      state.active = _.cloneDeep(state.active);
    }
  },

  UPDATE_BUNDLE_TYPE (state, res) {
    let indx = _.findIndex(state.active.bundleTypes, (item) => {
      return item.id == res.data.id
    });

    if (indx > -1) {
      state.active.bundleTypes[indx] = _.assign(state.active.bundleTypes[indx], res.data);
      state.active = _.cloneDeep(state.active);
    }
  },

  //REMOVE TYPES
  REMOVE_ROOM_TYPE (state, res) {
    state.active.roomTypes = state.active.roomTypes.filter((item) => {
      return item.id !== res.data.id
    })
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_BUNDLE_TYPE (state, res) {
    state.active.bundleTypes = state.active.bundleTypes.filter((item) => {
      return item.id !== res.data.id
    })
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_PARKING_TYPE (state, res) {
    state.active.parkingTypes = state.active.parkingTypes.filter((item) => {
      return item.id !== res.data.id
    })
    state.active = _.cloneDeep(state.active);
  },

  //TYPE RATES
  GET_PARKING_RATES_OF_TYPE (state, res) {
    state.activeParkingTypeRates = res.data
  },

  GET_ROOM_RATES_OF_TYPE (state, res) {
    state.activeRoomTypeRates = res.data
  },

  ADD_ROOM_RATE_TO_CHANNELS (state, res) {
    //TODO: update active channel
    console.log(res);

  },

  ADD_PARKING_RATE_TO_CHANNELS (state, res) {
    //TODO: update active channel
    console.log(res);

  },

  ADD_BUNDLE_TO_CHANNELS (state, res) {
    //TODO: update active channel
    console.log(res);

  },

  UPDATE_NO_SHOW_RESERVATION (state, data) {
    let active = state.active_reservation;
    if (!_.isEmpty(state.active.reservations)) {
      let index = state.active.reservations.data.findIndex(i => i.id === active.id);
      state.active.reservations.data[index].no_show = data.no_show;
    }
  },

  //CHANNEL RATES
  GET_CHANNEL_RATES (state, {channelId, roomRates, parkingRates, bundleRates, typeId, type}) {  
    let channel = _.find(state.active.channels, (data) => data.channel.id == channelId);
    state.activeChannel = channel
    const updateOrAddRate = (ratesArray, typeId, newData) => {
      const index = _.findIndex(ratesArray, (rate) => rate.typeId === typeId);
      if (index !== -1) {
        _.set(ratesArray, `[${index}]`, { typeId, rates: newData });
      } else {
        ratesArray.push({ typeId, rates: newData });
      }
    };


    if(type == 'parking') {
      if(!state.activeChannel.parkingRates) {
        state.activeChannel.parkingRates = [];
      }
      updateOrAddRate(state.activeChannel.parkingRates, typeId, parkingRates.data);
    }  else if(type == 'room') {
      if(!state.activeChannel.roomRates) {
        state.activeChannel.roomRates = [];
      }
      updateOrAddRate(state.activeChannel.roomRates, typeId, roomRates.data);
    } else {
      if(!state.activeChannel.bundleRates) {
        state.activeChannel.bundleRates = [];
      }
      updateOrAddRate(state.activeChannel.bundleRates, typeId, bundleRates.data);
    }    

    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  GET_CALENDAR_RATES (state, {data, calendarRates}) {
    // console.log('data.channel', data.channel);
    let channel = _.find(state.active.channels, (channel) => channel.channel.id == data.channel);
    console.log('channel.channel.id', channel.channel);
    console.log('channel', channel);
    state.calendarRates = calendarRates.data;
  },

  UPDATE_PARKING_RATE (state, res) {
    let index = _.findIndex(state.activeChannel.parkingRates, item => item.typeId == res.data.location_parking_type_id);
    if(index > -1) {
      let indx = _.findIndex(state.activeChannel.parkingRates[index].rates.data, (item) => item.id == res.data.id);
      if(indx > -1) {
        state.activeChannel.parkingRates[index].rates.data[indx] = _.assign(state.activeChannel.parkingRates[index].rates.data[indx], res.data);
      }
    }
    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  UPDATE_ROOM_RATE (state, res) {
    let index = _.findIndex(state.activeChannel.roomRates, item => item.typeId == res.data.location_room_type_id);
    if(index > -1) {
      let indx = _.findIndex(state.activeChannel.roomRates[index].rates.data, (item) => item.id == res.data.id);
      if(indx > -1) {
        state.activeChannel.roomRates[index].rates.data[indx] = _.assign(state.activeChannel.roomRates[index].rates.data[indx], res.data);
      }
    }
    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  REMOVE_PARKING_RATE (state, res) {
    try {
      let index = _.findIndex(state.activeChannel.parkingRates, item => item.typeId == res.data.location_parking_type_id);
      if(index > -1) {
        state.activeChannel.parkingRates[index].rates.data = state.activeChannel.parkingRates[index].rates.data.filter((item) => {
          return item.id != res.data.id
        })
      }
    } catch (e) {
      console.log('eee', e);
    }
    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  REMOVE_ROOM_RATE (state, res) {
    try {
      let index = _.findIndex(state.activeChannel.roomRates, item => item.typeId == res.data.location_room_type_id);
      if(index > -1) {
        state.activeChannel.roomRates[index].rates.data = state.activeChannel.roomRates[index].rates.data.filter((item) => {
          return item.id != res.data.id
        })
      }
    } catch (e) {

    }
    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  REMOVE_BUNDLE_RATE (state, res) {
    try {
      let index = _.findIndex(state.activeChannel.bundleRates, item => item.typeId == res.data.location_bundle_id);
      if(index > -1) {
        state.activeChannel.bundleRates[index].rates.data = state.activeChannel.bundleRates[index].rates.data.filter((item) => {
          return item.id != res.data.id
        })
      }
    } catch (e) {

    }
    state.activeChannel = _.cloneDeep(state.activeChannel);
  },

  //Blackout dates
  GET_FAKE_BLACKOUT (state, res) {
    state.fakeBlackOut = res.data;
  },

  NEW_BLACKOUT (state, res) {
    state.active.blackouts.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_BLACKOUT (state, res) {
    state.active.blackouts = state.active.blackouts.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  NEW_FEE (state, res) {
    state.active.fees.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_LOCATION_FEE (state, res) {
    let indx = _.findIndex(state.active.fees, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.fees[indx] = _.assign(state.active.fees[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_FEE (state, res) {
    state.active.fees = state.active.fees.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  NEW_CANCELLATION_POLICIES (state, res) {
    state.active.cancellationPolicies = state.active.cancellationPolicies.concat(res.data);
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_CANCELLATION_POLICY (state, res) {
    state.active.cancellationPolicies = state.active.cancellationPolicies.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  NEW_EXTRA_FIELDS (state, res) {
    state.active.extraFields = state.active.extraFields.concat(res.data);
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_EXTRA_FIELD (state, res) {
    state.active.extraFields = state.active.extraFields.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_CAR_TYPE_STRUCTURE (state, res) {
    let indx = _.findIndex(state.parkingTypesWithStructure, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.parkingTypesWithStructure[indx] = _.assign(state.parkingTypesWithStructure[indx], res.data);
    }
  },

  ADD_LOCATION_CAR_TYPE_STRUCTURE (state, res) {
    let indx = _.findIndex(state.parkingTypesWithStructure, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.parkingTypesWithStructure[indx] = _.assign(state.parkingTypesWithStructure[indx], res.data);
    }
  },

  UPDATE_LOCATION_CAR_TYPE_STRUCTURE (state, res) {
    let indx = _.findIndex(state.parkingTypesWithStructure, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.parkingTypesWithStructure[indx] = _.assign(state.parkingTypesWithStructure[indx], res.data);
    }
  },

  // CLEAR_FAKE_BLACKOUT () {
  //   state.fakeBlackOut = null
  // },

  SET_LOCATION_VIEW_DATA (state, data) {
    state.active.rate_view = data;
  },

  GET_IN_HOUSE_REPORT_TOTALS (state, res) {
    state.in_house_report_totals = res.data;
  },


  //ERRORS
  LOCATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
