import moment from 'moment'
import logoSlider from 'components/logoSlider'
import newfooter from 'components/newfooter'
import newheader from 'components/newheader'
import { user, DataLayer} from 'services'

const components = {
  logoSlider,
  'new-footer':newfooter,
  'new-header':newheader,
}

export default {
  components,
  data () {
    return {
      cards: [
        {
          text: `I can't recommend Reservations Lab enough for parking reservations! Their straightforward booking system make it incredibly easy to maximize revenue with minimal effort. Customers appreciate the upfront payment model, which eliminates any hidden fees and ensures a smooth experience. As a parking facility manager, I've seen a significant increase in bookings since partnering with Reservations Lab. Their team is always available to assist, making the entire process hassle-free. If you're aiming to enhance your profitability without the headaches, this partnership is a must!`,
          quoteImage: '/assets/q.png',
          logo: '/assets/cambria-t.png',
          client: 'John Johnson',
          role: 'General Manager',
          company: 'Cambria Hotel Nashville Airport (BNA)',
        },
        {
          text: `I highly recommend Reservations Lab for parking reservations. Their competitive pricing and user-friendly booking process simplify travel. Customers pay upfront with no surprises, allowing parking facilities to focus on quick check-ins and shuttle services. As a parking facility manager, partnering with Reservations Lab has been a game-changer. Their responsive and supportive staff make every interaction seamless. If you're looking to boost your business, this partnership is one of the best decisions you can make.`,
          quoteImage: '/assets/q.png',
          logo: '/assets/globe-t.png',
          client: 'Will Altman',
          role: 'General Manager',
          company: 'Globe Parking Inc.',
        },
        {
          text: `Working with Reservations Lab has transformed our parking facility operations! They handle everything 
                from revenue optimization to payment processing and dispute resolution, allowing us to focus on what we 
                do best. The seamless integration of their services means we no longer worry about validation or customer 
                complaints. Their dedicated staff is always responsive and supportive, making every interaction smooth and 
                efficient. If you're looking for a partner that takes care of all the complexities of parking management, 
                Reservations Lab is the solution you need!`,
          quoteImage: '/assets/q.png',
          logo: '/assets/express.png',
          client: 'Jackie York',
          role: 'General Manager',
          company: 'Holiday Inn Express & Suites (CVG)',
        },
      ],
      sections: [
        {
            "title": "Centralized and integrated",
            "features": [
                { "name": "Automatic Access to 2 million+ users per month in the parking and travel spaces" },
                { "name": "Active channel management" },
                { "name": "Flexible inventory management" },
                { "name": "Seamless integrations with PARCS systems" }
            ]
        },
        {
            "title": "Flexible and Dynamic Rate management system",
            "features": [
                { "name": "Every day a different price" },
                { "name": "Tiered pricing" },
                { "name": "Fractional adjustments to rate types" },
                { "name": "Predictive and dynamic real-time pricing engine" }
            ]
        },
        {
            "title": "Configurable and customizable to your needs",
            "features": [
                { "name": "Make sure all the right information is disseminated to all channels" },
                { "name": "Customize from 30+ booking conditions to optimize your operation" }
            ]
        },
        {
            "title": "Guest/enforcement management tools",
            "features": [
                { "name": "Late checkout penalties" },
                { "name": "Customer validation" },
                { "name": "Fraud prevention tools" }
            ]
        },
        {
            "title": "Analytics dashboards and insights:",
            "features": [
                { "name": "Advanced reporting metrics" },
                { "name": "Intelligent recommendation solutions" },
                { "name": "A/B testing" }
            ]
        },
        {
            "title": "Active Management of end-user interactions",
            "features": [
                { "name": "Dispute and refund resolution" },
                { "name": "Dedicated account manager to help with ongoing operational requests" }
            ]
        }
    ],
      visibleSections: [],
      lastWindowWidth: null,
      lastWindowHeight: null,
      initialVisibleSections: 1,
      additionalVisibleSections: 1,
      airports: [],
      airports_revenue: [],
      monthlyRevenue: 0,
      annualRevenue: 0,
      centerCardIndex: 0,
      selectedAirport: '',
      selectedAirportObj: '',
      shuttleService: '',
      valetParking: '',
      coveredParking: '',
      numSpots: null,
      coveredSpots: null,
      uncoveredSpots: null,
      matchingAirport: null,
      isAirportMatched: true,
      noSearchResult: false,
      calculatedRevenue: null,
      a_selectClass: 'default', 
      c_selectClass: 'default',
      currentTab: 'propertyManager',
      currentIndex: 0,
      year: moment().format('YYYY')
    }
  },
  watch: {
    coveredParking(newValue, oldValue) {
      if (newValue === 'yes') {
        this.coveredSpots = null;
        this.uncoveredSpots = null;
      } else if (newValue === 'no') {
        this.numSpots = null;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.lastWindowWidth = window.innerWidth;
    this.lastWindowHeight = window.innerHeight;
    this.visibleSections = this.sections.slice(0, this.initialVisibleSections);
    this.fetchAirports();
    this.fetchRevenueData();
    this.startCardRotation();
    window.addEventListener("resize", this.handleResize);
    // this.updateStyle();
  }, 
  beforeDestroy() {
    if ($("#airport").data("ui-selectmenu")) {
      $("#airport").selectmenu("destroy");
    }
    if ($("#shuttle").data("ui-selectmenu")) {
      $("#shuttle").selectmenu("destroy");
    }
  },
  beforeRouteLeave(to, from, next) {
    if ($("#airport").data("ui-selectmenu")) {
      $("#airport").selectmenu("destroy");
    }
    if ($("#shuttle").data("ui-selectmenu")) {
      $("#shuttle").selectmenu("destroy");
    }
    next();
  },
  computed: {
    orderedCards() {
      const reordered = [...this.cards];
      if (typeof window !== "undefined" && window.innerWidth > 1000) {
        const shift = this.currentIndex % this.cards.length;
        return reordered.slice(shift).concat(reordered.slice(0, shift));
      }
      return reordered;
    },
    sortedAirports() {
      return this.airports.slice().sort((a, b) => a.airport_name.localeCompare(b.airport_name));
    },
    showMoreButton() {
        return this.sections.length > this.visibleSections.length;
    },
    showLessButton() {
        return this.visibleSections.length > this.initialVisibleSections;
    },
    gridClass() {
      return this.coveredParking === 'no' ? 'grid-3' : 'grid-2';
    },
    revnueClass() {
      return this.calculatedRevenue ? 'revnue-padding' : '';
    },
    user () {
      return user.getUser();
    },
  },
  methods: {
    isCenterCard(index) {
      return index === Math.floor(this.cards.length / 2);
    },
    setCenterCard(clickedIndex) {
      const centerIndex = Math.floor(this.cards.length / 2);
      this.currentIndex = (this.currentIndex + clickedIndex - centerIndex + this.cards.length) % this.cards.length;
    },
    startCardRotation() {
      if (typeof window !== "undefined") {
        setInterval(() => {
          if (window.innerWidth > 1000) {
            this.currentIndex++;
          }
        }, 10000);
      }
    },
    resetForm() {
      this.selectedAirport = '';
      this.shuttleService = '';
      this.valetParking = '';
      this.coveredParking = '';
      this.coveredSpots = null;
      this.uncoveredSpots = null;
      this.numSpots = null;

      this.calculatedRevenue = null;
      this.monthlyRevenue = 0;
      this.annualRevenue = 0;
      $("#airport").val("").selectmenu("refresh");
      $("#shuttle").val("").selectmenu("refresh");
      $("#airport").next('.ui-selectmenu-button').find('.ui-selectmenu-text').removeClass('active');
      $("#shuttle").next('.ui-selectmenu-button').find('.ui-selectmenu-text').removeClass('active');
      this.resetShuttleMenu();
    },
    initializeSelectMenu() {
      let vm = this;
      let isProcessing = false;
      let changing = false;
      let closing = false;
      $("#airport").selectmenu({
        create: function (event, ui) {

          const button = $(this).selectmenu("widget");
          if (!button.find(".clear-selection").length) {
            button.append(`
              <button class="clear-selection" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; color: #293851; border:none; background: none;margin-left: 10px;">
                ✖
              </button>
            `);
          }
    
          button.find(".clear-selection").off("click").on("click", function() {
            event.stopPropagation();
            event.preventDefault();
            changing = true;
            closing = true;
            vm.selectedAirport = '';
            button.find(".ui-selectmenu-text").text("Enter nearest airport").removeClass('active');
            vm.onAirportChange();
            $("#airport").selectmenu("close");

            setTimeout(() => {
              closing = false;
            }, 300);
          });
        },
        change: function(event, ui) {
          if (isProcessing) {
            return false;
          }
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').addClass('active');
          vm.selectedAirport = ui.item.value;
          vm.onAirportChange();
          changing = true;
        },
        open: function (event, ui) {
          let button = $(this).selectmenu("widget");
          let menu = $(this).selectmenu("menuWidget");
          let selectElement = $("#airport");
          isProcessing = true;
          changing = false;
          if (closing) {
            $("#airport").selectmenu("close");
            return false;
          }
          menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");

          closing = false;
          button.find(".search-input").remove();
          menu.find("li.no-results").remove();
          vm.noSearchResult = false;

          menu.find("li:first-child").hide();
   
          let currentValue = $(this).find("option:selected").text().trim();
          button.find(".ui-selectmenu-text").hide();
          if (currentValue == 'Enter nearest airport'){
            currentValue = '';
          }

          button.append(`<input type="text" class="search-input prop-search" placeholder="Enter nearest airport" />`);
          let searchInput = button.find(".search-input");
          searchInput.on("blur", function (event) {
            event.stopPropagation();
          });
          searchInput.val(currentValue).on("blur", function (event) {
            event.stopPropagation();
          });
          searchInput.val(currentValue).focus();

          let hasMatch = false;
          menu.find("li").each(function () {
              let text = $(this).text().toLowerCase();
              const isDisabled = $(this).attr("aria-disabled") === "true";
              let matches = !isDisabled && text.includes(currentValue.toLowerCase());
              $(this).toggle(matches);
              $(this).attr("aria-hidden", !matches);
              if (matches) {
                  hasMatch = true;
                }

              if ($(this).text().trim() === currentValue) {
                $(this).css('border-bottom', 'none');
              }
          });

          if (!hasMatch) {
              menu.append('<li class="no-results" style="text-align: left; color: black; margin: 5px;">No Results</li>');
          }
          
          searchInput.on("keydown", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
            if (event.key === " ") {
              event.preventDefault();
              let cursorPosition = searchInput[0].selectionStart;
              let currentValue = $(this).val();
              let newValue = currentValue.slice(0, cursorPosition) + " " + currentValue.slice(cursorPosition);
              $(this).val(newValue);
              searchInput[0].setSelectionRange(cursorPosition + 1, cursorPosition + 1);
              return false;
            }
            if (event.key === "Backspace" && currentValue) {
              event.preventDefault();
              $(this).val("");
              menu.find(".no-results").remove();
              currentValue = "";
              selectElement.val("");
              vm.selectedAirport = '';
              vm.noSearchResult = false;
              menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
              menu.find("li:visible:last").css("border-bottom", "none");
              vm.$nextTick(() => {
                $("#airport").selectmenu("close");
                $("#airport").selectmenu("open");
                vm.resetShuttleMenu();
              });
            }
          });
          let debounceTimeout;
          searchInput.on("keyup", function (event) {
            if (event.key === "Enter" || event.key === " ") {
              event.preventDefault();
              return false;
            }
            menu.addClass("loading").css("pointer-events", "none");
            clearTimeout(debounceTimeout);
            isProcessing = true; 
            let searchTerm = $(this).val().toLowerCase();
            let originalValue = $(this).val(); 
            hasMatch = false;
            menu.find("li").each(function () {
              let text = $(this).text().toLowerCase();
              const isDisabled = $(this).attr("aria-disabled") === "true";
              let matches = !isDisabled && text.includes(searchTerm);
              $(this).toggle(matches);
              $(this).attr("aria-hidden", !matches);
              if (matches) { 
                  hasMatch = true;
                }
              });
              
            let noResultsElement = menu.find(".no-results");
            if (!hasMatch) {
                if (!noResultsElement.length) {
                    menu.append('<li class="no-results" style="text-align: left; color: black; margin: 5px;">No Results</li>');
                } else {
                    noResultsElement.show();
                }
                vm.noSearchResult = true;
            } else {
                noResultsElement.hide();
                vm.noSearchResult = false;
            }
            if (originalValue === "") {
              $(this).val("");
              menu.find("li").each(function () {
                const isDisabled = $(this).attr("aria-disabled") === "true";
                if (!isDisabled) {
                  $(this).show();
                }
              });
              menu.find(".no-results").remove();
              menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
              menu.find("li:visible:last").css("border-bottom", "none");
            }
            menu.find("li").css("border-bottom", "0.5px solid rgba(0, 0, 0, 0.3)");
            menu.find("li:visible:last").css("border-bottom", "none");
            debounceTimeout = setTimeout(() => {
              menu.removeClass("loading").css("pointer-events", "");
              isProcessing = false;
            }, 1000);
          });
          let buttonWidth = $("#airport-button").outerWidth();
          $("#airport-menu").css({
              width: `${buttonWidth}px`,
          }).attr("style", `width: ${buttonWidth}px !important;`);
          isProcessing = false; 
          vm.$nextTick(() => {
            vm.resizeSelectMenu();
          });
        },
        close: function (event, ui) {
          let button = $(this).selectmenu("widget");
          let menu = $(this).selectmenu("menuWidget");
          let searchInput = button.find(".search-input");
          let selectedText = $(this).find("option:selected").text().trim();

          if (searchInput.is(":focus") && !changing) {
            $("#airport").selectmenu("open");
            return false;
          }
          if (searchInput.length) {
              button.find(".ui-selectmenu-text").text(selectedText).show();
              searchInput.remove();
          }

          menu.find("li").each(function () {
            const isDisabled = $(this).attr("aria-disabled") === "true";
            if (!isDisabled) {
              $(this).show();
            }
          });
          menu.find(".no-results").remove();
        }
      });
      $("#shuttle").selectmenu({
        change: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').addClass('active');
      
          vm.coveredParking = ui.item.value;
          vm.oncoverChange();
        },
        select: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').addClass('active');
          vm.coveredParking = ui.item.value;
          vm.oncoverChange();
        },
        open: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(-135deg)');
        },
        close: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(45deg)');
        }
      });
      this.$nextTick(() => {
        this.resizeSelectMenu();
      });
    },
    resetShuttleMenu() {
      let vm = this;
      $("#shuttle").selectmenu({
        change: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').addClass('active');
      
          vm.coveredParking = ui.item.value;
          vm.oncoverChange();
        },
        select: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-text').addClass('active');
          vm.coveredParking = ui.item.value;
          vm.oncoverChange();
        },
        open: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(-135deg)');
        },
        close: function(event, ui) {
          $(this).next('.ui-selectmenu-button').find('.ui-selectmenu-icon.ui-icon').css('-webkit-transform', 'rotate(45deg)');
        }
      });
      this.$nextTick(() => {
        this.resizeSelectMenu();
      });
    },
    closeSelectMenuOnZoom() {
        $("#airport").selectmenu("close");
        $("#shuttle").selectmenu("close");
        this.$nextTick(() => {
          this.resizeSelectMenu();
      });

    },
    handleResize() {
      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;

      if (
          Math.abs(currentWidth - this.lastWindowWidth) > 50 ||
          Math.abs(currentHeight - this.lastWindowHeight) > 50
      ) {
          this.lastWindowWidth = currentWidth;
          this.lastWindowHeight = currentHeight;
          this.closeSelectMenuOnZoom();
      }
    },
    resizeSelectMenu() {
      const shuttleSelect = $("#shuttle");
      if (shuttleSelect.data("ui-selectmenu")) {
        let shuttleContainerWidth = $("#shuttle-container").width();
        $("#shuttle").selectmenu("widget").css("width", shuttleContainerWidth + "px");
      }

      let containerWidth = $("#select-container").outerWidth();
      $("#airport").selectmenu("menuWidget").css("width", containerWidth + "px");
      $("#airport-button").css("width", containerWidth+"px");
      let buttonWidth = $("#airport-button").outerWidth();
      $("#airport-menu").css({width: `${buttonWidth}px`,}).attr("style", `width: ${buttonWidth}px !important;`);
    },
    blockMinusSign(event) {
      if (event.key === '-' || event.key === 'e') {
        event.preventDefault();
      }
    },
    focusNextField(refName) {
      const nextField = this.$refs[refName];
      if (nextField && nextField.focus) {
          nextField.focus();
      }
    },
    cleanAirportName(name) {
      return name.replace(/\s*\(.*?\)\s*/g, '').replace(/airport/gi, '').trim();
    },
    getExtraClass(index) {
      if (index === 0) return 'm-t-60';
      if (index === 2) return 'm-t-100';
      return '';
    },
    countIntersection(words1, words2) {
      const set1 = new Set(words1);
      const set2 = new Set(words2);
  
      const excludedWords = new Set(["international", "airport"]);
      const filteredSet1 = [...set1].filter(word => !excludedWords.has(word));
      const filteredSet2 = [...set2].filter(word => !excludedWords.has(word));

      return filteredSet1.filter(word => filteredSet2.includes(word)).length;
    },
    onAirportChange() {  
      let selectedID = this.selectedAirport;

      this.selectedAirportObj = this.airports.find(
        airport => airport.id == selectedID
      );
      this.a_selectClass = this.selectedAirportObj ? 'selected' : 'default';
    
      if (this.selectedAirportObj) {
        const iataCode = this.selectedAirportObj.iata_code.trim().toLowerCase();
        const selectedNameWords = this.cleanAirportName(this.selectedAirportObj.airport_name.toLowerCase()).split(" ");

        const matchingAirportsByIATA = this.airports_revenue.filter(fileAirport =>
          fileAirport.iata_code.trim().toLowerCase() === iataCode
        );
    
        if (matchingAirportsByIATA.length > 1) {
          this.matchingAirport = matchingAirportsByIATA.reduce((bestMatch, fileAirport) => {
            const cleanedNameWords = this.cleanAirportName(fileAirport.name.toLowerCase()).split(" ");
            const commonWords = this.countIntersection(selectedNameWords, cleanedNameWords);
    
            if (!bestMatch || commonWords > bestMatch.commonWords) {
              return { airport: fileAirport, commonWords };
            }
            return bestMatch;
          }, null);

          if (this.matchingAirport) {
            this.matchingAirport = this.matchingAirport.airport;
          }
        } else if (matchingAirportsByIATA.length === 1) {
          this.matchingAirport = matchingAirportsByIATA[0];
        } else {
          this.matchingAirport = null;
        }
      } else {
        this.matchingAirport = null;
      }

      if (!this.matchingAirport) {
        this.isAirportMatched = false
        this.calculatedRevenue = null
      } else {
        this.isAirportMatched = true
      }
      this.focusNextField('ShuttleService');
      this.resetShuttleMenu();
    },
    addscheduleDemoInitiateDataLayer(button_text) {
      if (!isServer) {
        DataLayer.scheduleDemoInitiate(this.user,button_text);
      }
      this.$router.push({ name: 'contact-us', query: { source: 'propertyManager', buttonText: button_text } });
    },
    fetchRevenueData() {
      this.$store.dispatch('dashboards.getRevenue').then(() => {
          this.airports_revenue = _.cloneDeep(this.$store.state.dashboards.revenue);
        })
        .catch(error => {
          console.error('Error fetching file data:', error);
        });
    },
    calculateRevenue() {
      let basePrice = 0;
      if (this.matchingAirport) {
        let minPrice = parseFloat(this.matchingAirport.min_price) || null;      
        let price = parseFloat(this.matchingAirport.price) || null;

        if (price) {
          basePrice = price;
        } else if (minPrice) {
          basePrice = minPrice;
        } else {
          this.matchingAirport = false;
        }

        let discount = 0;
        
        if (this.coveredParking === 'yes') {
          discount += 0.3;
        }
        if (this.shuttleService === 'no') {
          discount -= 0.2;
        }

        discount = 1 + discount;
        basePrice *= discount;

        let totalRevenue = 0;

        totalRevenue = basePrice * this.numSpots;
        this.calculatedRevenue = totalRevenue;
        this.monthlyRevenue = totalRevenue * 30;
        this.monthlyRevenue  = Math.round(this.monthlyRevenue  / 50) * 50;
        this.annualRevenue = this.monthlyRevenue * 12;
        this.annualRevenue  = Math.round(this.annualRevenue  / 50) * 50;

        let revenueData = {
          nearest_airport: this.matchingAirport.name,
          shuttle: this.shuttleService === 'yes',
          valet: this.valetParking === 'yes',
          cover: this.coveredParking === 'yes',
          num_spots: this.numSpots,
          calculated_amount: this.annualRevenue
        };
        if (!isServer) {
          DataLayer.calculateRevenue(this.user,revenueData);
        }
      }

    },
    fetchAirports() {
      this.$store.dispatch('dashboards.getAirports').then(() => {
          this.airports = _.cloneDeep(this.$store.state.dashboards.airports.airports);
          this.initializeSelectMenu();
          this.error = '';
        }).catch(err => {
          this.error = err.response ? err.response.data.error : 'An error occurred';
          this.airports = [];
        });
    },
    showMore() {
      const nextIndex = this.visibleSections.length + this.additionalVisibleSections;
      this.visibleSections = this.sections.slice(0, nextIndex);
    },
    showLess() {
      const previousIndex = Math.max(this.initialVisibleSections, this.visibleSections.length - this.additionalVisibleSections);
      this.visibleSections = this.sections.slice(0, previousIndex);
    },
    oncoverChange() {
      this.c_selectClass = this.coveredParking === '' ? 'default' : 'selected';

      this.focusNextField('allcoveredParking');
      
    },
  }
}
