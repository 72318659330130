import layout from 'layouts/default'
import toastr from 'toastr'
import { hasPermits } from 'directives' 
import { editableText,editableCheckbox } from 'components/editable'
import { confirm } from 'components/modals'
import newRoomsType from '../rooms/newType.modal'
import editRoomsType from '../rooms/editType.modal'
import newBundleType from '../bundles/newType.modal'
import editBundleType from '../bundles/editType.modal'
import newParkingsType from '../parkings/newType.modal'
import tabs from '../tabs'
import checkInOutModal from '../checkInOutModal'
import { permits } from 'services'


const directives = {
  hasPermits
}

const watch = {
  filter (n,o) {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id}),
      this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id, filter: n, all: false}),
      this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id, filter: n}),
      this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id, filter: n}),
    ]).then(() => {
      this.$set(this, 'loading', false);
    });
  }
}

const components = {
  layout,
  newRoomsType,
  editRoomsType,
  newParkingsType,
  newBundleType,
  editBundleType,
  editableText,
  editableCheckbox,
  confirm,
  checkInOutModal,
  tabs
}

const computed = {
  isSuperAdmin () {
    return permits.hasPermit('super_admin');
  },
  roomTypes () {
    return _.cloneDeep(this.$store.state.locations.active.roomTypes)
  },
  parkingTypes () {
    let pTypes = _.cloneDeep(this.$store.state.locations.active.parkingTypes);
    for (let i = 0; i < pTypes.length; i++) {
      pTypes[i].displayName = pTypes[i].name;
      if ("type_info" in pTypes[i] ) {
        pTypes[i].name = pTypes[i].type_info.name;
      } else {
        pTypes[i].name = pTypes[i].name;
      }
    }
    return pTypes;
  },
  bundleTypes () {
    return _.cloneDeep(this.$store.state.locations.active.bundleTypes)
  },
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  }
}

const methods = {
  onEditType (typeType, editableData, item, key) {
    console.log('data, item', editableData, item);
    item[key] = editableData.value;
    editableData.startLoading();

    if (item.beds) {
      delete item.beds;
    }

    this.$store.dispatch(`locations.update${typeType}Type`, {
      id: item.id, data: item
    }).then(() => {
      editableData.stopLoading();
      let errors;
      if (errors = this.$store.state.locations.errors) {
        toastr.error(this.$store.state.locations.errors.message);
        console.log('errors', errors);
        if (errors.errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.error(error.message);
        }
      } else {
        editableData.close();
        this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id, filter: this.filter, all: false});
      }
    })
  },

  onCheckboxClicked (typeType, checkbox, item, key) {
    const checkboxValue = checkbox.value;
    item[key] = checkboxValue ? 1 : 0;
    checkbox.startLoading();

    if (item.beds) {
      delete item.beds;
    }

    this.$store.dispatch(`locations.update${typeType}Type`, {
      id: item.id, data: item
    }).then(() => {
      checkbox.stopLoading();
      let errors;
      if (errors = this.$store.state.locations.errors) {
        console.log('errors', errors);
        if (errors.errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.error(error.message);
        }
      } else {
        checkbox.close();
      }
    })
  },

  remove (type, item, force = false) {
    this.$store.dispatch(`locations.remove${_.capitalize(type)}Type`, {id: item.id, location_id: this.$route.params.id, force}).then(() => {
      let errors = this.$store.state.locations.errors;
      if (errors) {
        if (errors.message && errors.message.includes("You don't have permission to delete")) {
          toastr.error(errors.message);
        }
        else if(errors.status_code == 400) {
          this.$refs.deleteConfirm.confirm('This type has rates assigned to it, all data will be lost!', () => {
            this.remove (type, item, true);
          })

        } else if (errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        }
      } else if (item.parent_id) {
        this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id, filter: this.filter, all: false});
      }
    })
  },

  recover (type, item) {
    this.$store.dispatch(`locations.undoRemove${_.capitalize(type)}Type`, {id: item.id, location_id: this.$route.params.id}).then(() => {
      let errors = this.$store.state.locations.errors;
      if (errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
      } else {
        let messsage = `${type} Rate Type has been Recovered successfully.`
        toastr.success(messsage);
      }
    })
  },

  removeRoomType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.remove('Room', item);
    });
  },
  removeBundleType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.remove('Bundle', item);
    });
  },
  removeParkingType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.remove('Parking', item);
    });
  },
  recoverRoomType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.recover('Room', item);
    });
  },
  recoverBundleType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.recover('Bundle', item);
    });
  },
  recoverParkingType (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.recover('Parking', item);
    });
  },
  editType (item) {
    this.selectedType = item;
    this.$refs.editType.open();
  },
  editBundle (item) {
    this.selectedBundle = item;
    this.$refs.editBundle.open();
  },
  getLocation () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', {id: this.$route.params.id}),
      this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id, filter: this.filter, all: false}),
      this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id, filter: this.filter}),
      this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id, filter: this.filter}),
    ]).then(() => {
      this.$set(this, 'loading', false);
    });
  },
  onUpdateTypesNumber (key, data) {
    this.errors = {}
    data.startLoading();
    let location = _.cloneDeep(this.location);
    delete location.channels;
    location[key] = data.value;
    this.$store.dispatch('locations.edit', {
      id: this.$route.params.id,
      data: location
    }).then(() => {
      data.stopLoading();
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        if (this.openCheckInOutTimesModal(['checkin_time', 'checkout_time'])) {
          this.$refs.timesRequired.open(location, this.$refs.roomComponent);
        } else {
          toastr.error(this.$store.state.locations.errors.message);
        }
      } else {
        toastr.success('Data has been saved');
        data.close();
      }
    })
  },
  openCheckInOutTimesModal (arr) {
    for (let i in arr) {
      if (Object.keys(this.errors).indexOf(arr[i]) > -1) {
        return true
      }
    }

    return false
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: "types",
  computed,
  methods,
  components,
  directives,
  watch,
  data () {
    return {
      loading: false,
      submit_loading: false,
      selectedType: {},
      selectedBundle: {},
      errors: {},
      filter: "active",
    }
  },
  created () {

    this.$store.dispatch('types.getParkings', this.$route.params.id);
    this.$store.dispatch('types.getBedTypes', this.$route.params.id);

    this.getLocation();
  },
}
