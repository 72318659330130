
const components = {

}

const computed = {
  parkingCarTypes() {
    return _.cloneDeep(this.$store.state.carTypesIframe.locationParkingCarTypes);
  },

  carTypes() {
    return _.cloneDeep(this.$store.state.carTypesIframe.carTypes);
  },

  makes(){
    let carTypes = _.cloneDeep(this.carTypes);
    return Object.keys(carTypes);
  },

  carBodyTypes () {
    return _.cloneDeep(this.$store.state.carTypesIframe.carBodyTypes);
  },

}

const watch = {

  ['numberOfCars'] (n, o) {
    if (n != o && this.validateNumberOfCars(n)) {      
      if (n > o) {
        for (let i = 0; i < n - o; i++) {
          this.carsData.push({
            isUserCarInputs: false,
            userCarMakeModelInput: "",
            userCarBodyTypeId: null,
            userCarBodyTypeName: null,
            loading:false,
            selectedMake: null,
            selectedModel: null,
            selectedYear: null,
            selectedTrimId: null,  
            models: [],
            years: [],
            trims: [],
            });
    
        }
      }
      else if (n < o) {
        this.carsData.splice(n, o - n)
        this.submit();
      }
    }
  },

}

const methods = {
  clearCarFields(car, trim = false, year = false, model = false) {
    if(trim) {
      car.trims = [];
      car.selectedTrimId= null;
    }
    if(year) {
      car.years = [];
      car.selectedYear= null;
    }
    if(model) {
      car.models = [];
      car.selectedModel= null;
    }
  },

  handleMakeChange(index) {
    let car = this.carsData[index];
    if (car.selectedMake) {
      car.loading = true;
      this.clearCarFields(car, true, true, true);
      let data = {
        index: index,
        value: car.selectedMake,
      }
      this.sendMessage(data ,"selection_changed", "make");
      let carTypes = _.cloneDeep(this.carTypes)
      car.models = carTypes[car.selectedMake] ? Object.keys(carTypes[car.selectedMake]) : [];
      car.loading = false; 
      
    } else {
      this.clearCarFields(car, true, true, true);
    }
  },

  handleModelChange(index) {
    let car = this.carsData[index];
    if (car.selectedModel) {
      car.loading = true;
      this.clearCarFields(car, true, true);
      let data = {
        index: index,
        value: car.selectedModel,
      }
      this.sendMessage(data ,"selection_changed", "model");
      let carTypes = _.cloneDeep(this.carTypes)
      car.years = carTypes[car.selectedMake] && carTypes[car.selectedMake][car.selectedModel] ? Object.keys(carTypes[car.selectedMake][car.selectedModel]).reverse() : [];
      car.loading = false; 
    } else {
      this.clearCarFields(car, true, true);
    }
  },

  handleYearChange(index) {
    let car = this.carsData[index];
    if (car.selectedYear) {
      car.loading = true;
      this.clearCarFields(car, true);
      let data = {
        index: index,
        value: car.selectedYear,
      }
      this.sendMessage(data ,"selection_changed", "year");
      let carTypes = _.cloneDeep(this.carTypes);
      let trims = [];
      if(carTypes[car.selectedMake][car.selectedModel][car.selectedYear] && !carTypes[car.selectedMake][car.selectedModel][car.selectedYear]['all_same']){
        trims = carTypes[car.selectedMake][car.selectedModel][car.selectedYear]['trims'];
        trims = trims.map((trim) => {
          trim = trim.trim().split(',');
          return {
            id: trim[0],
            name: trim[1]
          };
        })
      }
      if(!_.isEmpty(trims) && trims.length > 0) {
        car.trims = trims;
        car.loading = false;
      } else {
        this.clearCarFields(car, true);
        car.loading = false;
        this.submit();
      }
    } else {
      this.clearCarFields(car, true);
    }
  },

  handleTrimChange(index) {
    let car = this.carsData[index];
    if (car.selectedTrimId) {
      let data = {
        index: index,
        value: car.selectedTrimId,
      }
      this.sendMessage(data ,"selection_changed", "trim");
      this.submit();
    }
  },

  handleUserInputCheckboxChanged(index) {
    let car = this.carsData[index];
    let data = {
      index: index,
      value: car.isUserCarInputs,
    }
    this.sendMessage(data ,"selection_changed", "input_checkbox");
    this.submit();
  },

  handleUserInputMakeModelChanged(index) {
    let car = this.carsData[index];
    let data = {
      index: index,
      value: car.userCarMakeModelInput,
    }
    this.sendMessage(data ,"selection_changed", "make_model");
    this.submit(); 
  },

  handleUserInputCarTypeChanged(index) {
    let car = this.carsData[index];
    let data = {
      index: index,
      value: car.userCarBodyTypeId,
    }
    this.sendMessage(data ,"selection_changed", "car_type");
    this.submit();
  },

  formatCarsData(carsData, fillOptions = false) {
    let formatted_cars_data = [];
    for (let i = 0; i < carsData.length; i++) {
      let car = carsData[i];
      if (!this.locationId) {
        this.sendMessage("LocationId is required", 'error', "location_id");
        return false;
      }
      else if (car.isUserCarInputs && (car.userCarMakeModelInput.trim() === "" || car.userCarBodyTypeId === null)) {
        return false;
      }
      else if (!car.isUserCarInputs && (car.selectedMake === null || car.selectedModel === null || car.selectedYear === null)) {
        return false;
      }
      else if (!car.isUserCarInputs && car.trims && car.trims.length > 0 && car.selectedTrimId === null) {
        return false;
      }

      let carTypes = _.cloneDeep(this.carTypes)
      if (car.isUserCarInputs) {
        _.map(this.carBodyTypes, item => {
          if (car.userCarBodyTypeId == item.id) {
            car.userCarBodyTypeName = item.name;
          }
        });
      }
      let carData = {
        isUserCarInputs: car.isUserCarInputs,
        userCarMakeModelInput: car.isUserCarInputs ? car.userCarMakeModelInput : "",
        userCarBodyTypeId: car.isUserCarInputs ? car.userCarBodyTypeId : null,
        userCarBodyTypeName: car.isUserCarInputs ? car.userCarBodyTypeName : null,
        selectedMake: !car.isUserCarInputs ? car.selectedMake : null,
        selectedModel: !car.isUserCarInputs ? car.selectedModel : null,
        selectedYear: !car.isUserCarInputs ? car.selectedYear : null,
        selectedTrimId: !car.isUserCarInputs ? car.selectedTrimId : null,  
      };
      let carTrim = car.selectedTrimId;
      if(!carTrim && !car.isUserCarInputs) {
        carTrim = carTypes[carData.selectedMake][carData.selectedModel][carData.selectedYear]['trims'][0].trim().split(',')[0];
      }
      carData.selectedTrimId = carTrim;

      if(fillOptions) {
        carData.models = carTypes[carData.selectedMake] ? Object.keys(carTypes[carData.selectedMake]) : [];
        carData.years = carTypes[carData.selectedMake] && carTypes[carData.selectedMake][carData.selectedModel] ? Object.keys(carTypes[carData.selectedMake][carData.selectedModel]).reverse() : [];
        let trims = [];
        if(carTypes[carData.selectedMake] && carTypes[carData.selectedMake][carData.selectedModel] && carTypes[carData.selectedMake][carData.selectedModel][carData.selectedYear] && !carTypes[carData.selectedMake][carData.selectedModel][carData.selectedYear]['all_same']){
          trims = carTypes[carData.selectedMake][carData.selectedModel][carData.selectedYear]['trims'];
          trims = trims.map((trim) => {
            trim = trim.trim().split(',');
            return {
              id: trim[0],
              name: trim[1]
            };
          })
        }
        carData.trims = trims;
        if(!carData.isUserCarInputs && carData.trims && carData.trims.length > 0 && carData.selectedTrimId === null) {
          return false;
        }
      }
      formatted_cars_data.push(carData);
    }
    return formatted_cars_data;
  },

  submit() {
    let cars_data = this.formatCarsData(this.carsData);
    if(!cars_data) {
      return;
    }

    let carTypesToken = sessionStorage.getItem("__carTypesToken__");
    if (!carTypesToken) {
      carTypesToken = this.generateCarTypesToken();
      sessionStorage.setItem("__carTypesToken__", carTypesToken);
    }

    let data = {
      cars_data: cars_data,
      location_id: this.locationId,
      car_types_token: carTypesToken
    }
    this.sendMessage(data, "submit");
    let allowedParkingTypes = [];
    let blockedParkingTypes = [];
    data.cars_data.forEach((car) => {
      let carAllowedParkingTypes = [];
        this.parkingCarTypes.forEach((parking) => {
          if(parking.dimensions) {
            if(car.isUserCarInputs) {
              if(parking.dimensions.allTypes) {
                carAllowedParkingTypes.push(parking.parkingType);
              } else if(this.checkNumberInParsedArray(parking.dimensions.types, car.userCarBodyTypeId)){
                carAllowedParkingTypes.push(parking.parkingType);
              }
            } else {
              if(parking.dimensions.allTrims) {
                carAllowedParkingTypes.push(parking.parkingType);
              } else if (this.checkNumberInParsedArray(parking.dimensions.trims, car.selectedTrimId)) {
                carAllowedParkingTypes.push(parking.parkingType);
              }
            }
          } 
          else if (parking.bodyTypes){
            if(car.isUserCarInputs) {
              if(parking.bodyTypes.allTypes) {
                carAllowedParkingTypes.push(parking.parkingType);
              } else if(this.checkNumberInParsedArray(parking.bodyTypes.types, car.userCarBodyTypeId)){
                carAllowedParkingTypes.push(parking.parkingType);
              }
            } else {
              if(parking.bodyTypes.allTrims) {
                carAllowedParkingTypes.push(parking.parkingType);
              } else if (this.checkNumberInParsedArray(parking.bodyTypes.trims, car.selectedTrimId)) {
                carAllowedParkingTypes.push(parking.parkingType);
              }
            }
          } else {
            carAllowedParkingTypes.push(parking.parkingType);
          }
        })
        if (allowedParkingTypes.length < 1) {
          allowedParkingTypes = carAllowedParkingTypes;
        } else {
          let idSet = new Set(carAllowedParkingTypes.map(item => item.id));
          let intersection = allowedParkingTypes.filter(item => idSet.has(item.id));
          blockedParkingTypes.push(
            ...allowedParkingTypes.filter(type => !idSet.has(type.id) && !blockedParkingTypes.some(blocked => blocked.id === type.id)),
            ...carAllowedParkingTypes.filter(type => !intersection.some(intersect => intersect.id === type.id) && !blockedParkingTypes.some(blocked => blocked.id === type.id))
          );
          allowedParkingTypes = intersection;
        }
    });
    this.sendMessage(blockedParkingTypes, "blockedParkingTypes");
    this.sendMessage(allowedParkingTypes, "parkingTypes");
    this.$store.dispatch('carTypes.storeUserCarBodyType',{data});
  },

  checkNumberInParsedArray(parsedArray, number) {
    for (let i = 0; i < parsedArray.length; i++) {
      let [start, end] = parsedArray[i];
      if (number >= start && number <= end) {
        return true;
      }
    }
    return false;
  },

  validateNumberOfCars(numberOfCars) {
    if (!numberOfCars && numberOfCars != 0) {
      this.sendMessage("Number of cars is required", "error", "number_of_cars");
      return false;
    }
    else if (isNaN(Number(numberOfCars))) {
      this.sendMessage("Number of cars must be integer", "error", "number_of_cars");
      return false;
    }
    else if (numberOfCars < 1) {
      this.sendMessage("Number of cars must be at least 1", "error", "number_of_cars");
      return false;
    }
    else if (numberOfCars > 5) {
      this.sendMessage("Number of cars may not be greater than 5", "error", "number_of_cars");
      return false;
    }
    return true
  },

  generateCarTypesToken() {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < 32; i++) {
      token += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return token;
  },

  sendHeightMessage() {
    this.sendMessage(document.documentElement.offsetHeight, 'height')
  },

  sendMessage(value, type, title = null) {
    window.parent.postMessage({type: type, title: title, value: value}, "*");
  },

}


export default {
  name: 'car-types-iframe',
  components,
  computed,
  watch,
  methods,
  data () {
    return {
      loading: true,
      locationId: null,
      numberOfCars: 1,
      disableSelection: false,
      carsData : [
        {
          isUserCarInputs: false,
          userCarMakeModelInput: "",
          userCarBodyTypeId: null,
          userCarBodyTypeName: null,
          loading:false,
          selectedMake: null,
          selectedModel: null,
          selectedYear: null,
          selectedTrimId: null,  
          models: [],
          years: [],
          trims: [],
        }
      ],
      tempCarsData: []
    }
  },
  mounted () {
    let locationId = this.$route.query.location_id;
    let numberOfCars = this.$route.query.number_of_cars;

    const link = document.createElement('link');
    document.head.appendChild(link);  
    const style = document.createElement('style');
    document.head.appendChild(style);

    if (isNaN(Number(locationId))) {
      this.sendMessage("LocationId is required", 'error', "location_id");
    } 
    else if (this.validateNumberOfCars(numberOfCars)) {
      this.sendMessage("ready_for_messages", 'ready');
      
      this.numberOfCars = numberOfCars;
      this.locationId = locationId
      

      window.onload = this.sendHeightMessage;
      window.onresize = this.sendHeightMessage;
      var observer = new MutationObserver(this.sendHeightMessage);
      observer.observe(document.body, { childList: true, subtree: true });    

      const resizeObserver = new ResizeObserver(this.sendHeightMessage);
      resizeObserver.observe(document.body);

      window.addEventListener('message', (event) => {
        
        if (event.data.type === 'number_of_cars') {
          if(this.validateNumberOfCars(event.data.value)) {
            this.sendMessage(event.data.value ,"selection_changed", "number_of_cars");
            this.numberOfCars = event.data.value;
          }
        }

        else if (event.data.type === 'cars_data') {
          if(!event.data.value) {
            this.sendMessage("Invalid data", 'error', "cars_data");
          } 
          else if (!event.data.value.car_types_token || event.data.value.car_types_token.trim().length  != 32 ) {
            this.sendMessage("Invalid car_types_token", 'error', "cars_data");
          }
          else if (!event.data.value.location_id || event.data.value.location_id != this.locationId) {
            this.sendMessage("Invalid location_id", 'error', "cars_data");
          }
          else if(!event.data.value.cars_data || event.data.value.cars_data.length < 1) {
            this.sendMessage("Invalid cars data", 'error', "cars_data");
          } else {
            this.tempCarsData = event.data.value.cars_data;
            sessionStorage.setItem("__carTypesToken__", event.data.value.car_types_token.trim());
          }
        }

        else if (event.data.type === 'disable') {
          if(event.data.value === 'true') {
            this.disableSelection = true;
          } else {
            this.disableSelection = false;
          }
        }

        else if (event.data.type === 'stylesheet_link') {
          if (event.data.value.endsWith(".css")) {
            link.rel = 'stylesheet';
            link.href = event.data.value;
          }
          else {
            this.sendMessage("stylesheet link must end with '.css'", 'error', "stylesheet_link");
          }
        } 

        else if (event.data.type === 'stylesheet_content') {
            style.textContent = event.data.value; 
        }
      });
    }
    
    sessionStorage.setItem("__carTypesToken__", this.generateCarTypesToken());

    Promise.all([
      this.$store.dispatch('carTypes.getCarTypes'),
      this.$store.dispatch('carTypes.getAllCarBodyTypes'),
      this.$store.dispatch('carTypes.getLocationParkingCarTypes', {location_id: locationId})
    ]).then(() => {
      let errors = _.cloneDeep(this.$store.state.carTypesIframe.errors);
      if(!errors) {
        let withoutTypeStructure = this.parkingCarTypes.every(item => (!item.hasOwnProperty('dimensions') && !item.hasOwnProperty('bodyTypes')));
        if(withoutTypeStructure) {
          this.sendMessage("no_car_types_limitation", 'ready');
        } else {
          this.sendMessage("ready_to_display", 'ready');
        }
        
        this.loading = false;
        if(this.tempCarsData && this.tempCarsData.length) {
          let data = this.formatCarsData(this.tempCarsData, true)
          if(!data) {
            this.sendMessage("Invalid cars data", 'error', "cars_data");
          } else {
            this.carsData = data;
            this.submit();
          }  
        }

      } else {
        this.sendMessage(errors, 'error', "internal_server_error");
      }

    });
  }
}
