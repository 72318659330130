
class DataLayer {

  login (user, website = 'Email') {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "login",
        'phone': user ? user.phone : null,
        'email': user ? user.email : null,
        'method': website,
        'user_login_status': user ? "Logged in" : "Guest"
      }

      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:login event", error);
    }
  }

  generateLead (user, lead_type, email) {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "generate_lead",
        'phone': user ? user.phone : null,
        'email': email ? email : user ? user.email : null,
        'user_login_status': user ? "Logged in" : "Guest",
        'lead_type': lead_type
        
      };
      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:generate_lead event", error);
    }
  }

  resetPassword (email, user = null) {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "reset_password",
        'phone': user ? user.phone : null,
        'email': user ? user.email : email,
        'user_login_status': user ? "Logged in" : "Guest",
        'password_reset_attempt': null
      };

      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:reset_password event", error);
    }
  }

  scheduleDemoInitiate (user = null, button_text) {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "schedule_demo_initiate",
        'phone': user ? user.phone : null,
        'email': user ? user.email : null,
        'user_login_status': user ? "Logged in" : "Guest",
        'button_text': button_text
      };

      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:schedule_demo_initiate event", error);
    }
  }

  scheduleDemoSuccess (email, phone, user = null, button_text, form) {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "schedule_demo_success",
        'phone': phone ? phone : user ? user.phone : null,
        'email': email ? email : user ? user.email : null,
        'user_login_status': user ? "Logged in" : "Guest",
        'button_text': button_text,
        'nearest_airport': form ? form.airport_name : null,
        'preferred_contact_method': form ? form.inerested_in : null,
      };

      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:schedule_demo_success event", error);
    }
  }

  calculateRevenue (user = null, revenueData) {
    try {
      dataLayer.push({ ecommerce: null });

      let data_layer = {
        'event': "calculate_revenue",
        'phone': user ? user.phone : null,
        'email': user ? user.email : null,
        'user_login_status': user ? "Logged in" : "Guest",
        'nearest_airport': revenueData ? revenueData.nearest_airport : null,
        'shuttle': revenueData ? (revenueData.shuttle ? 1 : 0) : null,
        'valet': revenueData ? (revenueData.valet ? 1 : 0) : null,
        'cover': revenueData ? (revenueData.cover ? 1 : 0) : null,
        'num_spots': revenueData ? revenueData.num_spots : null,
        'calculated_amount': revenueData ? revenueData.calculated_amount : null,
      };

      if (user) {
        data_layer.user_id = "" + user.id;
      }

      dataLayer.push({...data_layer});
    } catch (error) {
      console.log("DataLayer:calculate_revenue event", error);
    }
  }

}

export default new DataLayer();
