var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("no-ssr", [
    _c("div", { staticClass: "wrapper" }, [
      _c(
        "header",
        { staticClass: "main-header" },
        [
          _vm.isLocationPage
            ? _c(
                "a",
                {
                  staticClass: "sidebar-toggle-mobile toggle-sidebar-custom",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleSidebar.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.sideBarIsActive
                    ? _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _c("i", {
                        staticClass: "fa fa-bars",
                        attrs: { "aria-hidden": "true" }
                      })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("router-link", {
            staticClass: "logo",
            attrs: { to: { name: "property-manager" } }
          }),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass: "navbar navbar-static-top",
              attrs: { role: "navigation" }
            },
            [
              _c("div", { staticClass: "navbar-custom-menu" }, [
                _c("ul", { staticClass: "nav navbar-nav" }, [
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["location_list", "location_user"],
                          expression: "['location_list', 'location_user']",
                          arg: "allow"
                        }
                      ],
                      class: { active: _vm.activeNav.indexOf("locations") > -1 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "locations.all" } },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute("locations.all")
                            }
                          }
                        },
                        [_vm._v("\n              Locations\n            ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["channel_list", "channel_user"],
                          expression: "['channel_list', 'channel_user']",
                          arg: "allow"
                        }
                      ],
                      class: { active: _vm.activeNav.indexOf("channels") > -1 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "channels.all" } },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute("channels.all")
                            }
                          }
                        },
                        [_vm._v("\n              Channels\n            ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.state.channels.active.id &&
                  _vm.activeNav.indexOf("channels.") == -1 &&
                  _vm.activeNav.indexOf("refundRequests") == -1 &&
                  _vm.activeNav.indexOf("reports") == -1
                    ? _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["channel_list", "channel_user"],
                              expression: "['channel_list', 'channel_user']",
                              arg: "allow"
                            },
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:prevent",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "prevent"
                            }
                          ],
                          class: {
                            active: _vm.activeNav.indexOf("locations.") > -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "channels.edit",
                                  params: {
                                    id: _vm.$store.state.channels.active.id
                                  }
                                }
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("channels.edit")
                                }
                              }
                            },
                            [_vm._v("\n              Locations\n            ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["user_list"],
                          expression: "['user_list']",
                          arg: "allow"
                        }
                      ],
                      class: { active: _vm.activeNav.indexOf("users") > -1 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "users.all" } },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute("users.all")
                            }
                          }
                        },
                        [_vm._v("\n              Users\n            ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasAccessToReports()
                    ? _c(
                        "li",
                        {
                          staticClass: "reports-lg",
                          class: {
                            active: _vm.activeNav.indexOf("reports") > -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: { name: "reports.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("reports.all")
                                }
                              }
                            },
                            [_vm._v("\n              Reports\n            ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("reservations") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "reservations.all" } },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute("reservations.all")
                            }
                          }
                        },
                        [_vm._v("\n              Reservations\n            ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasAccessToRefundRequests()
                    ? _c(
                        "li",
                        {
                          staticClass: "refund-requests-lg",
                          class: {
                            active: _vm.activeNav.indexOf("refundRequests") > -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: { name: "refundRequests.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("refundRequests.all")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Refund Requests\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: [
                            "refundRequest_list",
                            "guarantee_card_list",
                            "vendor_list",
                            "role_list",
                            "point_of_interest_list"
                          ],
                          expression:
                            "['refundRequest_list','guarantee_card_list', 'vendor_list', 'role_list', 'point_of_interest_list']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "dropdown more-menu"
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            id: "settings-drop-down",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm._v("\n              More\n              "),
                          _c("i", {
                            staticClass: "fa fa-caret-down",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu" },
                        [
                          _vm.hasAccessToReports()
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "reports-mob",
                                  class: {
                                    active:
                                      _vm.activeNav.indexOf("reports") > -1
                                  },
                                  attrs: { to: { name: "reports.all" } },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.changeRoute("reports.all")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Reports\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAccessToRefundRequests()
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "refund-requests-mob",
                                  class: {
                                    active:
                                      _vm.activeNav.indexOf("refundRequests") >
                                      -1
                                  },
                                  attrs: { to: { name: "refundRequests.all" } },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.changeRoute(
                                        "refundRequests.all"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Refund Requests\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["vendor_list"],
                                  expression: "['vendor_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active: _vm.activeNav.indexOf("vendors") > -1
                              },
                              attrs: { to: { name: "vendors.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("vendors.all")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Vendors\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["guarantee_card_list"],
                                  expression: "['guarantee_card_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.activeNav.indexOf("guaranteeCard") > -1
                              },
                              attrs: {
                                to: {
                                  name: "guaranteeCard.edit",
                                  params: { id: 1 }
                                }
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("guaranteeCard.edit")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Virtual Card\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["role_list"],
                                  expression: "['role_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active: _vm.activeNav.indexOf("roles") > -1
                              },
                              attrs: { to: { name: "roles.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("roles.all")
                                }
                              }
                            },
                            [_vm._v("\n                Roles\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["super_admin"],
                                  expression: "['super_admin']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active: _vm.activeNav.indexOf("audits") > -1
                              },
                              attrs: { to: { name: "audits.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("audits.all")
                                }
                              }
                            },
                            [_vm._v("\n                Audits\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["app_logs"],
                                  expression: "['app_logs']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active: _vm.activeNav.indexOf("appLogs") > -1
                              },
                              attrs: { to: { name: "appLogs" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("appLogs")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                App Logs\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["point_of_interest_list"],
                                  expression: "['point_of_interest_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.activeNav.indexOf("pointOfInterest") > -1
                              },
                              attrs: { to: { name: "poi.all" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeRoute("poi.all")
                                }
                              }
                            },
                            [_vm._v("\n                POI\n              ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown user user-menu" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-toggle",
                        attrs: { href: "#", "data-toggle": "dropdown" }
                      },
                      [
                        _c("span", [
                          _c("i", {
                            staticClass: "fa fa-cogs",
                            attrs: { "aria-hidden": "true" }
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu" }, [
                      _vm.user
                        ? _c("li", { staticClass: "user-header" }, [
                            _c("p", [
                              _c("label", [_vm._v("Name:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.name) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("label", [_vm._v("Email:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.email) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("label", [_vm._v("Role:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.roles[0]
                                      ? _vm.user.roles[0].name
                                      : ""
                                  ) +
                                  "\n                "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", { staticClass: "user-footer" }, [
                        _c(
                          "div",
                          { staticClass: "user-footer--item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-primary btn-flat",
                                attrs: { to: { name: "settings" } }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-user",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  "\n                    Profile\n                  "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-footer--item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary btn-flat",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sign-out",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                    Sign out\n                  "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.noSidebar
        ? _c("aside", { staticClass: "main-sidebar" }, [
            _c("section", { staticClass: "sidebar" }, [
              !_vm.noSidebar
                ? _c(
                    "a",
                    {
                      staticClass: "toggle-sidebar-custom",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleSidebar.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm.sideBarIsActive
                        ? _c("i", {
                            staticClass: "fa fa-chevron-left",
                            attrs: { "aria-hidden": "true" }
                          })
                        : _c("i", {
                            staticClass: "fa fa-chevron-right",
                            attrs: { "aria-hidden": "true" }
                          })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "sidebar-menu",
                  attrs: { "data-widget": "tree" }
                },
                [
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_list", "location_user"]
                          },
                          expression:
                            "{id:$route.params.id, permits: ['location_list', 'location_user']}",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("guestManagement") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.guestManagement",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute(
                                "locations.guestManagement"
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-address-book",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Guest Management")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_rate_management"]
                          },
                          expression:
                            "{id: $route.params.id, permits: ['location_rate_management']}",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("rateManagement") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.rateManagement",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeRoute("locations.rateManagement")
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-usd",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Rate Management")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_management"]
                          },
                          expression:
                            "{id: $route.params.id, permits: ['location_management']}",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("information") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.information.details",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-map-marker",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Location Information")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_booking_conditions"]
                          },
                          expression:
                            "{id: $route.params.id, permits: ['location_booking_conditions']}",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("bookingConditions") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.bookingConditions",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-sliders",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Booking Conditions")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_administration"]
                          },
                          expression:
                            "{id: $route.params.id, permits: ['location_administration']}",
                          arg: "allow"
                        }
                      ],
                      class: {
                        active: _vm.activeNav.indexOf("administration") > -1
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.administration.channels",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-lock",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Administration")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_analytics"]
                          },
                          expression:
                            "{id: $route.params.id, permits: ['location_analytics']}",
                          arg: "allow"
                        }
                      ],
                      class: { active: _vm.activeNav.indexOf("analytics") > -1 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.analytics.dashboard",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-area-chart",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Analytics")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.$route.params.id,
                            permits: ["location_profile"]
                          },
                          expression:
                            "{id:$route.params.id, permits: ['location_profile']}",
                          arg: "allow"
                        }
                      ],
                      class: { active: _vm.activeNav.indexOf("profile") > -1 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "locations.profile",
                              params: {
                                id: _vm.$route.params.id
                              }
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-user",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Profile")])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content-wrapper",
          class: { "no-sidebar": _vm.noSidebar }
        },
        [
          _c("section", { staticClass: "content-header" }, [
            _vm.isAdmin && _vm.location && !_vm.parkingCancellationPolicy
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning message-box",
                    attrs: { role: "alert" }
                  },
                  [
                    _vm._v(
                      "\n        There is no parking cancellation policy for this location\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.title && _vm.isLocationPage && _vm.locations.length > 1
              ? _c(
                  "h1",
                  {
                    attrs: {
                      "data-toggle": "collapse",
                      href: "#collapseSelectLocation",
                      role: "button",
                      "aria-expanded": "false",
                      "aria-controls": "collapseSelectLocation"
                    }
                  },
                  [
                    _vm._v("\n          " + _vm._s(_vm.title) + " "),
                    _c("span", [
                      _c("i", {
                        staticClass: "fa fa-caret-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    ])
                  ]
                )
              : _c("h1", [
                  _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
                ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse",
                attrs: { id: "collapseSelectLocation" }
              },
              [
                _vm.isLocationPage && _vm.locations.length > 1
                  ? _c("div", { staticClass: "card card-body margin-top-3x" }, [
                      _c(
                        "div",
                        [
                          _c("s-select", {
                            attrs: {
                              selected: _vm.activeLocation,
                              options: _vm.allLocations
                            },
                            on: { selectedChanged: _vm.changeSelected }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.breadcrumb
              ? _c(
                  "ol",
                  { staticClass: "breadcrumb" },
                  _vm._l(_vm.breadcrumb, function(level, i) {
                    return _c(
                      "li",
                      { class: { active: i == _vm.breadcrumb.length } },
                      [
                        _c("router-link", { attrs: { to: level.route } }, [
                          _c("i", { staticClass: "fa fa-dashboard" }),
                          _vm._v(" " + _vm._s(level.title) + "\n          ")
                        ])
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "content container-fluid" }, [
            _vm.noBox
              ? _c("div", [_vm._t("default")], 2)
              : _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box-body" }, [_vm._t("default")], 2)
                ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "main-footer", class: { "no-sidebar": _vm.noSidebar } },
        [
          _c("div", { staticClass: "copyright" }, [
            _c("strong", [
              _vm._v("Copyright ©  "),
              _c("a", { attrs: { href: "/" } }, [_vm._v("Reservations Lab ")]),
              _vm._v(" " + _vm._s(_vm.year) + ".")
            ]),
            _vm._v(" All rights reserved.\n    ")
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "control-sidebar-bg" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f7e4975", { render: render, staticRenderFns: staticRenderFns })
  }
}