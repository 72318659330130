import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
import { stripTags } from 'filters'
import { confirm } from 'components/modals'
import datepicker from 'components/datepicker'
import { editableCheckbox } from 'components/editable'
import sSelect from "components/sSelect";
import moment from 'moment'
import { permits } from 'services'
import _ from 'lodash'
// import './details.scss'

const directives = {
  hasPermits
}

const components = {
  layout,
  confirm,
  datepicker,
  editableCheckbox,
  sSelect,
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },

  locations () {
    let locations = _.filter(_.cloneDeep(this.$store.state.locations.all), location => location.id != this.location.id);
    locations = _.map(locations, item => {
      return {
        value: item.id,
        text: item.name
      }
    });
    locations.unshift({value: null, text: '-- Select from list --'});
    return locations
  },

  linkedLocationParkingTypes () {
    if (this.linkedLocation) {
    let types = _.map(_.cloneDeep(this.$store.state.locations.linkedLocationParkingTypes), item => {
      return {
        value: item.id,
        text: item.name
      }});
      types.unshift({value: null, text: '-- Select from list --'});
      return types;
    }

    return [];

  },

  rateTypes () {
    let parking_types = _.cloneDeep(this.$store.state.locations.active.parkingTypes);
    let room_types =  _.cloneDeep(this.$store.state.locations.active.roomTypes);
    let bundle_types = _.cloneDeep(this.$store.state.locations.active.bundleTypes);
    let data = _.concat(parking_types, room_types, bundle_types);
    return data;
  },
  
  users () {
    let users = _.cloneDeep(this.$store.state.users.all);
    return users;
  }, 

  all_vendors () {
      return _.map(_.cloneDeep(this.$store.state.vendors.all), item => {
        return {
          value: item.id,
          text: item.name
        }
      });
  }, 

  all_points_of_interest () {
      return _.map(_.cloneDeep(this.$store.state.pointOfInterest.all), item => {
        return {
          value: item.id,
          text: item.name
        }
      });
  }, 
}

const watch = {
  location () {
    try {
      this.comments = this.location.comments.map((item) => ({
        id: item.id,
        comment: item.comment,
        time: item.updated_at,
        user_name: item.user.name,
        editing: false,
        edit_value: item.comment
      }))
    } catch (e) {}
  },

  users () {
    try {
      this.ims_users = _.cloneDeep(this.$store.state.users.all);
    } catch (e) {}
  },  

  all_vendors () {
    try {
      this.vendors = _.map(_.cloneDeep(this.$store.state.vendors.all), item => {
        return {
          value: item.id,
          text: item.name
        }
      });
    } catch (e) {}
  }, 

  all_points_of_interest () {
    try {
      this.points_of_interest = _.map(_.cloneDeep(this.$store.state.pointOfInterest.all), item => {
        return {
          value: item.id,
          text: item.name
        }
      });
    } catch (e) {}
  }, 

  rateTypes () {
    try {
      let arrangement = this.location.profile.arrangement;
      let rates = this.rateTypes.map((rate) => ({
        type_id: rate.id,
        type_type: rate.hasOwnProperty('number_of_parking_days') && rate.hasOwnProperty('number_of_room_days') ? 'bundle' : rate.hasOwnProperty('number_of_parkings') ? 'parking' : 'room',
        type_name: rate.name,
        arrangement_type: "",
        lease_amount: "",
      }));
      let diff;
      if (arrangement) {
        diff = rates.filter(object1 => {
          return !arrangement.some(object2 => {
            return (object1.type_id === object2.type_id && object1.type_type === object2.type_type);
          });
       });    
      }
      else {
        diff = null;
      }    
      this.form.arrangement = arrangement && arrangement.length != 0 ? _.concat(arrangement, diff) : rates;
      this.initEditArrangementStatus();
    } catch (e) {}
  },
}

const methods = {

  writeComment () {
    this.typing_comment = true;
    setTimeout(() => {
      this.$refs.commentEditor.focus();
    }, 100)

    this.cancelCommentEditors();
  },

  cancelComment () {
    this.new_comment = "";
    this.typing_comment = false;
  },

  editComment(commentId, index) {

    this.comments[index].edit_value = this.comments[index].comment;
    this.comments[index].editing = true;

    this.cancelCommentEditors(index);
    this.cancelComment(false);

    setTimeout(() => {
      this.$refs[`commentEditor${commentId}`][0].focus();
    }, 100)
  },

  cancelCommentEditors(index = -1) {
    for (let i = 0; i < this.comments.length; i++) {
      if (i != index || index == -1) {
        this.cancelEdit(this.comments.id,i);
      }
    }
  },

  deleteComment(commentId, index) {
    
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.processing = true;
      this.$store.dispatch('locations.removeComment', {
        locId: this.$route.params.id,
        id: commentId
      }).then(() => {
        this.processing = false;
        let errors =  this.$store.state.locations.errors;
        if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);  
          }
          toastr.error(errors.message);
        } else {
          toastr.success('Comment removed successfully');
        }

      });
    })
  },

  updateComment(commentId, index) {
    if (stripTags(this.comments[index].edit_value)  == "") {
      console.log("empty update");
      return;
    }

    this.errors = {};
    this.processing = true;
    this.$store.dispatch('locations.updateComment', {
      locId: this.$route.params.id,
      id: commentId,
      data: {
        comment: this.comments[index].edit_value
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been updated successfully');
      }
    });
  },

  cancelEdit(commentId, index) {
    this.comments[index].editing = false;
  },

  changeComment(newValue) {
    this.new_comment = newValue;
  },

  newComment () {

    if (stripTags(this.new_comment) == "" ) {
      return;
    }

    this.errors = {};
    this.processing = true;
    this.$store.dispatch('locations.newComment', {
      id: this.$route.params.id,
      data: {
        comment: this.new_comment
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been add successfully');
        this.new_comment = "";
        this.typing_comment = false;
      }
    });
  },

  submit () {
    this.errors = {}
    this.submit_loading = true;
    // check if date changed validation
    for (let i = 0; i < this.editArrangementStatus.length; i++) {
      if (this.editArrangementStatus[i].error) {
        this.submit_loading = false;
        toastr.error('Please change the date for changed types');
        return;
      }
    }

    // execute random command to force change event to run
    this.$refs.important_notes.execCommand('refresh');

    if (this.start_date) {
      this.start_date = this.start_date === 'MM/DD/YYYY' ? null : this.start_date;

      this.form.start_date = this.start_date ? moment(this.start_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
    }
    
    for (let i = 0; i < this.form.arrangement.length; i++) {
      if (this.form.arrangement[i].date) {
        let sDate = this.form.arrangement[i].date === 'MM/DD/YYYY' ? null : this.form.arrangement[i].date;
        this.form.arrangement[i].start_date = sDate ? moment(sDate, ['MM/DD/YYYY']).format('YYYY-MM-DD') : '';
      }  
    }
    this.form.vendor_id = _.isEmpty(this.vendor) ? null : this.vendor.value;
   
    this.form.point_of_interest_id = _.isEmpty(this.poi) ? null : this.poi.value;

    this.processing = true;
    this.form.mark_location_as_closed = this.markAsClose;
    if (this.location_closed_at) {
      this.form.location_closed_at = moment(this.location_closed_at, ['MM/DD/YYYY']).format('YYYY-MM-DD');
      this.form.linkedLocationTypes.parking = this.linkedLocationParkingType ? this.linkedLocationParkingType.value : null;
    }
    
    this.$store.dispatch('locations.editProfile', {id: this.$route.params.id, data: this.form}).then(() => {

      this.processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
        this.submit_loading = false;
      } else {
        toastr.success('Data has been saved');
        this.submit_loading = false;
        this.editDate = false;
        this.initEditArrangementStatus();
      }
    })
  },

  displayErrors (errors) {
    this.errors = _.cloneDeep(errors);
  },
  
  setAccountManager (account_manager) {
    this.account_manager = account_manager;
    this.form.user_id = account_manager.value;
  },

  onChange(lease_amount, i) {
    this.form.arrangement[i].lease_amount = this.form.arrangement[i].arrangement_type === 'c' ? '' : lease_amount;  
    if (this.form.arrangement[i].arrangement_type != this.editArrangementStatus[i].arrangement_type) {
      this.editArrangementStatus[i].typeChanged = true;
    } else {
      this.editArrangementStatus[i].typeChanged = false;
    }
    this.setEditValidationMessage(i);
  },

  startEdit() {
    this.editDate = true;
  },

  initEditArrangementStatus() {
    let arrCopy = Array(this.form.arrangement.length);
    for (let i = 0; i < arrCopy.length; i++) {
      arrCopy[i] = {
        startEdit: false,
        arrangement_type: this.form.arrangement[i].arrangement_type,
        typeChanged: false,
        dateChanged: _.isEmpty(this.form.arrangement[i].date),
        error: false,
      };
    }

    this.editArrangementStatus = arrCopy;
  },

  onDateChange(i, value) {
    this.editArrangementStatus[i].dateChanged = true;
    this.setEditValidationMessage(i);
  },

  setEditValidationMessage(i) {
    let copy = this.editArrangementStatus.slice();

    if (copy[i].typeChanged && !copy[i].dateChanged && !_.isEmpty(copy[i].arrangement_type)) {
      copy[i].error = "you should change the date when change the type";
    } else {
      copy[i].error = null;
    }
      this.editArrangementStatus = copy;
  },

  startEditStartDate(index) {
    if (index >= 0) {
      let copy = this.editArrangementStatus.slice();
      copy[index].startEdit = true;
      this.editArrangementStatus = copy;
    }
  },
  setVendor (vendor) {
    this.vendor = vendor;
    this.form.vendor_id = vendor.value;
  },

  setPointOfInterest (poi) {
    this.poi = poi;
    this.form.point_of_interest_id = poi.value;
  },

  setLinkedLocationParkingType (type) {
    this.linkedLocationParkingType = type;
  },

  setLinkedLocation (location) {
    this.linkedLocation = location;
    this.form.linked_location_id = location.value; 
    if (permits.hasPermit('super_admin') && location.value) {
      this.$store.dispatch('locations.parkingTypes', {id: location.value, linked_location: true}).then((res) => {
        this.linkedLocationParkingType = _.filter(this.linkedLocationParkingTypes, type => type.value == this.location.profile.linked_location_parking_type_id)[0];
      });
    }
  },

  removeVendor () {
    this.vendor = {};
  },

  saveLeaseHistory () {
    this.processingLeaseHistory = true;
    this.$store.dispatch('locations.saveLeaseHistory', {id: this.$route.params.id}).then(() => {
      this.processingLeaseHistory = false;
    })
  },

  removePoi () {
    this.poi = {};
  }
}

export default {
  name: "profile",
  components,
  computed,
  watch,
  methods,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      loading: false,
      processing: false,
      submit_loading: false,
      typing_comment: false,
      new_comment: "",
      comments: [],
      errors: {},
      ims_users: [],
      start_date: null,
      account_manager: {},
      location_closed_at: null,
      linkedLocation: {},
      linkedLocationParkingType: {},
      markAsClose: false,
      form: {
        main_contact: "",
        payment_type: "cash",
        account_information: "",
        important_notes: "",
        user_id: null,
        start_date: null,
        arrangement: [],
        vendor_id: null,
        point_of_interest_id: null,
        location_closed_at: null,
        linked_location_id: null,
        linkedLocationTypes: {
          parking: null,
        },
      },
      editDate: false,
      editArrangementStatus: [],
      vendor: {},
      poi: {},
      vendors: [],
      points_of_interest: [],
    }
  }, created () {

    this.loading = true;
    let promises = [
      this.$store.dispatch('locations.get', {id: this.$route.params.id}),
      this.$store.dispatch('locations.getProfile', this.$route.params.id),
      this.$store.dispatch('locations.getComments', this.$route.params.id),
    ];
    if (permits.hasPermit('super_admin')) {
      promises.concat([
        this.$store.dispatch('locations.parkingTypes', {id: this.$route.params.id}),
        this.$store.dispatch('locations.roomTypes', {id: this.$route.params.id}),
        this.$store.dispatch('locations.bundleTypes', {id: this.$route.params.id}),
        this.$store.dispatch('users.getAll', {_ims: true}),
        this.$store.dispatch('vendors.getAll', {_all: true}),
        this.$store.dispatch('poi.getAll', {_all: true}),
      ]);
    }

    Promise.all(promises).then(() => {
      this.loading = false;
      try {
        this.form.main_contact = this.location.profile.main_contact;
        this.form.payment_type = this.location.profile.payment_type;
        this.form.account_information = this.location.profile.account_information;
        this.form.important_notes = this.location.profile.important_notes;

        this.comments = this.location.comments.map((item) => ({
          id: item.id,
          comment: item.comment,
          time: item.updated_at,
          user_name: item.user.name,
          editing: false,
          edit_value: item.comment
        }));
        if (permits.hasPermit('super_admin')) {
          this.account_manager = this.location.profile.account_manager;
          this.start_date = this.location.profile.start_date ? moment(this.location.profile.start_date, ['YYYY-MM-DD']).format('MM/DD/YYYY') : 'MM/DD/YYYY';
          let arrangement = this.location.profile.arrangement;
          let rates = this.rateTypes.map((rate) => ({
            type_id: rate.id,
            type_type: rate.hasOwnProperty('number_of_parking_days') && rate.hasOwnProperty('number_of_room_days') ? 'bundle' : rate.hasOwnProperty('number_of_parkings') ? 'parking' : 'room',
            type_name: rate.name,
            arrangement_type: "",
            lease_amount: "",
          }));
          let diff = rates.filter(object1 => {
            return !arrangement.some(object2 => {
              return (object1.type_id === object2.type_id && object1.type_type === object2.type_type);
            });
          });          
          this.form.arrangement = arrangement && arrangement.length != 0 ? _.concat(arrangement, diff) : rates;
          this.initEditArrangementStatus();

          this.ims_users = this.users;
          this.vendor = this.location.profile.vendor;
          this.poi = this.location.profile.poi;
          this.vendors = this.all_vendors;
          this.points_of_interest = this.all_points_of_interest;
          this.markAsClose = this.location.profile.mark_location_as_closed;
          if (this.location.profile.location_closed_at) {
            setTimeout(() => {
              this.location_closed_at = moment(this.location.profile.location_closed_at, ['YYYY-MM-DD']).format('MM/DD/YYYY');
              this.linkedLocation = _.filter(this.locations, location => location.value == this.location.profile.linked_location_id)[0];
              this.setLinkedLocation(this.linkedLocation);
            }, 1000);
          }
        }
      } catch (e) {}
    });
  }
}
